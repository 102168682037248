/* tslint:disable */
/* eslint-disable */
export interface ReportingCreateDocumentArgs {
  actionID?: null | string;
  baseDocumentID?: null | string;
  deviceInfo?: null | {
    [key: string]: any;
  };
  format?: null | string;
  useCache?: boolean;
}
