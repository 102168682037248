import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ProcessesEmployeeType, ProcessesPositionSeats, ProcessesProbation, ProcessesShiftType, UserAppConfigDto } from '@verde/shared';
import { Subject } from 'rxjs';

@Component({
  selector: 'verde-onboarding-required-info-edit',
  templateUrl: './onboarding-required-info-edit.component.html',
  styleUrls: ['./onboarding-required-info-edit.component.scss'],
})
export class OnboardingRequiredInfoEditComponent implements OnInit {
  private onDestroy$ = new Subject<boolean>();

  @Input() employeeType: ProcessesEmployeeType[] | null = null;
  @Input() positionSeat: ProcessesPositionSeats | null = null;
  @Input() probation: ProcessesProbation[] | null = null;
  @Input() shiftType: ProcessesShiftType[] | null = null;
  @Input() config: UserAppConfigDto | null = null;
  @Input() public accountDetails: FormGroup;

  constructor() {}

  ngOnInit(): void {}

  public ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
