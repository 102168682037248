import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subject, take, takeUntil } from 'rxjs';
import { DatePipe } from '@angular/common';
import { VerdeApprovalService } from '../../services/verde-approval.service';
import { HrPortalService } from '../../../../../../../../apps/verde/src/app/features/hr-portal/services/hr-portal.service';
import * as moment from 'moment';
import { UserService } from '@verde/core';
import { ProcessesApiProcessesService, ProcessesEmployeeExit, ProcessesBtProcessStatus, ModalService } from '@verde/shared';

@Component({
  selector: 'verde-employee-exit-edit',
  templateUrl: './employee-exit-edit.component.html',
  styleUrls: ['./employee-exit-edit.component.scss'],
})
export class EmployeeExitEditComponent implements OnInit {
  private onDestroy$ = new Subject<boolean>();

  @ViewChild('comments') commentsInput: any;
  @ViewChild('fileUpload') fileUploadInput: any;

  public min: Date = new Date(1917, 0, 1);
  public max: Date = new Date(2020, 4, 31);

  uploadedFiles: File[] = [];

  employeeExitAccess: any[] = [];
  selectedLegalEntity: string;
  selectedEmployee: string;
  selectedExitReason: string;
  exitReasons: any;
  gridColWidth = '45%';
  employeeExit: any;
  listofFiles: any[];
  confirmMessage: string;

  documentUrl: any;

  constructor(
    private userService: UserService,
    private processesApiProcessesService: ProcessesApiProcessesService,
    private sidebarService: VerdeApprovalService,
    private refreshService: HrPortalService,
    private modalService: ModalService,
  ) {}

  ngOnInit(): void {
    this.sidebarService
      .getEmployeeExitData()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data: any) => {
        this.employeeExit = data;
      });

    this.processesApiProcessesService
      .getExitReason({ body: { statusCode: true } })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (val) => {
          this.exitReasons = val;
        },
        () => {},
      );
    this.registerForm.get('exitReason').setValue(this.employeeExit.bt_exitreason);
    let exitDateValue = new Date(this.employeeExit.bt_exitdate);
    let noticeDateValue = new Date(this.employeeExit.bt_noticedate);
    this.registerForm.get('exitDate').setValue(exitDateValue);
    this.registerForm.get('noticeDate').setValue(noticeDateValue);
    this.registerForm.get('comments').setValue(this.employeeExit.bt_comment);
    this.listofFiles = [];

    if (this.employeeExit.annotations.length > 0) {
      this.employeeExit.annotations.forEach((e) => {
        this.listofFiles.push({
          name: e.filename,
        });
      });
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  public registerForm: FormGroup = new FormGroup({
    exitReason: new FormControl(),
    exitDate: new FormControl(moment().toDate()),
    noticeDate: new FormControl(moment().toDate()),
    comments: new FormControl(),
  });

  setExitReasonValue(exitReason) {
    this.selectedExitReason = exitReason.bt_verdeemployeeexitreasonsid;
  }

  public submitForm(): void {}

  process() {
    this.modalService.open('processModal');
    this.confirmMessage = 'Please confirm that you would like to process employee exit';
  }

  reversal() {
    this.modalService.open('reversalModal');
    this.confirmMessage = 'Please confirm that you would like to reverse employee exit';
  }

  confirmReversal() {
    if (this.selectedExitReason === undefined) {
      this.selectedExitReason = this.registerForm.value.exitReason.bt_verdeemployeeexitreasonsid;
    }

    let body: ProcessesEmployeeExit = {
      bt_ProcessStatus: ProcessesBtProcessStatus.Cancelled,
      bt_employeeexitdetailsid: this.employeeExit.bt_employeeexitdetailsid,
      exitReasonID: this.selectedExitReason,
      bt_exitdate: this.registerForm.value.exitDate,
      bt_processedBy: this.userService.user.employeeId,
      bt_processLegalEntity: this.userService.user.legalEntityId,
    };
    this.processesApiProcessesService
      .patchEmployeeExit({ body: { body: body } })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          console.log(ret);
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.sidebarService.setShowSidebar(false);
          this.refreshService.refreshView();
        },
      );
  }

  confirmProcess() {
    if (this.selectedExitReason === undefined) {
      this.selectedExitReason = this.registerForm.value.exitReason.bt_verdeemployeeexitreasonsid;
    }

    let body: ProcessesEmployeeExit = {
      bt_employeeexitdetailsid: this.employeeExit.bt_employeeexitdetailsid,
      bt_comment: this.registerForm.value.comments,
      exitReasonID: this.selectedExitReason,
      bt_exitdate: this.registerForm.value.exitDate,
      bt_ProcessStatus: ProcessesBtProcessStatus.Processing,
      bt_processedBy: this.userService.user.employeeId,
      bt_processLegalEntity: this.userService.user.legalEntityId,
    };
    this.processesApiProcessesService
      .patchEmployeeExit({ body: { body: body } })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          console.log(ret);
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.sidebarService.setShowSidebar(false);
          this.refreshService.refreshView();
        },
      );
  }

  removeFile(index: number) {}
}
