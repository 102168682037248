import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyJsonschema } from '@ngx-formly/core/json-schema';
import { DeviceTypeService, UserService } from '@verde/core';
import { CommonApiWebDynamicService, CommonGetDynamicFormLookupCommand, CommonWebDynamicFilterDto, SidePanelWidth, VerdeApprovalService } from '@verde/shared';
import { BehaviorSubject, Subject, take, takeUntil, tap } from 'rxjs';
import { WebDynamicFormMode } from '../enums/web-dynamic-form-mode.enum';
import { WebDynamicFormType } from '../enums/web-dynamic-form-type.enum';
import { WebDynamicModel, WebDynamicSidePanelArgs } from '../models/web-dynamic-form.model';

@Injectable({
  providedIn: 'root',
})
export class WebDynamicService implements OnDestroy {
  private onDestroy$ = new Subject<boolean>();
  webDynamicModel$: BehaviorSubject<WebDynamicModel | undefined> = new BehaviorSubject<WebDynamicModel | undefined>(undefined);

  constructor(
    private http: HttpClient,
    private sidebarService: VerdeApprovalService,
    public userService: UserService,
    private commonApiWebDynamicService: CommonApiWebDynamicService,
    private formlyJsonschema: FormlyJsonschema,
    public deviceTypeService: DeviceTypeService,
  ) {}

  setDynamicForm(formModel: WebDynamicModel) {
    // formModel.formFile = 'example-cross-filter';
    this.http
      .get<any>('/assets/web-dynamic/' + formModel.formFile + '.json')
      .pipe(
        tap(({ schema, additional }) => {
          const form = new FormGroup({});
          schema = this.replacePlaceholdersRecursively(schema, this.userService.config$.getValue());
          const fields = [
            this.formlyJsonschema.toFieldConfig(schema, {
              map: (mappedField: FormlyFieldConfig, mapSource: any) => {
                // Prepare default options
                let extraProps = {};
                if (mappedField.props.options === undefined) {
                  if (mapSource.type === 'boolean') {
                    extraProps = {
                      options: [
                        { label: 'Yes', value: true },
                        { label: 'No', value: false },
                      ],
                    };
                  }

                  if (mapSource.type === 'select') {
                    extraProps = {
                      options: this.commonApiWebDynamicService.dynamicFormLookupData({
                        body: mapSource.props.lookUp as CommonGetDynamicFormLookupCommand,
                      }),
                    };

                    mappedField.hooks = {
                      ...mappedField.hooks,
                      onChanges: (field: FormlyFieldConfig) => this.onFieldChanges(form, fields, field),
                    };
                  }
                }

                mappedField = {
                  ...mappedField,
                  props: {
                    ...mappedField.props,
                    ...mapSource.props,
                    ...extraProps,
                  },
                  expressions: {
                    ...(mapSource.props?.expressions ?? {}),
                  },
                };

                mappedField.templateOptions = {
                  ...mappedField.props,
                };

                return mappedField;
              },
            }),
          ];

          fields[0].props.label = null; // Clear the title that is shown at the wrong place

          this.webDynamicModel$.next({
            ...formModel,
            form,
            options: {},
            schema,
            fields,
            additional: additional,
            formMode: (formModel.entityId ?? '') === '' ? WebDynamicFormMode.CREATE : formModel.formMode,
          });

          switch (formModel.formType) {
            case WebDynamicFormType.SIDEPANEL: {
              const args = formModel.args as WebDynamicSidePanelArgs;
              this.sidebarService.setShowSidebar(args.visible);
              this.sidebarService.setTitleTag(schema.title);
              this.sidebarService.setSidebarSize(
                Number(this.deviceTypeService.isMobile() == false ? additional?.formWidth ?? SidePanelWidth.Half : SidePanelWidth.Full),
              );
              this.sidebarService.setSidebarType('verdeDynamicForm');
              break;
            }
            case WebDynamicFormType.GRID: {
              break;
            }
          }
        }),
        take(1),
      )
      .subscribe();
  }

  private replacePlaceholdersRecursively(obj: any, config: any): any {
    if (obj && typeof obj === 'object') {
      for (const key of Object.keys(obj)) {
        if (typeof obj[key] === 'string') {
          obj[key] = this.replacePlaceholders(obj[key], config);
        } else if (Array.isArray(obj[key])) {
          obj[key] = obj[key].map((item) => this.replacePlaceholdersRecursively(item, config));
        } else if (typeof obj[key] === 'object') {
          obj[key] = this.replacePlaceholdersRecursively(obj[key], config);
        }
      }
    }
    return obj;
  }

  private getNestedValue(obj: any, path: string): any {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  }

  private replacePlaceholders(template: string, config: any): string {
    return template.replace(/\$\{(.*?)\}/g, (_, path) => {
      const value = this.getNestedValue(config, path);
      return value !== undefined ? value : '';
    });
  }

  private findControlByKey(control: AbstractControl, key: string): AbstractControl | null {
    if (control instanceof FormGroup) {
      if (control.contains(key)) {
        return control.get(key);
      }
      // Recursively search within nested FormGroups
      for (const child of Object.values(control.controls)) {
        const result = this.findControlByKey(child, key);
        if (result) {
          return result;
        }
      }
    } else if (control instanceof FormArray) {
      // Recursively search within FormArray elements
      for (const child of control.controls) {
        const result = this.findControlByKey(child, key);
        if (result) {
          return result;
        }
      }
    }
    return null;
  }

  private onFieldChanges(form: FormGroup, fields: FormlyFieldConfig[], field: FormlyFieldConfig) {
    if (field.key) {
      const key = field.key as string;
      const control = this.findControlByKey(form, key);
      if (control) {
        control.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((value) => {
          if (value) {
            this.updateDependentFields(fields, key, value);
          }
        });
      }
    }
  }

  private updateDependentFields(fields: FormlyFieldConfig[], property: string, value: any) {
    fields.forEach((field) => {
      if (field.props.lookUp && field.props.lookUp.dependency && Object.keys(field.props.lookUp.dependency).length > 0) {
        const updatedLookUp = {
          ...field.props.lookUp,
          dependency: {
            ...field.props.lookUp.dependency,
            value,
          },
        };
        field.props.lookUp = updatedLookUp;
        field.templateOptions.lookUp = updatedLookUp;
      }

      if (field.fieldGroup) {
        this.updateDependentFields(field.fieldGroup, property, value);
      }
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
