import { Injectable } from '@angular/core';

const isNil = (value: any) => value === null || value === undefined;

@Injectable()
export class PowerBiTokenService {
  public tokenPayload: any | null;
  private token: string | null;

  // percentage on the total lifespan on when the token should be refreshed
  // Default: 50% (0.5)
  // 0.1 = It will expire if 10% of the total lifespan has passed
  private readonly refreshThreshold = 0.05;

  // the embed token is not in pure JWT format, and it does not include the issued at date.
  private _issuedAt: Date;

  public get issuedAt(): Date | null {
    if (!this.isValid) {
      return null;
    }
    const { iat } = this.tokenPayload;
    return new Date(iat * 1000);
  }

  public get isAboutToExpire(): boolean {
    if (!this.isValid) {
      return true;
    }

    const current = new Date(Date.now());
    return current > this.refreshingAt!;
  }

  public get isExpired(): boolean {
    if (!this.isValid) {
      return true;
    }

    const current = new Date(Date.now());
    return current > this.expiresAt!;
  }

  public get isValid(): boolean {
    const result = !isNil(this.tokenPayload);
    const containsProperties = this.containsProperties(this.tokenPayload, 'exp', 'iat');

    return result && containsProperties;
  }

  public get expiresAt(): Date | null {
    if (!this.isValid) {
      return null;
    }

    const { exp } = this.tokenPayload;
    return new Date(exp * 1000);
  }

  public get refreshingAt(): Date | null {
    if (!this.isValid) {
      return null;
    }
    const start = this.issuedAt!.getTime();
    const end = this.expiresAt!.getTime();
    const timeToAdd = (end - start) * this.refreshThreshold;
    return new Date(start + timeToAdd);
  }

  public setToken(token: string, issuedAt?: Date): void {
    this.token = token;
    this._issuedAt = issuedAt || new Date(Date.now());
    this.setPayload(token);
  }

  /**
   * Returns true if the object contains all the properties passed as arguments
   * @param object The object to check
   * @param props The properties that should exist on the object
   * @private
   */
  private containsProperties(object: any, ...props: string[]): boolean {
    if (isNil(object)) {
      return false;
    }

    for (const prop of props) {
      if (!(prop in object)) {
        return false;
      }
    }
    return true;
  }

  /**
   * This function takes the token, and extracts the public part of the token and stores it in a normal js object.
   * This object is then used to view the contents of the token.
   *
   * It will set the tokenPayload to null if the token is invalid.
   * @param token The JSON Web Token
   * @private
   */
  private setPayload(token: string): void {
    try {
      if (!isNil(token) && token !== 'undefined') {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        this.tokenPayload = {
          ...JSON.parse(
            decodeURIComponent(
              atob(base64)
                .split('')
                .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
                .join(''),
            ),
          ),
          // PowerBI does not add the issued at date on the token, so we manually add it.
          // We need to divide by 1000 because the token is in seconds, and Date is in milliseconds
          iat: Math.ceil(this._issuedAt.getTime() / 1000),
        };
      }
    } catch (ex) {
      console.error('Failed to set token payload', ex);
      this.tokenPayload = null;
      this.token = null;
    }
  }
}
