import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UserService } from '@verde/core';
import {
  DelegationsApiDelegationsService,
  DelegationsBtGapAnalysisStatus,
  DelegationsBtMobilitytransferpotential,
  DelegationsBtReadyin,
  DelegationsBtRetentionPotential,
  DelegationsBtRetirementStatus,
  DelegationsBtSuccessorType,
  DelegationsBtTrainingPlanDeveloped,
  DelegationsSuccessionPlanSuccessor,
  DvLegalEntities,
  SharedApiLegalEntitiesService,
  UserUserDto,
  UserApiUserService,
  UserAppConfigDto,
} from '@verde/shared';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, take, takeUntil } from 'rxjs';
import { VerdeApprovalService } from '../../services/verde-approval.service';

@Component({
  selector: 'verde-successor-details',
  templateUrl: './successor-details.component.html',
  styleUrls: ['./successor-details.component.scss'],
})
export class SuccessorDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
  private onDestroy$ = new Subject<boolean>();

  config: UserAppConfigDto;

  successorDetails: DelegationsSuccessionPlanSuccessor = {};
  actionType: string = '';
  readOnlyForm: boolean = false;

  showFirstPage: boolean = true;
  showSecondPage: boolean = false;

  get allSuccessorType() {
    return Object.values(DelegationsBtSuccessorType).map((value) => ({ text: value, value: value }));
  }

  get SuccessorType() {
    return DelegationsBtSuccessorType;
  }

  defaultSuccessorType: { text: string; value: string } = { text: this.SuccessorType.Internal, value: this.SuccessorType.Internal };

  allSuccessorTypeMapped: any = [];

  allPriorities: Array<{ text: string; value: number }> = [
    { text: 'Low', value: 3 },
    { text: 'Medium', value: 2 },
    { text: 'High', value: 1 },
  ];

  get allRetentionPotential() {
    return Object.values(DelegationsBtRetentionPotential).map((value) => ({ text: value, value: value }));
  }

  selectedRetentionPotential: { text: string; value: string };

  allRetentionPotentialMapped: any = [];

  get allRetirementStatus() {
    return Object.values(DelegationsBtRetirementStatus).map((value) => ({ text: value, value: value }));
  }

  selectedRetirementStatus: { text: string; value: string };

  allRetirementStatusMapped: any = [];

  get allTransferPotential() {
    return Object.values(DelegationsBtMobilitytransferpotential).map((value) => ({ text: value, value: value }));
  }

  selectedTransferPotential: { text: string; value: string };

  allTransferPotentialMapped: any = [];

  get allReadyIn() {
    return Object.values(DelegationsBtReadyin).map((value) => ({ text: value, value: value }));
  }

  selectedReadyIn: { text: string; value: string };

  allReadyInMapped: any = [];

  get allGapAnalysisStatus() {
    return Object.values(DelegationsBtGapAnalysisStatus).map((value) => ({ text: value, value: value }));
  }

  selectedGapAnalysisStatus: { text: string; value: string };

  allGapAnalysisStatusMapped: any = [];

  get allTrainingPlanDeveloped() {
    return Object.values(DelegationsBtTrainingPlanDeveloped).map((value) => ({ text: value, value: value }));
  }

  get TrainingPlanDeveloped() {
    return DelegationsBtTrainingPlanDeveloped;
  }

  selectedTrainingPlanDeveloped: { text: string; value: string };

  allTrainingPlanDevelopedMapped: any = [];

  allYesNoOptions: Array<{ text: string; value: boolean }> = [
    { text: 'Yes', value: true },
    { text: 'No', value: false },
  ];

  getSelectedYesNoOptions(fieldName: string): { text: string; value: boolean } {
    return this.allYesNoOptions.find((item) => item.value === this.successorDetails[fieldName]);
  }

  allLegalEntities: Array<DvLegalEntities> = new Array<DvLegalEntities>();
  defaultLegalEntity: DvLegalEntities = {
    bthr_legalentityname: this.userService.user.legalEntityName,
    bthr_legalentitiesid: this.userService.user.legalEntityId,
  };
  allEmployeesFilteredData: Array<UserUserDto> = new Array<UserUserDto>();
  employeeDropdownMessage: string = 'Type at least 3 characters';
  todaysDate = moment().format('YYYY-MM-DD');

  resultMessage: string = '';
  showConfirmationModal: boolean = false;

  trainingPlanFile: File[] | null = [];
  trainingPlanBase64: string = '';

  constructor(
    private sidebarService: VerdeApprovalService,
    public userService: UserService,
    private userApiUserService: UserApiUserService,
    private sharedApiLegalEntitiesService: SharedApiLegalEntitiesService,
    private delegationsApiDelegationsService: DelegationsApiDelegationsService,
    private spinner: NgxSpinnerService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.allSuccessorTypeMapped = [...this.allSuccessorType];
    this.allRetentionPotentialMapped = [...this.allRetentionPotential];
    this.allRetirementStatusMapped = [...this.allRetirementStatus];
    this.allTransferPotentialMapped = [...this.allTransferPotential];
    this.allReadyInMapped = [...this.allReadyIn];
    this.allGapAnalysisStatusMapped = [...this.allGapAnalysisStatus];
    this.allTrainingPlanDevelopedMapped = [...this.allTrainingPlanDeveloped];

    this.successorDetails.bt_successortype = this.SuccessorType.Internal;

    this.successorDetails.bt_successorlegalentity = { id: this.userService?.user?.legalEntityId };

    this.userService.config$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      this.config = data;

      if (this.config?.legalEntityConfig?.successionAllowGroupEntities) {
        this.getAllLegalEntities();
      }
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  ngAfterViewInit(): void {
    this.sidebarService
      .getSuccessorDetails()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((payload) => {
        console.log('payload', payload);
        this.actionType = payload.type;
        this.readOnlyForm = this.actionType === 'view' ? true : false;

        if (this.actionType === 'view' || this.actionType === 'edit') {
          this.showFirstPage = false;
          this.showSecondPage = true;

          this.successorDetails = payload.successor;
          this.selectedRetentionPotential = { text: payload.successor?.bt_retentionpotential, value: payload.successor?.bt_retentionpotential };
          this.selectedRetirementStatus = { text: payload.successor?.bt_retirementstatus, value: payload.successor?.bt_retirementstatus };
          this.selectedTransferPotential = { text: payload.successor?.bt_mobilitytransferpotential, value: payload.successor?.bt_mobilitytransferpotential };
          // this.selectedReadyIn = { text: payload.successor?.bt_readyin, value: payload.successor?.bt_readyin };
          // this.selectedGapAnalysisStatus = { text: payload.successor?.bt_gapanalysisstatus, value: payload.successor?.bt_gapanalysisstatus };
          // this.selectedTrainingPlanDeveloped = { text: payload.successor?.bt_trainingplandeveloped, value: payload.successor?.bt_trainingplandeveloped };

          // Temp fixes
          if (payload.successor?.bt_readyin?.toString() == 'Now') {
            this.selectedReadyIn = { text: 'Now', value: 'Now' };
          } else if (payload.successor?.bt_readyin?.toString() == '_12years') {
            this.selectedReadyIn = { text: '1 - 2 years', value: '1 - 2 years' };
          } else if (payload.successor?.bt_readyin?.toString() == '_35years') {
            this.selectedReadyIn = { text: '3 - 5 years', value: '3 - 5 years' };
          } else if (payload.successor?.bt_readyin?.toString() == '_5yearsplus') {
            this.selectedReadyIn = { text: '5 years plus', value: '5 years plus' };
          }

          if (payload.successor?.bt_gapanalysisstatus?.toString() == 'Completed') {
            this.selectedGapAnalysisStatus = { text: 'Completed', value: 'Completed' };
          } else if (payload.successor?.bt_gapanalysisstatus?.toString() == 'Inprogress') {
            this.selectedGapAnalysisStatus = { text: 'In progress', value: 'In progress' };
          } else if (payload.successor?.bt_gapanalysisstatus?.toString() == 'Nostarted') {
            this.selectedGapAnalysisStatus = { text: 'No started', value: 'No started' };
          }

          if (payload.successor?.bt_trainingplandeveloped?.toString() == 'Completed') {
            this.selectedTrainingPlanDeveloped = { text: 'Completed', value: 'Completed' };
          } else if (payload.successor?.bt_trainingplandeveloped?.toString() == 'Inprogress') {
            this.selectedTrainingPlanDeveloped = { text: 'In progress', value: 'In progress' };
          } else if (payload.successor?.bt_trainingplandeveloped?.toString() == 'NotStarted') {
            this.selectedTrainingPlanDeveloped = { text: 'Not Started', value: 'Not Started' };
          }

          if (this.successorDetails.bt_trainingplanattachment_name != null) {
            this.getTrainingPlanFile();
          }

          this.cdr.detectChanges();
        }
      });
  }

  getTrainingPlanFile() {
    this.spinner.show('successortrainingplanfile');
    this.delegationsApiDelegationsService
      .downloadFile({
        body: {
          columnName: 'bt_trainingplanattachment',
          entityName: 'bt_successplan',
          recordID: this.successorDetails.bt_successplanid,
        },
      })
      .pipe(take(1))
      .subscribe(
        (file) => {
          this.trainingPlanBase64 = file;
          this.spinner.hide('successortrainingplanfile');
        },
        (error) => {
          console.error(error);
        },
      );
  }

  downloadFile() {
    const link = document.createElement('a');

    link.href = 'data:application/pdf;base64,' + this.trainingPlanBase64;
    link.download = this.successorDetails.bt_trainingplanattachment_name;
    link.click();
  }

  getAllLegalEntities() {
    this.sharedApiLegalEntitiesService
      .getAllLegalEntities()
      .pipe(take(1))
      .subscribe((ret) => {
        this.allLegalEntities = ret;
      });
  }

  onInternalRequiredChange(event: any) {
    this.successorDetails.bt_internaltrainingrequired = event.value;
  }

  onExternalRequiredChange(event: any) {
    this.successorDetails.bt_externaltrainingrequired = event.value;
  }

  onTrainingPlanChange(event: any) {
    this.successorDetails.bt_trainingplandeveloped = event.value;
  }

  onSuccessorTypeChange(event: any) {
    this.successorDetails.bt_successortype = event.value;
  }

  onLegalEntityChange(event: any) {
    this.successorDetails.bt_successorlegalentity = { id: event.bthr_legalentitiesid };
  }

  onSuccessorChange(event: any) {
    this.successorDetails.bt_successoremployeeid = { id: event.bthr_employeeid };
  }

  onReadyNowChange(event: any) {
    this.successorDetails.bt_readynow = event.value;
  }

  handleSuccessorFilter(search: string) {
    if (search.length >= 3) {
      this.userApiUserService
        .getUsersByLegalEntity({
          body: {
            search,
            legalEntityId: this.successorDetails.bt_successorlegalentity.id,
          },
        })
        .pipe(take(1))
        .subscribe(
          (ret) => {
            this.allEmployeesFilteredData = ret;
          },
          (error) => {
            console.error(error);
          },
          () => {
            if (this.allEmployeesFilteredData?.length === 0) {
              this.employeeDropdownMessage = 'No Data Found for ' + search;
            }
          },
        );
    } else if (search.length === 0) {
      this.allEmployeesFilteredData = [];
      this.employeeDropdownMessage = 'Type at least 3 characters';
    }
  }

  createNew(myForm: FormGroup) {
    this.spinner.show('successorchange');

    let body: DelegationsSuccessionPlanSuccessor = {
      bt_employeeid: { id: this.userService.user.employeeId },
      bt_employeeentity: { id: this.userService.user.legalEntityId },
      bt_successoremployeeid: myForm.value.newSuccessorType.value == DelegationsBtSuccessorType.Internal ? this.successorDetails.bt_successoremployeeid : null,
      bt_successorlegalentity:
        myForm.value.newSuccessorType.value == DelegationsBtSuccessorType.Internal ? this.successorDetails.bt_successorlegalentity : null,
      bt_successortype: myForm.value.newSuccessorType.value,
      bt_priorityno: myForm.value.newPriority.value,
      bt_comment: myForm.value.newSuccessorComment,
      bt_name: this.userService?.user?.employeeId + '_' + moment().format('YYYY-MM-DD, h:mm:ss a'),
    };

    this.delegationsApiDelegationsService
      .createUpdateSuccessionsSuccessors({ body: { body: body } })
      .pipe(take(1))
      .subscribe(
        (createdRecordID) => {
          if (myForm.value.newSuccessorType.value == DelegationsBtSuccessorType.Internal) {
            this.showFirstPage = false;
            this.showSecondPage = true;

            this.successorDetails = {
              ...body,
              bt_successplanid: createdRecordID,
              bt_lastreviewdate: moment().format('YYYY-MM-DD'),
              bt_externaltrainingcomments: '',
              bt_internaltrainingcomments: '',
            };
          } else {
            this.showConfirmationModal = true;
            this.resultMessage = 'Successor created successfully!';
          }

          this.spinner.hide('successorchange');
        },
        (error) => {
          console.error(error);
        },
      );
  }

  submitUpdate(myForm: FormGroup) {
    // Temp fixes
    let readyInValue: any;

    if (myForm.value.newReadyIn?.value == 'Now') {
      readyInValue = 'Now';
    } else if (myForm.value.newReadyIn?.value == '1 - 2 years') {
      readyInValue = '_12years';
    } else if (myForm.value.newReadyIn?.value == '3 - 5 years') {
      readyInValue = '_35years';
    } else if (myForm.value.newReadyIn?.value == '5 years plus') {
      readyInValue = '_5yearsplus';
    }

    let gapAnalysisValue: any;

    if (myForm.value.newGapStatus?.value == 'Completed') {
      gapAnalysisValue = 'Completed';
    } else if (myForm.value.newGapStatus?.value == 'In progress') {
      gapAnalysisValue = 'Inprogress';
    } else if (myForm.value.newGapStatus?.value == 'No started') {
      gapAnalysisValue = 'Nostarted';
    }

    let trainingPlanValue: any;

    if (myForm.value.newTrainingPlan?.value == 'Completed') {
      trainingPlanValue = 'Completed';
    } else if (myForm.value.newTrainingPlan?.value == 'In progress') {
      trainingPlanValue = 'Inprogress';
    } else if (myForm.value.newTrainingPlan?.value == 'Not started') {
      trainingPlanValue = 'Notstarted';
    }

    let body: DelegationsSuccessionPlanSuccessor = {
      bt_retentionpotential: myForm.value.newRetentionPotential?.value,
      bt_gapanalysisstatus: gapAnalysisValue,
      bt_mobilitytransferpotential: myForm.value.newTransferPotential?.value,
      bt_readyin: readyInValue,
      bt_trainingplandeveloped: trainingPlanValue,
      bt_retirementstatus: myForm.value.newRetirementStatus?.value,
      bt_externaltrainingrequired: myForm.value.newExternalRequired?.value,
      bt_readynow: myForm.value.newReadyNow?.value,
      bt_internaltrainingrequired: myForm.value.newInternalRequired?.value,
      bt_internaltrainingcomments: myForm.value.newInternalComment,
      bt_externaltrainingcomments: myForm.value.newExternalComment,
      bt_successplanid: this.successorDetails.bt_successplanid,
    };

    console.log('body', body);

    this.delegationsApiDelegationsService
      .createUpdateSuccessionsSuccessors({ body: { body: body } })
      .pipe(take(1))
      .subscribe(
        (result) => {
          console.log(result);
          this.showConfirmationModal = true;
          this.resultMessage = this.actionType == 'create' ? 'Successor created successfully!' : 'Successor details updated successfully!';
        },
        (error) => {
          console.error(error);
        },
      );

    if (this.trainingPlanFile.length > 0) {
      this.delegationsApiDelegationsService
        .uploadFile({
          body: {
            columnName: 'bt_trainingplanattachment',
            entityName: 'bt_successplan',
            recordID: this.successorDetails.bt_successplanid,
            file: this.trainingPlanFile[0],
          },
        })
        .pipe(take(1))
        .subscribe(
          (result) => {
            console.log(result);
          },
          (error) => {
            console.error(error);
          },
        );
    }
  }

  closeConfirmationModal() {
    this.showConfirmationModal = false;
    this.sidebarService.setShowSidebar(false);
  }
}
