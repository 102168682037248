<!-- Modal 1 -->
<verde-modal
  id="newRequestModal1"
  modalMinWidth="700"
  submitButton="Next"
  modalTitle="New Request"
  (actionButton)="viewModal('newRequestModal2')"
  [submitActive]="newRequestTypeForm?.valid"
  [hasSubmitConfirmation]="false"
  [hasCancelConfirmation]="newRequestTypeForm?.touched && !newRequestTypeForm?.pristine"
  cancelConfirmationMessage="Please confirm that you would like to cancel this request"
  (modalStateOpen)="isModalOpen($event)"
>
  <!--[submitActive]="this.requestTypeSelected"-->
  <section class="form-section">
    <form [formGroup]="this.requestModalService.getRequestTypeform()">
      <div class="form-group">
        <div class="form-field-container">
          <label>Request Type:</label>
          <div style="position: relative">
            <ngx-spinner
              [disableAnimation]="disableAnimation"
              name="requesttypespinner"
              bdColor="rgb(237, 237, 237)"
              size="default"
              color="#6264a7"
              type="ball-clip-rotate"
              [fullScreen]="false"
            >
            </ngx-spinner>

            <select class="custom-select" formControlName="odataPostRequestType" (change)="changeRequestType($event.target.value)">
              <option [value]="null" disabled selected hidden>Choose an option</option>
              <option *ngFor="let requestType of requestTypes" [value]="'/bt_verderequesttypes(' + requestType.bt_verderequesttypeid + ')'">
                {{ requestType.bt_verderequesttype }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="request-type-description form-field-container">
          <label>Description: </label>
          <br />
          <textarea class="form-control" [readonly]="true" #requestTypeDescriptionTextArea rows="3" formControlName="bt_description"> </textarea>
        </div>
      </div>

      <div class="form-group" *ngIf="currentRequestType && currentRequestType.bt_displaytemplate">
        <div class="form-field-container" *ngIf="templateFileDownloadInProgress">
          <div class="spinner-wrapper">
            <ngx-spinner
              [disableAnimation]="disableAnimation"
              name="templateDocumentSpinner"
              bdColor="rgb(237, 237, 237)"
              size="default"
              color="#6264a7"
              type="ball-clip-rotate"
              [fullScreen]="false"
            >
            </ngx-spinner>
          </div>
        </div>

        <div class="form-field-container" *ngIf="!templateFileDownloadInProgress">
          <label style="margin: 6px 5px 0px auto; width: 170px">Template Document:</label>
          <p
            class="link_class"
            style="margin: 6px 5px 0px auto"
            title="Click to download attachment"
            (click)="downloadFile(templateDocumentBase64, currentRequestType?.bt_speclabel)"
          >
            {{ currentRequestType?.bt_speclabel }}
          </p>
        </div>
      </div>

      <div class="modal_grid">
        <label>Process Owner</label>
        <br />
        <div class="form-group">
          <div class="form-field-container">
            <input
              class="form-control"
              type="text"
              [disabled]="true"
              formControlName="odataProcessLegalEntity"
              [value]="this.currentRequestType?.odataProcessLegalEntity || ''"
              readonly
            />
          </div>
        </div>

        <div class="form-group">
          <div class="form-field-container">
            <input
              class="form-control"
              type="text"
              [disabled]="true"
              formControlName="bt_fullname"
              [value]="this.currentRequestType?.bt_ProcessOwner?.bt_fullname || ''"
              readonly
            />
          </div>
        </div>
      </div>
    </form>
  </section>
</verde-modal>

<!-- Modal 2 -->
<verde-modal
  id="newRequestModal2"
  modalMinWidth="1200"
  submitButton="Submit"
  modalTitle="New Request"
  (actionButton)="submitRequest()"
  [hasSubmitConfirmation]="true"
  [hasCancelConfirmation]="true"
  [hasCancelButton]="false"
  submitConfirmationMessage="Please confirm that you would like to submit this request"
  cancelConfirmationMessage="Please confirm that you would like to cancel"
  [linkedToPreviousModal]="true"
  previousModalName="newRequestModal1"
  [navigateToPreviousModal]="this.viewModal"
  [saveActionAvailable]="true"
  (saveActionButton)="this.presentSaveOrDeleteModal()"
  saveActionButtonText="Close"
  [submitActive]="this.requestModalService.getRequestForm().valid && isSpecDocFieldValid()"
>
  <section class="form-section">
    <form [formGroup]="this.requestModalService.getRequestForm()">
      <div class="modal_grid">
        <div class="form-group">
          <div class="form-field-container">
            <label>Request Type:</label>
            <br />
            <input class="form-control" type="text" [disabled]="true" [value]="this.currentRequestType?.bt_verderequesttype" />
          </div>
        </div>

        <div class="form-group">
          <div class="form-field-container">
            <label>Request Date:</label>
            <input
              type="date"
              min="{{ minDate | date : 'yyyy-MM-dd' }}"
              value="{{ minDate | date : 'yyyy-MM-dd' }}"
              class="form-control"
              formControlName="bt_requestdate"
            />
          </div>
          <div
            class="alert alert-danger"
            *ngIf="
              this.requestModalService.getRequestForm().controls.bt_requestdate.touched &&
              !this.requestModalService.getRequestForm().controls.bt_requestdate.valid
            "
          >
            Field is required!
          </div>
        </div>

        <div class="form-group">
          <div class="form-field-container">
            <label>Description:</label>
            <textarea #descriptionTextArea rows="3" formControlName="bt_description"> </textarea>
          </div>
          <div
            class="alert alert-danger"
            *ngIf="
              this.requestModalService.getRequestForm().controls.bt_description.touched &&
              !this.requestModalService.getRequestForm().controls.bt_description.valid
            "
          >
            Field is required!
          </div>
        </div>

        <div class="form-group">
          <div class="form-field-container">
            <label>Motivation:</label>
            <textarea #motivationTextArea rows="3" formControlName="bt_motivation"> </textarea>
          </div>
          <div
            class="alert alert-danger"
            *ngIf="
              this.requestModalService.getRequestForm().controls.bt_motivation.touched &&
              !this.requestModalService.getRequestForm().controls.bt_motivation.valid
            "
          >
            Field is required!
          </div>
        </div>

        <!-- Spec attachment -->
        <div class="form-group">
          <div class="form-field-container" *ngIf="this.currentRequestType?.bt_displayspecificationattachment">
            <label>{{ currentRequestType?.bt_speclabel || 'Upload Specification Document' }}</label>
            <br />
            <input
              type="file"
              title="Upload Specification Document"
              [required]="this.currentRequestType?.bt_specificationrequired"
              (change)="uploadSpecificationDocument($event)"
            />
          </div>
          <div
            class="alert alert-danger"
            *ngIf="
              this.requestModalService.getRequestForm().controls.bt_specificationdocumentname.touched &&
              !this.requestModalService.getRequestForm().controls.bt_specificationdocumentname.valid
            "
          >
            Field is required!
          </div>
        </div>

        <!-- Quote 1 -->
        <div class="quote-1" *ngIf="this.currentRequestType?.bt_numberofquotesrequired > 0">
          <div class="form-group" *ngIf="this.currentRequestType?.bt_quoteamountrequired">
            <div class="form-field-container">
              <label>Amount:</label>
              <br />
              <input class="form-control quote-amount" type="number" placeholder="R0.00" step="0.01" min="0" formControlName="bt_quote1value" />
            </div>
            <div
              class="alert alert-danger"
              *ngIf="
                this.requestModalService.getRequestForm().controls.bt_quote1value.touched &&
                !this.requestModalService.getRequestForm().controls.bt_quote1value.valid
              "
            >
              Field is required!
            </div>
          </div>

          <div class="form-group">
            <div class="form-field-container" *ngIf="this.currentRequestType?.bt_quoteallowed">
              <label>{{ this.currentRequestType?.bt_label1 || 'Upload' }}</label>
              <br />
              <input type="file" title="Upload Quote 1" [required]="this.currentRequestType?.bt_quoteallowed" (change)="uploadQuote($event, 1)" />
            </div>
          </div>
        </div>

        <!-- Quote 2 -->
        <div class="quote-2" *ngIf="this.currentRequestType?.bt_numberofquotesrequired > 1">
          <div class="form-group" *ngIf="this.currentRequestType?.bt_quoteamountrequired">
            <div class="form-field-container">
              <label>Amount:</label>
              <br />
              <input class="form-control quote-amount" type="number" placeholder="R0.00" step="0.01" min="0" formControlName="bt_quotevalue2" />
            </div>
            <div
              class="alert alert-danger"
              *ngIf="
                this.requestModalService.getRequestForm().controls.bt_quotevalue2.touched &&
                !this.requestModalService.getRequestForm().controls.bt_quotevalue2.valid
              "
            >
              Field is required!
            </div>
          </div>

          <div class="form-group">
            <div class="form-field-container" *ngIf="this.currentRequestType?.bt_quoteallowed">
              <label>{{ this.currentRequestType?.bt_label2 || 'Upload' }}</label>
              <br />
              <input type="file" title="Upload Quote 2" [required]="this.currentRequestType?.bt_quoteallowed" (change)="uploadQuote($event, 2)" />
            </div>
          </div>
        </div>

        <!-- Quote 3 -->
        <div class="quote-3" *ngIf="this.currentRequestType?.bt_numberofquotesrequired > 2">
          <div class="form-group" *ngIf="this.currentRequestType?.bt_quoteamountrequired">
            <div class="form-field-container">
              <label>Amount:</label>
              <br />
              <input class="form-control quote-amount" type="number" placeholder="R0.00" step="0.01" min="0" formControlName="bt_quotevalue3" />
            </div>
            <div
              class="alert alert-danger"
              *ngIf="
                this.requestModalService.getRequestForm().controls.bt_quotevalue3.touched &&
                !this.requestModalService.getRequestForm().controls.bt_quotevalue3.valid
              "
            >
              Field is required!
            </div>
          </div>

          <div class="form-group">
            <div class="form-field-container" *ngIf="this.currentRequestType?.bt_quoteallowed">
              <label>{{ this.currentRequestType?.bt_label3 || 'Upload' }}</label>
              <br />
              <input type="file" title="Upload Quote 3" [required]="this.currentRequestType?.bt_quoteallowed" (change)="uploadQuote($event, 3)" />
            </div>
          </div>
        </div>
      </div>
    </form>
  </section>
</verde-modal>

<verde-modal modalTitle="Confirmation" id="newRequestConfirmationModal" modalMaxWidth="400" cancelText="Close">
  <div style="position: relative; height: 60px">
    <ngx-spinner
      [disableAnimation]="disableAnimation"
      name="confirmationSpinner"
      bdColor="rgb(237, 237, 237)"
      size="default"
      color="#6264a7"
      type="ball-clip-rotate"
      [fullScreen]="false"
    >
    </ngx-spinner>

    <p>{{ confirmationMessage }}</p>
  </div>
</verde-modal>

<verde-modal
  modalTitle="Confirmation"
  id="saveOrDeleteModal"
  modalMinWidth="700"
  [saveActionAvailable]="true"
  (saveActionButton)="this.saveCurrentRequest()"
  saveActionButtonText="Save"
  submitButton="Delete"
  (actionButton)="deleteRequest()"
>
  <p>Would you like to save as draft or delete this request?</p>
</verde-modal>
