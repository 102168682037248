import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { RefreshService } from '../../../services/refresh-service';
import { ProcessesApiProcessesService, ProcessesValidateTaxNumberQuery } from '@verde/shared';

@Component({
  selector: 'verde-onboarding-tax-info',
  templateUrl: './onboarding-tax-info.component.html',
  styleUrls: ['./onboarding-tax-info.component.scss'],
})
export class OnboardingTaxInfoComponent implements OnInit {
  private onDestroy$ = new Subject<boolean>();

  @Output() validateTaxNumberEvent = new EventEmitter<void>();

  @Input() public paymentDetails: FormGroup;
  @Input() taxValidation: string;

  taxValidationMessage: string = '';

  constructor(private refreshService: RefreshService, private processesApiProcessesService: ProcessesApiProcessesService) {}

  ngOnInit(): void {
    this.refreshService.sharedValue$.pipe(takeUntil(this.onDestroy$)).subscribe((value) => {
      this.taxValidation = value;
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  validateTaxNumber() {
    this.refreshService.sharedValue$.subscribe((value) => {
      this.taxValidation = value;
    });
    let validation: ProcessesValidateTaxNumberQuery = {
      countryName: this.taxValidation,
      taxNumber: this.paymentDetails.value.taxNo,
    };
    this.processesApiProcessesService
      .validateTaxNumber({ body: validation })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (val) => {
          this.taxValidationMessage = val;
        },
        () => {},
      );
  }

  onRadioChange() {
    this.paymentDetails?.get('taxNo')?.setValue('');
  }
}
