<!-- #region Verde Approval Modal -->
<verde-modal
  id="verdeApprovalModal"
  modalTitle="{{ approvalDefinitionHeader }}"
  [modalMinWidth]="750"
  [modalMaxWidth]="750"
  submitButton="{{ approveButtonText }}"
  (actionButton)="patchApproval(verdeApprovalForm)"
  (modalCancelled)="hideModal($event)"
  [submitActive]="verdeApprovalForm.valid && (agreedYes || agreedNo)"
  submitConfirmationMessage="Please confirm that you would like to submit this {{ confirmationAction }}"
  cancelConfirmationMessage="Please confirm that you would like to cancel"
  [hasSubmitConfirmation]="true"
  [hasCancelConfirmation]="verdeApprovalForm.touched && !verdeApprovalForm.pristine"
>
  <form #verdeApprovalForm="ngForm" novalidate autocomplete="off" style="position: relative">
    <div class="form-group">
      <label for="submittedBy">Submitted By: {{ approvalLogGroup?.bt_SubmittedBy?.bt_fullname }}</label>
    </div>

    <div class="form-group">
      <kendo-textbox [readonly]="true" id="approvalType" name="approvalType"> </kendo-textbox>
    </div>

    <div *ngIf="agreedNo" class="form-group">
      <label for="approveRequestComment">Reason for not approving:</label>
      <kendo-textarea
        id="approveRequestComment"
        name="approveRequestComment"
        #varApproveRequestComment="ngModel"
        maxlength="300"
        resizable="vertical"
        required
        [ngModel]
      >
      </kendo-textarea>

      <!--Validation -->
      <div class="alert alert-danger" *ngIf="varApproveRequestComment.touched && !varApproveRequestComment.valid">
        <div *ngIf="varApproveRequestComment.errors?.required">Field is required!</div>
        <div *ngIf="varApproveRequestComment.errors?.minlength">Enter a detailed reason.</div>
      </div>
    </div>

    <div class="form-group">
      <div style="float: right; display: inline-flex">
        <label style="margin-bottom: unset; margin-top: 3px">Approve:</label>

        <div style="margin-left: 5px">
          <label style="margin-bottom: unset; margin-top: 3px" for="agreedYes">Yes:</label>
          <input
            style="width: 25px; height: 20px; margin: 0 0 0 5px; vertical-align: middle"
            (change)="agreedYesChange($event.target.checked, varAgreedNo)"
            type="checkbox"
            kendoCheckBox
            class="form-control"
            id="agreedYes"
            name="agreedYes"
            #varAgreedYes="ngModel"
            [ngModel]="agreedYes"
          />

          <label for="agreedNo" style="margin-left: 5px; margin-bottom: unset; margin-top: 3px">No:</label>
          <input
            style="width: 25px; height: 20px; margin: 0 0 0 5px; vertical-align: middle"
            (change)="agreedNoChange($event.target.checked, varAgreedYes)"
            type="checkbox"
            kendoCheckBox
            class="form-control"
            id="agreedNo"
            name="agreedNo"
            #varAgreedNo="ngModel"
            [ngModel]="agreedNo"
          />
        </div>
      </div>
      <br />
    </div>
  </form>
</verde-modal>
<!-- #endregion Verde Approval Modal -->
