<div class="main">
  <div class="scrollable-content">
    <form class="k-form sidepanel-form"
          [formGroup]="fg"
          autocomplete="off">
      <kendo-gridlayout gap="2% 5%"
                        [rows]="[]"
                        [cols]="[{ width: '1fr' }, { width: '1fr' }]">
        <kendo-gridlayout-item [row]="1"
                               [col]="1">
          <kendo-formfield>
            <kendo-label for="DocTypeName">Document Type Name:</kendo-label>
            <kendo-textbox formControlName="DocTypeName"
                           required
                           id="DocTypeName"
                           name="DocTypeName"
                           [clearButton]="true"> </kendo-textbox>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item [row]="1"
                               [col]="2">
          <kendo-formfield>
            <kendo-label for="groupingDropdown">Grouping:</kendo-label>
            <kendo-dropdownlist formControlName="groupingDropdown"
                                required
                                [data]="menuOptions"
                                id="groupingDropdown"
                                name="groupingDropdown"
                                required>
            </kendo-dropdownlist>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item [row]="2"
                               [col]="1">
          <kendo-formfield>
            <kendo-label for="MaskName">Mask:</kendo-label>
            <kendo-textbox formControlName="MaskName"
                           required
                           id="MaskName"
                           name="MaskName"
                           [clearButton]="true"> </kendo-textbox>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item [row]="2"
                               [col]="2">
          <kendo-formfield required
                           orientation="horizontal"
                           class="radio-buttons"
                           style="margin-top: 7%">
            <kendo-label class="radio-label">Allow Bulk Uploads</kendo-label>
            <ul class="k-radio-list k-list-horizontal">
              <li class="k-radio-item">
                <input type="radio"
                       #yes
                       value="true"
                       kendoRadioButton
                       formControlName="radioButton" />
                <kendo-label class="k-radio-label"
                             [for]="yes"
                             text="Yes"></kendo-label>
              </li>
              <li class="k-radio-item">
                <input type="radio"
                       #no
                       value="false"
                       kendoRadioButton
                       formControlName="radioButton" />
                <kendo-label class="k-radio-label"
                             [for]="no"
                             text="No"></kendo-label>
              </li>
            </ul>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item [row]="3"
                               [col]="1"
                               [colSpan]="2">
          <span class="k-form-separator2"></span>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item [row]="4"
                               [col]="1"
                               [colSpan]="2">
          <kendo-grid #myGrid
                      [rowClass]="rowCallback"
                      [kendoGridBinding]="allSkeletonData"
                      [resizable]="true"
                      [scrollable]="true"
                      (dataStateChange)="skeletonDataStateChange($event)"
                      [selectable]="selectableSettings"
                      [kendoGridSelectBy]="skeletonSelectedCallback"
                      [selectedKeys]="skeletonSelection">
            <kendo-grid-column field="bt_doctypefinancial"
                               title="Document"> </kendo-grid-column>
            <kendo-grid-column field="documentGrouping"
                               title="Grouping"> </kendo-grid-column>
            <kendo-grid-column field="bt_allowbulkupload"
                               title="Bulk Uploads">
              <ng-template kendoGridCellTemplate
                           let-dataItem>
                {{ dataItem.bt_allowbulkupload ? 'Yes' : 'No' }}
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-excel fileName="Doc Types.xlsx"></kendo-grid-excel>
          </kendo-grid>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
    </form>
  </div>
</div>
<div #appendTo
     class="fixed-buttons">
  <span class="k-form-separator"></span>
  <br />
  <div class="k-form-buttons k-buttons-end">
    <div>
      <button kendoButton
              themeColor="primary"
              (click)="Create()"
              [disabled]="!isFormValid()">Create</button>
    </div>
  </div>
</div>
<!-- #region Confirmation Modal -->
<kendo-dialog *ngIf="showConfirmationModal"
              title="Confirmation"
              verdeResponsiveDialog>
  <div style="height: 60px">
    <ngx-spinner [disableAnimation]="disableAnimation"
                 name="uploadSpinner"
                 bdColor="rgb(255, 255, 255)"
                 size="default"
                 color="#6264a7"
                 type="ball-clip-rotate"
                 [fullScreen]="false">
    </ngx-spinner>
    <p style="text-align: center"></p>
    <p>Your new Document Type has been created.</p>
  </div>
  <kendo-dialog-actions>
    <button kendoButton
            themeColor="primary"
            style="margin-left: auto"
            (click)="closeConfirmationModal()">Continue</button>
  </kendo-dialog-actions>
</kendo-dialog>
<!-- #endregion Confirmation Modal -->