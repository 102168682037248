/* tslint:disable */
/* eslint-disable */
export enum CommonBtVerdeStandardProcess {
  QualificationEmployeeAddModify = 'QualificationEmployeeAddModify',
  QualificationEmployeeRenewAddModify = 'QualificationEmployeeRenewAddModify',
  QualificationRequestnewQualification = 'QualificationRequestnewQualification',
  QualificationRequestnewInstitution = 'QualificationRequestnewInstitution',
  StructureRequestPositionSeat = 'StructureRequestPositionSeat',
  StructureRequestPosition = 'StructureRequestPosition',
  StructureRequestDepartment = 'StructureRequestDepartment',
  StructureRequestDivision = 'StructureRequestDivision',
  StructureRequestLegalEnity = 'StructureRequestLegalEnity',
  OffboardNotice = 'OffboardNotice',
  OffboardExit = 'OffboardExit',
  StructureTransfertoEntity = 'StructureTransfertoEntity',
  StructureTransfertoDepartment = 'StructureTransfertoDepartment',
  StructureTransfertoPositionSeat = 'StructureTransfertoPositionSeat',
  StructureTransferSeattoPosition = 'StructureTransferSeattoPosition',
  StructureReplacePositionSeat = 'StructureReplacePositionSeat',
  FleetInfringementRedirect = 'FleetInfringementRedirect',
  PeopleManagementReplacePositionSeat = 'PeopleManagementReplacePositionSeat',
  TakeonReplacePositionSeat = 'TakeonReplacePositionSeat',
  PeopleManagementRequestPositionSeat = 'PeopleManagementRequestPositionSeat',
  TakeonRequestPositionSeat = 'TakeonRequestPositionSeat',
  PeopleManagementRequestPosition = 'PeopleManagementRequestPosition',
  PeopleManagementRequestDepartment = 'PeopleManagementRequestDepartment',
  PeopleManagementRequestDivision = 'PeopleManagementRequestDivision',
  PeopleManagementTransfertoEntity = 'PeopleManagementTransfertoEntity',
  PeopleManagementTransfertoDepartment = 'PeopleManagementTransfertoDepartment',
  PeopleManagementTransfertoPositionSeat = 'PeopleManagementTransfertoPositionSeat',
  PeopleManagementTransferSeattoPosition = 'PeopleManagementTransferSeattoPosition',
}
