<!--<verde-modal id="structureDisposeRequestModal"
          class="structure-dispose-request-modal"
          submitButton="Dispose"
          [submitActive]="isSubmitActive"
          [modalTitle]="'Structure Dispose Request'"
          (actionButton)="dispose()"
          [modalMaxWidth]="600"
          [modalMinWidth]="600"
          [hasSubmitConfirmation]="true"
          [hasCancelConfirmation]="true"
          (cancelButton)="close()"
          (modalCancelled)="close()">-->
<div class="content">
  <form [formGroup]="fg" autocomplete="off">
    <div fxLayout="column">
      <kendo-formfield>
        <kendo-label [for]="effectiveDate" text="Effective Date"></kendo-label>
        <kendo-datepicker formControlName="effectiveDate" #effectiveDate format="yyyy/MM/dd" required></kendo-datepicker>
      </kendo-formfield>

      <kendo-formfield>
        <kendo-label [for]="motivation" text="Motivation"></kendo-label>
        <input formControlName="motivation" kendoTextBox #motivation required />
      </kendo-formfield>
    </div>
  </form>

  <button class="form-buttons" kendoButton themeColor="primary" [disabled]="!isSubmitActive" (click)="dispose()">Dispose</button>
</div>
<!--</verde-modal>-->
