<form [formGroup]="fg" autocomplete="off" class="k-form" style="margin-top: 20px">
  <!-- <kendo-formfield>
    <kendo-label [for]="structureType"
                 text="Structure Type"></kendo-label>
    <kendo-dropdownlist #structureType
                        formControlName="structureType"
                        [data]="structureTypes"
                        textField="StructureType"
                        valueField="StructureType"
                        [valuePrimitive]="true"
                        required>
    </kendo-dropdownlist>
  </kendo-formfield> -->

  <div class="grid-container">
    <kendo-gridlayout gap="1% 10%" [rows]="[{ height: 70 }, { height: 120 }]" [cols]="[{ width: gridColWidth }, { width: gridColWidth }]">
      <kendo-gridlayout-item [row]="1" [col]="1">
        <kendo-formfield>
          <kendo-label [for]="nameNew" text="Name"></kendo-label>
          <input formControlName="bthr_description" kendoTextBox #nameNew required />
        </kendo-formfield>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item [row]="1" [col]="2">
        <kendo-formfield>
          <kendo-label [for]="effectiveDate" text="Effective Date"></kendo-label>
          <kendo-datepicker formControlName="effectiveDate" #effectiveDate format="yyyy/MM/dd" required></kendo-datepicker>
        </kendo-formfield>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item [row]="2" [col]="1" [colSpan]="2">
        <kendo-formfield>
          <kendo-label [for]="motivation" text="Motivation"></kendo-label>
          <kendo-textarea formControlName="motivation" #motivation [rows]="3" resizable="vertical" required></kendo-textarea>
        </kendo-formfield>
      </kendo-gridlayout-item>
    </kendo-gridlayout>
  </div>
</form>
<div *ngIf="display === 'positionList'" class="content">
  <kendo-grid
    [kendoGridBinding]="positions"
    kendoGridSelectBy="bthr_positionid"
    [sortable]="true"
    [reorderable]="true"
    [height]="500"
    [rowClass]="rowClass.bind(this)"
    [columnMenu]="{ filter: true }"
  >
    <kendo-grid-column field="bthr_description" title="Name"> </kendo-grid-column>
  </kendo-grid>
  <button class="form-buttons" kendoButton themeColor="primary" (click)="add()">Add</button>
</div>
<button class="form-buttons" kendoButton themeColor="primary" (click)="add()">Add</button>
