import { Injectable } from '@angular/core';
declare global {
  interface Window {
    AdobeDC: any;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ViewSDKClient {
  private _credentialKey: string = '';

  set credentialKey(val: string) {
    this._credentialKey = val;
  }

  readyPromise: Promise<any> = new Promise((resolve) => {
    if (window.AdobeDC) {
      resolve(true);
    } else {
      /* Wait for Adobe Document Services PDF Embed API to be ready */
      document.addEventListener('adobe_dc_view_sdk.ready', () => {
        resolve(true);
      });
    }
  });
  adobeDCView: any;

  ready() {
    return this.readyPromise;
  }

  base64ToArrayBuffer(base64: string) {
    var bin = window.atob(base64);
    var len = bin.length;
    var uInt8Array = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      uInt8Array[i] = bin.charCodeAt(i);
    }
    return uInt8Array.buffer;
  }

  previewFile(divId: string, viewerConfig: any, base46: string, fileName: string) {
    const config: any = {
      /* Pass your registered client id */
      clientId: this._credentialKey,
    };
    if (divId) {
      /* Optional only for Light Box embed mode */
      /* Pass the div id in which PDF should be rendered */
      config.divId = divId;
    }
    /* Initialize the AdobeDC View object */
    this.adobeDCView = new window.AdobeDC.View(config);

    /* Invoke the file preview API on Adobe DC View object */
    const previewFilePromise = this.adobeDCView.previewFile(
      {
        /* Pass information on how to access the file */
        content: { promise: Promise.resolve(this.base64ToArrayBuffer(base46)) },
        /* Pass meta data of file */
        metaData: {
          /* file name */
          fileName: fileName,
        },
      },
      {
        showAnnotationTools: false,
      },
    );

    return previewFilePromise;
  }

  previewFileUsingFilePromise(divId: string, filePromise: Promise<string | ArrayBuffer>, fileName: any) {
    /* Initialize the AdobeDC View object */
    this.adobeDCView = new window.AdobeDC.View({
      /* Pass your registered client id */
      clientId: this._credentialKey,
      /* Pass the div id in which PDF should be rendered */
      divId,
    });

    /* Invoke the file preview API on Adobe DC View object */
    this.adobeDCView.previewFile(
      {
        /* Pass information on how to access the file */
        content: {
          /* pass file promise which resolve to arrayBuffer */
          promise: filePromise,
        },
        /* Pass meta data of file */
        metaData: {
          /* file name */
          fileName,
        },
      },
      {},
    );
  }

  registerSaveApiHandler() {
    /* Define Save API Handler */
    const saveApiHandler = (metaData: any, content: any, options: any) => {
      return new Promise((resolve) => {
        /* Dummy implementation of Save API, replace with your business logic */
        setTimeout(() => {
          const response = {
            code: window.AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
            data: {
              metaData: Object.assign(metaData, { updatedAt: new Date().getTime() }),
            },
          };
          resolve(response);
        }, 2000);
      });
    };

    this.adobeDCView.registerCallback(window.AdobeDC.View.Enum.CallbackType.SAVE_API, saveApiHandler, {});
  }

  registerEventsHandler() {
    /* Register the callback to receive the events */
    this.adobeDCView.registerCallback(
      /* Type of call back */
      window.AdobeDC.View.Enum.CallbackType.EVENT_LISTENER,
      /* call back function */
      (event: any) => {
        console.log(event);
      },
      /* options to control the callback execution */
      {
        /* Enable PDF analytics events on user interaction. */
        enablePDFAnalytics: true,
      },
    );
  }
}
