import { PeerReviewFeedback, PeerReviewFeedbackSummary, User } from '@verde/shared';
import { PeerReviewUser } from './peer-review-user';

export interface UserPeerReviewProcess {
  employee?: User;
  peerReviewSummaries?: PeerReviewFeedbackSummary;
  questions?: PeerReviewFeedback[];
  peerReviewUser?: PeerReviewUser;
  idRef?: number;
}
