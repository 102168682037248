<div *ngIf="getFormTypeValueFrontPage === WebDynamicFormType.GRID">
  <form class="k-form" [formGroup]="dynamicFormGroupFrontPage" *ngIf="dynamicFormfieldsFrontPage">
    <formly-form
      [model]="dynamicModelFrontPage"
      [fields]="dynamicFormfieldsFrontPage"
      [options]="optionsFrontPage"
      [form]="dynamicFormGroupFrontPage"
    ></formly-form>
  </form>
</div>
