import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldProps } from '@ngx-formly/core';

export interface KendoFormlyCheckboxProps extends FormlyFieldProps {
  hideLabel?: boolean;
}

@Component({
  selector: 'verde-kendo-formly-checkbox',
  template: `
    <input
      [id]="field.key"
      type="checkbox"
      kendoCheckBox
      [formControl]="formControl"
      [formlyAttributes]="field"
      [readonly]="props.readonly"
      [required]="props.required"
      [tabIndex]="props.tabindex || 0"
    />
    <label [for]="id" class="k-checkbox-label">
      {{ props.label }}
      <span *ngIf="props.required" aria-hidden="true" class="k-required">*</span>
    </label>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./checkbox.type.scss'],
})
export class KendoFormlyCheckbox extends FieldType<FieldTypeConfig<KendoFormlyCheckboxProps>> {
  override defaultOptions = {
    props: {
      hideLabel: true,
    },
  };
}
