<div style="height: 80vh">
  <kendo-grid [kendoGridBinding]="gridData" [resizable]="true" [scrollable]="true" [sortable]="true" [groupable]="true" [loading]="gridLoading">
    <ng-template kendoGridToolbarTemplate style="height: 57px">
      <kendo-grid-spacer></kendo-grid-spacer>
      <button style="height: 36px; margin-left: auto" kendoGridExcelCommand icon="file-excel">Export to Excel</button>
      <img title="Refresh" class="refresh_icon" src="https://engageimages.z1.web.core.windows.net/Images/Verde/Other/reload.svg" alt="" (click)="refresh()" />
    </ng-template>
    <kendo-grid-column *ngFor="let column of columns" [field]="column.field" [title]="column.title" [width]="column.width"> </kendo-grid-column>
    <kendo-grid-command-column title="" [width]="40" [sticky]="true">
      <ng-template kendoGridCellTemplate let-ListTable>
        <verde-dots-menu-button
          [items]="dotMenuItems"
          (menuClicked)="menuClicked(ListTable)"
          (menuItemClicked)="menuItemClicked($event)"
        ></verde-dots-menu-button>
      </ng-template>
    </kendo-grid-command-column>
  </kendo-grid>
</div>
