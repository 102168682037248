<div *ngIf="showPDFpreview === false" style="padding: 20px">
  <form [formGroup]="columnOneForm" *ngIf="columnOneFormfields">
    <formly-form [model]="columnOneModel" [fields]="columnOneFormfields" [options]="options" [form]="columnOneForm"></formly-form>
    <button
      id="submit-button"
      [disabled]="isDisabled || !columnOneForm.valid"
      style="float: right"
      kendoButton
      themeColor="primary"
      (click)="submit()"
      (ngSubmit)="submit()"
      type="submit"
    >
      Submit
    </button>
  </form>
</div>

<!--<verde-modal modalTitle="Confirmation"
          id="confirmFundOptionChange"
          [hasCancelButton]="true"
          submitButton="Continue"
          (actionButton)="confirm()"
          modalMaxWidth="400">

  <div style="position: relative; height: 60px;">

    <p>Please confirm that you would like to change fund option to {{ newFundOption }}%</p>
  </div>
</verde-modal>-->

<div *ngIf="showPDFpreview === true" style="max-height: 200px">
  <verde-provident-fund
    [pdfObject]="columnOneModel"
    [selectedFund]="selectedFund"
    [newFundOptionID]="newFundOptionID"
    [existingOptionInt]="existingOptionInt"
  ></verde-provident-fund>
</div>
