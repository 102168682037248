<!-- #region Request Transfer Modal -->
<verde-modal
  [id]="'newIssueReportModal' + issueLocation"
  modalTitle="Report Issue - {{ issueLocation }}"
  submitButton="Submit"
  (actionButton)="postIssue(reportIssueForm)"
  (modalCancelled)="hideModal()"
  [submitActive]="reportIssueForm.valid"
  submitConfirmationMessage="Please confirm that you would like to submit this issue"
  cancelConfirmationMessage="Please confirm that you would like to cancel"
  [hasSubmitConfirmation]="true"
  [hasCancelConfirmation]="reportIssueForm.touched && !reportIssueForm.pristine"
>
  <form #reportIssueForm="ngForm" novalidate autocomplete="off">
    <div class="form-group">
      <label for="issueDescription">Issue Description</label>
      <kendo-textarea id="issueDescription" name="issueDescription" #varIssueDescription="ngModel" maxlength="300" resizable="vertical" required [ngModel]>
      </kendo-textarea>

      <!-- Validation -->
      <div class="alert alert-danger" *ngIf="varIssueDescription.touched && !varIssueDescription.valid" style="width: 250px">
        <div *ngIf="varIssueDescription.errors?.required">Field is required!</div>
      </div>
    </div>
    <div class="form-group" style="max-width: 278.25px">
      <label for="issueAttachment">Attachment:</label>
      <kendo-fileselect id="issueAttachment" name="issueAttachment" [ngModel] [multiple]="false"> </kendo-fileselect>
    </div>
  </form>
</verde-modal>
<!-- #endregion Request Transfer Modal -->
<!-- #region Confirmation Modal -->
<verde-modal
  modalTitle="Confirmation"
  id="confirmIssueModal"
  modalMaxWidth="400"
  [hasCancelButton]="false"
  submitButton="Continue"
  (actionButton)="hideModal()"
>
  <div style="position: relative; height: 60px">
    <ngx-spinner
      [disableAnimation]="disableAnimation"
      name="confirmissue"
      bdColor="rgb(237, 237, 237)"
      size="default"
      color="#6264a7"
      type="ball-clip-rotate"
      [fullScreen]="false"
    >
    </ngx-spinner>

    <p>{{ confirmationMessage }}</p>
  </div>
</verde-modal>
<!-- #endregion Confirmation Modal -->
