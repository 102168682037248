import { Injectable, OnDestroy, Renderer2, RendererFactory2 } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, fromEvent, Subject } from 'rxjs';

// Tablet
const TABLET_SCREEN = 868;
const TABLET_SCREEN_BREAKPOINT = `(max-width: ${TABLET_SCREEN}px)`;

// Mobile
const MOBILE_SCREEN = 586;
const MOBILE_SCREEN_BREAKPOINT = `(max-width: ${MOBILE_SCREEN}px)`;

//Screen size
/*
    "xs"	"(max-width: 599.98px)"
    "sm"	"(max-width: 959.98px)"
    "md"	"(max-width: 1279.98px)"
    "lg"	"(max-width: 1919.98px)"
    "xl"	"(min-width: 1920px)"
*/
const XS_BREAKPOINT_SIZE = 599.98; //(max-width: 599.98px)
const SM_BREAKPOINT_SIZE = 959.98; //(max-width: 959.98px)
const MD_BREAKPOINT_SIZE = 1279.98; //(max-width: 1279.98px)
const LG_BREAKPOINT_SIZE = 1919.98; //(max-width: 1919.98px)
const XL_BREAKPOINT_SIZE = 1920; //(min-width: 1920px)

interface ScreenSizeDetailed {
  size: number;
  name: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

@Injectable({
  providedIn: 'root',
})
export class DeviceTypeService implements OnDestroy {
  renderer: Renderer2;
  onDestroy$ = new Subject<boolean>();
  isTablet$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isMobile$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  screenSizeDetailed$: BehaviorSubject<ScreenSizeDetailed | undefined> = new BehaviorSubject<ScreenSizeDetailed | undefined>(undefined);

  constructor(private rendererFactory2: RendererFactory2, private readonly breakpointObserver: BreakpointObserver) {
    this.determineScreenSize(window.innerWidth);

    this.breakpointObserver
      .observe([MOBILE_SCREEN_BREAKPOINT])
      .pipe(
        map((m) => m.matches),
        startWith(false),
        takeUntil(this.onDestroy$),
      )
      .subscribe((isMobile) => {
        this.isMobile$.next(isMobile);
        this.isTablet$.next(!isMobile);
      });

    this.breakpointObserver
      .observe([TABLET_SCREEN_BREAKPOINT])
      .pipe(
        map((m) => m.matches),
        startWith(false),
        takeUntil(this.onDestroy$),
      )
      .subscribe((isTablet) => {
        if (isTablet) {
          this.isTablet$.next(isTablet);
          this.isMobile$.next(false);

          //test
          this.breakpointObserver
            .observe([MOBILE_SCREEN_BREAKPOINT])
            .pipe(
              map((m) => m.matches),
              startWith(false),
              takeUntil(this.onDestroy$),
            )
            .subscribe((isMobile) => {
              if (isMobile) {
                this.isMobile$.next(isMobile);
                this.isTablet$.next(false);
              }
            });
        } else {
          this.isTablet$.next(false);
          this.isMobile$.next(false);
        }
      });

    this.renderer = this.rendererFactory2.createRenderer(null, null);
    this.renderer.listen(window, 'resize', (evt) => {
      if (evt) {
        this.determineScreenSize(window.innerWidth);
      }
    });
  }

  isTablet(): boolean {
    return this.isTablet$.getValue();
  }

  isMobile(): boolean {
    return this.isMobile$.getValue();
  }

  getScreenSize(): number | undefined {
    return this.screenSizeDetailed$.getValue()?.size;
  }

  getScreenName(): string | undefined {
    return this.screenSizeDetailed$.getValue()?.name;
  }

  getScreenSizeDetailed(): ScreenSizeDetailed | undefined {
    return this.screenSizeDetailed$.getValue();
  }

  determineScreenSize(windowSize: number) {
    if (windowSize <= XS_BREAKPOINT_SIZE) this.screenSizeDetailed$.next({ name: 'xs', size: windowSize });
    else if (windowSize <= SM_BREAKPOINT_SIZE) this.screenSizeDetailed$.next({ name: 'sm', size: windowSize });
    else if (windowSize <= MD_BREAKPOINT_SIZE) this.screenSizeDetailed$.next({ name: 'md', size: windowSize });
    else if (windowSize <= LG_BREAKPOINT_SIZE) this.screenSizeDetailed$.next({ name: 'lg', size: windowSize });
    else if (windowSize >= XL_BREAKPOINT_SIZE) this.screenSizeDetailed$.next({ name: 'xl', size: windowSize });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
