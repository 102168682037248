import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'verde-dynamic-form-SidePanel',
  templateUrl: './verde-dynamic-form-side-panel.component.html',
  styleUrls: ['./verde-dynamic-form-side-panel.component.scss'],
})
export class VerdeDynamicFormComponentSidePanel implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<boolean>();

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
