import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
// Store
//Related
import * as fromProfileRelatedReducer from '../store/reducers/profile-related.reducer';
// Equipment Request Detail
import * as EmployeeEducationalQualificationRenewRenewalActions from '../store/actions/employee-educational-qualification-renewal.actions';
import * as fromEmployeeEducationalQualificationRenewReducer from '../store/reducers/employee-educational-qualification-renewal.reducer';
import { QualificationsGetEmployeeEducationalQualificationRenewalQuery } from '@verde/shared';

@Injectable({
  providedIn: 'root',
})
export class EmployeeEducationalQualificationRenewService {
  // EmployeeEducationalQualificationRenew
  EmployeeEducationalQualificationRenewLoading$: Observable<boolean>;
  EmployeeEducationalQualificationRenewObjects$: Observable<any[]>;

  constructor(private store: Store<fromProfileRelatedReducer.ProfileRelatedState>) {
    // EmployeeEducationalQualificationRenew
    this.EmployeeEducationalQualificationRenewLoading$ = this.store.pipe(
      select(fromEmployeeEducationalQualificationRenewReducer.isEmployeeEducationalQualificationRenewalLoading),
    );
    this.EmployeeEducationalQualificationRenewObjects$ = this.store.pipe(
      select(fromEmployeeEducationalQualificationRenewReducer.getEmployeeEducationalQualificationRenewal),
    );
  }

  // EmployeeEducationalQualificationRenew Detail
  // Get
  getEmployeeEducationalQualificationRenew(body: QualificationsGetEmployeeEducationalQualificationRenewalQuery) {
    this.store.dispatch(new EmployeeEducationalQualificationRenewRenewalActions.EmployeeEducationalQualificationRenewalGetAll(body));
  }

  // Clear
  EmployeeEducationalQualificationRenewClear() {
    this.store.dispatch(new EmployeeEducationalQualificationRenewRenewalActions.EmployeeEducationalQualificationRenewalClear(null));
  }

  // Upload
  uploadEmployeeEducationalQualificationRenew(payload: any) {
    this.store.dispatch(new EmployeeEducationalQualificationRenewRenewalActions.EmployeeEducationalQualificationRenewalsUpload(payload));
  }
}
