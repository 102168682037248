<div class="scrollable-content" style="height: calc(100% - 28px)">
  <ngx-spinner
    [disableAnimation]="disableAnimation"
    name="dynamicFormSpinner"
    bdColor="rgb(255, 255, 255)"
    size="default"
    color="#6264a7"
    type="ball-clip-rotate"
    [fullScreen]="false"
  >
    <p>Loading Data...</p>
  </ngx-spinner>

  <div class="k-form" *ngIf="fields">
    <form [formGroup]="form" (ngSubmit)="openSubmitModal()" *ngIf="fields">
      <formly-form [model]="model" [fields]="fields" [options]="options" [form]="form"></formly-form>
      <button
        kendoButton
        type="submit"
        id="submit-button"
        themeColor="primary"
        style="float: right; bottom: 14px; margin-top: 14px; right: 20px"
        [disabled]="!form?.valid ?? true"
      >
        Submit
      </button>
    </form>
  </div>
</div>

<verde-modal modalTitle="Confirmation" id="confirmModal" [hasCancelButton]="true" submitButton="Continue" (actionButton)="submit()" modalMaxWidth="400">
  <div style="position: relative; height: 60px">
    <p>{{ notificationMessage }}</p>
  </div>
</verde-modal>

<verde-modal modalTitle="Error" id="errorModal" [hasCancelButton]="false" submitButton="Continue" modalMaxWidth="400">
  <div style="position: relative; height: 60px">
    <p>{{ notificationMessage }}</p>
  </div>
</verde-modal>
