import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ProcessesProbation, ProcessesShiftType, ProcessesEmployeeType, UserAppConfigDto } from '@verde/shared';
import { Subject } from 'rxjs';

@Component({
  selector: 'verde-onboarding-required-info',
  templateUrl: './onboarding-required-info.component.html',
  styleUrls: ['./onboarding-required-info.component.scss'],
})
export class OnboardingRequiredInfoComponent implements OnInit {
  private onDestroy$ = new Subject<boolean>();

  @Input() employeeType: ProcessesProbation[] | null = null;
  @Input() probation: ProcessesShiftType[] | null = null;
  @Input() shiftType: ProcessesEmployeeType[] | null = null;
  @Input() config: UserAppConfigDto | null = null;
  @Input() public accountDetails: FormGroup | null = null;

  constructor() {}

  ngOnInit(): void {
    if (!this.accountDetails) {
      console.error('Account details FormGroup is null.');
    } else {
      let todayDate = new Date();
      this.accountDetails.get('startDate').setValue(todayDate);
    }
  }

  public ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
