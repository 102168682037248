<!-- #region Fleet License Modal -->
<verde-modal
  id="fleetLicenseModal"
  modalTitle="Add License"
  modalMinWidth="600"
  modalMaxWidth="600"
  submitButton="Submit"
  (actionButton)="postFleetLicense()"
  (modalCancelled)="hideModal()"
  [submitActive]="fleetLicenseForm.valid"
  submitConfirmationMessage="Please confirm that you would like to add this license"
  cancelConfirmationMessage="Please confirm that you would like to cancel"
  [hasSubmitConfirmation]="true"
  [hasCancelConfirmation]="fleetLicenseForm.touched && !fleetLicenseForm.pristine"
>
  <form #fleetLicenseForm="ngForm" novalidate autocomplete="off">
    <div class="form-group">
      <label for="licenseFile">Upload License:</label>
      <kendo-fileselect
        id="licenseFile"
        name="licenseFile"
        [ngModel]
        #varLicenseFile="ngModel"
        required
        [multiple]="false"
        (valueChange)="licenseFileSelected($event, varLicenseFile)"
      >
      </kendo-fileselect>

      <!--Validation-->
      <div class="alert alert-danger" *ngIf="varLicenseFile.touched && !varLicenseFile.valid">
        <div *ngIf="varLicenseFile.errors?.required">Field is required!</div>
      </div>
    </div>
  </form>
</verde-modal>
<!-- #endregion Assign Fleet Modal -->
<!-- #region Confirmation Modal -->
<verde-modal
  modalTitle="Confirmation"
  id="confirmFleetLicenseModal"
  modalMaxWidth="400"
  [hasCancelButton]="false"
  submitButton="Continue"
  (actionButton)="onConfirmClick()"
>
  <div style="position: relative; height: 60px">
    <ngx-spinner
      [disableAnimation]="disableAnimation"
      name="confirmfleetlicense"
      bdColor="rgb(237, 237, 237)"
      size="default"
      color="#6264a7"
      type="ball-clip-rotate"
      [fullScreen]="false"
    >
    </ngx-spinner>

    <p>{{ confirmationMessage }}</p>
  </div>
</verde-modal>
<!-- #endregion Confirmation Modal -->
