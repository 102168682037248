import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { RefreshService } from '../../../services/refresh-service';
import { ProcessesApiProcessesService, ProcessesPositionSeats, ProcessesValidateTaxNumberQuery } from '@verde/shared';
import { FileRestrictions } from '@progress/kendo-angular-upload';

@Component({
  selector: 'verde-passport-info',
  templateUrl: './onboarding-passport-info.component.html',
  styleUrls: ['./onboarding-passport-info.component.scss'],
})
export class OnboardingpassportInfoComponent implements OnInit {
  private onDestroy$ = new Subject<boolean>();
  @Input() public passportInfo: FormGroup | null = null;
  @Input() positionSeat: ProcessesPositionSeats | null = null;
  @Input() public personalDetails: FormGroup | null = null;

  public restrictions: FileRestrictions = {
    allowedExtensions: ['jpg', 'jpeg', 'png', 'pdf'],
  };

  constructor(private refreshService: RefreshService, private processesApiProcessesService: ProcessesApiProcessesService) {}

  ngOnInit(): void {
    if (!this.passportInfo) {
      console.error('passportInfo FormGroup is null.');
    } else {
      let todayDate = new Date();
      this.passportInfo.get('passportExpDate').setValue(todayDate);
    }

    if (this.personalDetails) {
      this.passportInfo?.get('passportCountry')?.setValue(this.personalDetails.value.primaryNationality.bthr_nationality);
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  onFileSelect(event: any): void {}

  onRadioChange(value) {}
}
