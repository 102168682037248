/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ReportingBaseService } from '../reporting-base-service';
import { ReportingApiConfiguration } from '../reporting-api-configuration';
import { ReportingStrictHttpResponse } from '../reporting-strict-http-response';
import { ReportingRequestBuilder } from '../reporting-request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ReportingClientReportSource } from '../models/reporting-client-report-source';
import { ReportingCreateDocumentArgs } from '../models/reporting-create-document-args';
import { ReportingEmpContactPositionManagerDto } from '../models/reporting-emp-contact-position-manager-dto';
import { ReportingEmpContactPositionManagerQuery } from '../models/reporting-emp-contact-position-manager-query';
import { ReportingSearchArgs } from '../models/reporting-search-args';
import { ReportingSendDocumentArgs } from '../models/reporting-send-document-args';

@Injectable({
  providedIn: 'root',
})
export class ReportingApiReportsService extends ReportingBaseService {
  constructor(config: ReportingApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation empContactPositionManager
   */
  static readonly EmpContactPositionManagerPath = '/api/Reports/verde/EmpContactPositionManager';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `empContactPositionManager$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  empContactPositionManager$Plain$Response(params?: {
    body?: ReportingEmpContactPositionManagerQuery;
  }): Observable<ReportingStrictHttpResponse<Array<ReportingEmpContactPositionManagerDto>>> {
    const rb = new ReportingRequestBuilder(this.rootUrl, ReportingApiReportsService.EmpContactPositionManagerPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as ReportingStrictHttpResponse<Array<ReportingEmpContactPositionManagerDto>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `empContactPositionManager$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  empContactPositionManager$Plain(params?: { body?: ReportingEmpContactPositionManagerQuery }): Observable<Array<ReportingEmpContactPositionManagerDto>> {
    return this.empContactPositionManager$Plain$Response(params).pipe(
      map((r: ReportingStrictHttpResponse<Array<ReportingEmpContactPositionManagerDto>>) => r.body as Array<ReportingEmpContactPositionManagerDto>),
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `empContactPositionManager()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  empContactPositionManager$Response(params?: {
    body?: ReportingEmpContactPositionManagerQuery;
  }): Observable<ReportingStrictHttpResponse<Array<ReportingEmpContactPositionManagerDto>>> {
    const rb = new ReportingRequestBuilder(this.rootUrl, ReportingApiReportsService.EmpContactPositionManagerPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as ReportingStrictHttpResponse<Array<ReportingEmpContactPositionManagerDto>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `empContactPositionManager$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  empContactPositionManager(params?: { body?: ReportingEmpContactPositionManagerQuery }): Observable<Array<ReportingEmpContactPositionManagerDto>> {
    return this.empContactPositionManager$Response(params).pipe(
      map((r: ReportingStrictHttpResponse<Array<ReportingEmpContactPositionManagerDto>>) => r.body as Array<ReportingEmpContactPositionManagerDto>),
    );
  }

  /**
   * Path part for operation apiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdActionsActionIdPut
   */
  static readonly ApiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdActionsActionIdPutPath =
    '/api/Reports/clients/{clientID}/instances/{instanceID}/documents/{documentID}/actions/{actionID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdActionsActionIdPut()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdActionsActionIdPut$Response(params: {
    clientID: string;
    instanceID: string;
    documentID: string;
    actionID: string;
  }): Observable<ReportingStrictHttpResponse<void>> {
    const rb = new ReportingRequestBuilder(
      this.rootUrl,
      ReportingApiReportsService.ApiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdActionsActionIdPutPath,
      'put',
    );
    if (params) {
      rb.path('clientID', params.clientID, {});
      rb.path('instanceID', params.instanceID, {});
      rb.path('documentID', params.documentID, {});
      rb.path('actionID', params.actionID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as ReportingStrictHttpResponse<void>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdActionsActionIdPut$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdActionsActionIdPut(params: {
    clientID: string;
    instanceID: string;
    documentID: string;
    actionID: string;
  }): Observable<void> {
    return this.apiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdActionsActionIdPut$Response(params).pipe(
      map((r: ReportingStrictHttpResponse<void>) => r.body as void),
    );
  }

  /**
   * Path part for operation apiReportsClientsPost
   */
  static readonly ApiReportsClientsPostPath = '/api/Reports/clients';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiReportsClientsPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsClientsPost$Response(params?: {}): Observable<ReportingStrictHttpResponse<void>> {
    const rb = new ReportingRequestBuilder(this.rootUrl, ReportingApiReportsService.ApiReportsClientsPostPath, 'post');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as ReportingStrictHttpResponse<void>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiReportsClientsPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsClientsPost(params?: {}): Observable<void> {
    return this.apiReportsClientsPost$Response(params).pipe(map((r: ReportingStrictHttpResponse<void>) => r.body as void));
  }

  /**
   * Path part for operation apiReportsClientsClientIdDelete
   */
  static readonly ApiReportsClientsClientIdDeletePath = '/api/Reports/clients/{clientID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiReportsClientsClientIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsClientsClientIdDelete$Response(params: { clientID: string }): Observable<ReportingStrictHttpResponse<void>> {
    const rb = new ReportingRequestBuilder(this.rootUrl, ReportingApiReportsService.ApiReportsClientsClientIdDeletePath, 'delete');
    if (params) {
      rb.path('clientID', params.clientID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as ReportingStrictHttpResponse<void>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiReportsClientsClientIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsClientsClientIdDelete(params: { clientID: string }): Observable<void> {
    return this.apiReportsClientsClientIdDelete$Response(params).pipe(map((r: ReportingStrictHttpResponse<void>) => r.body as void));
  }

  /**
   * Path part for operation apiReportsClientsKeepAliveClientIdPost
   */
  static readonly ApiReportsClientsKeepAliveClientIdPostPath = '/api/Reports/clients/keepAlive/{clientID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiReportsClientsKeepAliveClientIdPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsClientsKeepAliveClientIdPost$Response(params: { clientID: string }): Observable<ReportingStrictHttpResponse<void>> {
    const rb = new ReportingRequestBuilder(this.rootUrl, ReportingApiReportsService.ApiReportsClientsKeepAliveClientIdPostPath, 'post');
    if (params) {
      rb.path('clientID', params.clientID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as ReportingStrictHttpResponse<void>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiReportsClientsKeepAliveClientIdPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsClientsKeepAliveClientIdPost(params: { clientID: string }): Observable<void> {
    return this.apiReportsClientsKeepAliveClientIdPost$Response(params).pipe(map((r: ReportingStrictHttpResponse<void>) => r.body as void));
  }

  /**
   * Path part for operation apiReportsClientsSessionTimeoutGet
   */
  static readonly ApiReportsClientsSessionTimeoutGetPath = '/api/Reports/clients/sessionTimeout';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiReportsClientsSessionTimeoutGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsClientsSessionTimeoutGet$Response(params?: {}): Observable<ReportingStrictHttpResponse<void>> {
    const rb = new ReportingRequestBuilder(this.rootUrl, ReportingApiReportsService.ApiReportsClientsSessionTimeoutGetPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as ReportingStrictHttpResponse<void>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiReportsClientsSessionTimeoutGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsClientsSessionTimeoutGet(params?: {}): Observable<void> {
    return this.apiReportsClientsSessionTimeoutGet$Response(params).pipe(map((r: ReportingStrictHttpResponse<void>) => r.body as void));
  }

  /**
   * Path part for operation apiReportsClientsClientIdInstancesInstanceIdDocumentsPost
   */
  static readonly ApiReportsClientsClientIdInstancesInstanceIdDocumentsPostPath = '/api/Reports/clients/{clientID}/instances/{instanceID}/documents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiReportsClientsClientIdInstancesInstanceIdDocumentsPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiReportsClientsClientIdInstancesInstanceIdDocumentsPost$Response(params: {
    clientID: string;
    instanceID: string;
    body?: ReportingCreateDocumentArgs;
  }): Observable<ReportingStrictHttpResponse<void>> {
    const rb = new ReportingRequestBuilder(this.rootUrl, ReportingApiReportsService.ApiReportsClientsClientIdInstancesInstanceIdDocumentsPostPath, 'post');
    if (params) {
      rb.path('clientID', params.clientID, {});
      rb.path('instanceID', params.instanceID, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as ReportingStrictHttpResponse<void>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiReportsClientsClientIdInstancesInstanceIdDocumentsPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiReportsClientsClientIdInstancesInstanceIdDocumentsPost(params: {
    clientID: string;
    instanceID: string;
    body?: ReportingCreateDocumentArgs;
  }): Observable<void> {
    return this.apiReportsClientsClientIdInstancesInstanceIdDocumentsPost$Response(params).pipe(map((r: ReportingStrictHttpResponse<void>) => r.body as void));
  }

  /**
   * Path part for operation apiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdGet
   */
  static readonly ApiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdGetPath =
    '/api/Reports/clients/{clientID}/instances/{instanceID}/documents/{documentID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdGet$Response(params: {
    clientID: string;
    instanceID: string;
    documentID: string;
  }): Observable<ReportingStrictHttpResponse<void>> {
    const rb = new ReportingRequestBuilder(
      this.rootUrl,
      ReportingApiReportsService.ApiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdGetPath,
      'get',
    );
    if (params) {
      rb.path('clientID', params.clientID, {});
      rb.path('instanceID', params.instanceID, {});
      rb.path('documentID', params.documentID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as ReportingStrictHttpResponse<void>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdGet(params: { clientID: string; instanceID: string; documentID: string }): Observable<void> {
    return this.apiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdGet$Response(params).pipe(
      map((r: ReportingStrictHttpResponse<void>) => r.body as void),
    );
  }

  /**
   * Path part for operation apiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdDelete
   */
  static readonly ApiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdDeletePath =
    '/api/Reports/clients/{clientID}/instances/{instanceID}/documents/{documentID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdDelete$Response(params: {
    clientID: string;
    instanceID: string;
    documentID: string;
  }): Observable<ReportingStrictHttpResponse<void>> {
    const rb = new ReportingRequestBuilder(
      this.rootUrl,
      ReportingApiReportsService.ApiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdDeletePath,
      'delete',
    );
    if (params) {
      rb.path('clientID', params.clientID, {});
      rb.path('instanceID', params.instanceID, {});
      rb.path('documentID', params.documentID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as ReportingStrictHttpResponse<void>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdDelete(params: {
    clientID: string;
    instanceID: string;
    documentID: string;
  }): Observable<void> {
    return this.apiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdDelete$Response(params).pipe(
      map((r: ReportingStrictHttpResponse<void>) => r.body as void),
    );
  }

  /**
   * Path part for operation apiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdInfoGet
   */
  static readonly ApiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdInfoGetPath =
    '/api/Reports/clients/{clientID}/instances/{instanceID}/documents/{documentID}/info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdInfoGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdInfoGet$Response(params: {
    clientID: string;
    instanceID: string;
    documentID: string;
  }): Observable<ReportingStrictHttpResponse<void>> {
    const rb = new ReportingRequestBuilder(
      this.rootUrl,
      ReportingApiReportsService.ApiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdInfoGetPath,
      'get',
    );
    if (params) {
      rb.path('clientID', params.clientID, {});
      rb.path('instanceID', params.instanceID, {});
      rb.path('documentID', params.documentID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as ReportingStrictHttpResponse<void>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdInfoGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdInfoGet(params: {
    clientID: string;
    instanceID: string;
    documentID: string;
  }): Observable<void> {
    return this.apiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdInfoGet$Response(params).pipe(
      map((r: ReportingStrictHttpResponse<void>) => r.body as void),
    );
  }

  /**
   * Path part for operation apiReportsFormatsGet
   */
  static readonly ApiReportsFormatsGetPath = '/api/Reports/formats';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiReportsFormatsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsFormatsGet$Response(params?: {}): Observable<ReportingStrictHttpResponse<void>> {
    const rb = new ReportingRequestBuilder(this.rootUrl, ReportingApiReportsService.ApiReportsFormatsGetPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as ReportingStrictHttpResponse<void>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiReportsFormatsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsFormatsGet(params?: {}): Observable<void> {
    return this.apiReportsFormatsGet$Response(params).pipe(map((r: ReportingStrictHttpResponse<void>) => r.body as void));
  }

  /**
   * Path part for operation apiReportsClientsClientIdInstancesPost
   */
  static readonly ApiReportsClientsClientIdInstancesPostPath = '/api/Reports/clients/{clientID}/instances';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiReportsClientsClientIdInstancesPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiReportsClientsClientIdInstancesPost$Response(params: {
    clientID: string;
    body?: ReportingClientReportSource;
  }): Observable<ReportingStrictHttpResponse<void>> {
    const rb = new ReportingRequestBuilder(this.rootUrl, ReportingApiReportsService.ApiReportsClientsClientIdInstancesPostPath, 'post');
    if (params) {
      rb.path('clientID', params.clientID, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as ReportingStrictHttpResponse<void>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiReportsClientsClientIdInstancesPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiReportsClientsClientIdInstancesPost(params: { clientID: string; body?: ReportingClientReportSource }): Observable<void> {
    return this.apiReportsClientsClientIdInstancesPost$Response(params).pipe(map((r: ReportingStrictHttpResponse<void>) => r.body as void));
  }

  /**
   * Path part for operation apiReportsClientsClientIdInstancesInstanceIdDelete
   */
  static readonly ApiReportsClientsClientIdInstancesInstanceIdDeletePath = '/api/Reports/clients/{clientID}/instances/{instanceID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiReportsClientsClientIdInstancesInstanceIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsClientsClientIdInstancesInstanceIdDelete$Response(params: { clientID: string; instanceID: string }): Observable<ReportingStrictHttpResponse<void>> {
    const rb = new ReportingRequestBuilder(this.rootUrl, ReportingApiReportsService.ApiReportsClientsClientIdInstancesInstanceIdDeletePath, 'delete');
    if (params) {
      rb.path('clientID', params.clientID, {});
      rb.path('instanceID', params.instanceID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as ReportingStrictHttpResponse<void>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiReportsClientsClientIdInstancesInstanceIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsClientsClientIdInstancesInstanceIdDelete(params: { clientID: string; instanceID: string }): Observable<void> {
    return this.apiReportsClientsClientIdInstancesInstanceIdDelete$Response(params).pipe(map((r: ReportingStrictHttpResponse<void>) => r.body as void));
  }

  /**
   * Path part for operation apiReportsClientsClientIdPageSettingsPost
   */
  static readonly ApiReportsClientsClientIdPageSettingsPostPath = '/api/Reports/clients/{clientID}/pageSettings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiReportsClientsClientIdPageSettingsPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiReportsClientsClientIdPageSettingsPost$Response(params: {
    clientID: string;
    body?: ReportingClientReportSource;
  }): Observable<ReportingStrictHttpResponse<void>> {
    const rb = new ReportingRequestBuilder(this.rootUrl, ReportingApiReportsService.ApiReportsClientsClientIdPageSettingsPostPath, 'post');
    if (params) {
      rb.path('clientID', params.clientID, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as ReportingStrictHttpResponse<void>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiReportsClientsClientIdPageSettingsPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiReportsClientsClientIdPageSettingsPost(params: { clientID: string; body?: ReportingClientReportSource }): Observable<void> {
    return this.apiReportsClientsClientIdPageSettingsPost$Response(params).pipe(map((r: ReportingStrictHttpResponse<void>) => r.body as void));
  }

  /**
   * Path part for operation apiReportsClientsClientIdParametersPost
   */
  static readonly ApiReportsClientsClientIdParametersPostPath = '/api/Reports/clients/{clientID}/parameters';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiReportsClientsClientIdParametersPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiReportsClientsClientIdParametersPost$Response(params: {
    clientID: string;
    body?: ReportingClientReportSource;
  }): Observable<ReportingStrictHttpResponse<void>> {
    const rb = new ReportingRequestBuilder(this.rootUrl, ReportingApiReportsService.ApiReportsClientsClientIdParametersPostPath, 'post');
    if (params) {
      rb.path('clientID', params.clientID, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as ReportingStrictHttpResponse<void>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiReportsClientsClientIdParametersPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiReportsClientsClientIdParametersPost(params: { clientID: string; body?: ReportingClientReportSource }): Observable<void> {
    return this.apiReportsClientsClientIdParametersPost$Response(params).pipe(map((r: ReportingStrictHttpResponse<void>) => r.body as void));
  }

  /**
   * Path part for operation apiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdPagesPageNumberGet
   */
  static readonly ApiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdPagesPageNumberGetPath =
    '/api/Reports/clients/{clientID}/instances/{instanceID}/documents/{documentID}/pages/{pageNumber}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdPagesPageNumberGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdPagesPageNumberGet$Response(params: {
    clientID: string;
    instanceID: string;
    documentID: string;
    pageNumber: number;
  }): Observable<ReportingStrictHttpResponse<void>> {
    const rb = new ReportingRequestBuilder(
      this.rootUrl,
      ReportingApiReportsService.ApiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdPagesPageNumberGetPath,
      'get',
    );
    if (params) {
      rb.path('clientID', params.clientID, {});
      rb.path('instanceID', params.instanceID, {});
      rb.path('documentID', params.documentID, {});
      rb.path('pageNumber', params.pageNumber, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as ReportingStrictHttpResponse<void>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdPagesPageNumberGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdPagesPageNumberGet(params: {
    clientID: string;
    instanceID: string;
    documentID: string;
    pageNumber: number;
  }): Observable<void> {
    return this.apiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdPagesPageNumberGet$Response(params).pipe(
      map((r: ReportingStrictHttpResponse<void>) => r.body as void),
    );
  }

  /**
   * Path part for operation apiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdResourcesResourceIdGet
   */
  static readonly ApiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdResourcesResourceIdGetPath =
    '/api/Reports/clients/{clientID}/instances/{instanceID}/documents/{documentID}/resources/{resourceID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdResourcesResourceIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdResourcesResourceIdGet$Response(params: {
    clientID: string;
    instanceID: string;
    documentID: string;
    resourceID: string;
  }): Observable<ReportingStrictHttpResponse<void>> {
    const rb = new ReportingRequestBuilder(
      this.rootUrl,
      ReportingApiReportsService.ApiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdResourcesResourceIdGetPath,
      'get',
    );
    if (params) {
      rb.path('clientID', params.clientID, {});
      rb.path('instanceID', params.instanceID, {});
      rb.path('documentID', params.documentID, {});
      rb.path('resourceID', params.resourceID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as ReportingStrictHttpResponse<void>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdResourcesResourceIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdResourcesResourceIdGet(params: {
    clientID: string;
    instanceID: string;
    documentID: string;
    resourceID: string;
  }): Observable<void> {
    return this.apiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdResourcesResourceIdGet$Response(params).pipe(
      map((r: ReportingStrictHttpResponse<void>) => r.body as void),
    );
  }

  /**
   * Path part for operation apiReportsResourcesFolderResourceNameGet
   */
  static readonly ApiReportsResourcesFolderResourceNameGetPath = '/api/Reports/resources/{folder}/{resourceName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiReportsResourcesFolderResourceNameGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsResourcesFolderResourceNameGet$Response(params: { folder: string; resourceName: string }): Observable<ReportingStrictHttpResponse<void>> {
    const rb = new ReportingRequestBuilder(this.rootUrl, ReportingApiReportsService.ApiReportsResourcesFolderResourceNameGetPath, 'get');
    if (params) {
      rb.path('folder', params.folder, {});
      rb.path('resourceName', params.resourceName, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as ReportingStrictHttpResponse<void>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiReportsResourcesFolderResourceNameGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsResourcesFolderResourceNameGet(params: { folder: string; resourceName: string }): Observable<void> {
    return this.apiReportsResourcesFolderResourceNameGet$Response(params).pipe(map((r: ReportingStrictHttpResponse<void>) => r.body as void));
  }

  /**
   * Path part for operation apiReportsGet
   */
  static readonly ApiReportsGetPath = '/api/Reports';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiReportsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsGet$Response(params?: { folder?: string; resourceName?: string }): Observable<ReportingStrictHttpResponse<void>> {
    const rb = new ReportingRequestBuilder(this.rootUrl, ReportingApiReportsService.ApiReportsGetPath, 'get');
    if (params) {
      rb.query('folder', params.folder, {});
      rb.query('resourceName', params.resourceName, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as ReportingStrictHttpResponse<void>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiReportsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsGet(params?: { folder?: string; resourceName?: string }): Observable<void> {
    return this.apiReportsGet$Response(params).pipe(map((r: ReportingStrictHttpResponse<void>) => r.body as void));
  }

  /**
   * Path part for operation apiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdSearchPost
   */
  static readonly ApiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdSearchPostPath =
    '/api/Reports/clients/{clientID}/instances/{instanceID}/documents/{documentID}/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdSearchPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdSearchPost$Response(params: {
    clientID: string;
    instanceID: string;
    documentID: string;
    body?: ReportingSearchArgs;
  }): Observable<ReportingStrictHttpResponse<void>> {
    const rb = new ReportingRequestBuilder(
      this.rootUrl,
      ReportingApiReportsService.ApiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdSearchPostPath,
      'post',
    );
    if (params) {
      rb.path('clientID', params.clientID, {});
      rb.path('instanceID', params.instanceID, {});
      rb.path('documentID', params.documentID, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as ReportingStrictHttpResponse<void>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdSearchPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdSearchPost(params: {
    clientID: string;
    instanceID: string;
    documentID: string;
    body?: ReportingSearchArgs;
  }): Observable<void> {
    return this.apiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdSearchPost$Response(params).pipe(
      map((r: ReportingStrictHttpResponse<void>) => r.body as void),
    );
  }

  /**
   * Path part for operation apiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdSendPost
   */
  static readonly ApiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdSendPostPath =
    '/api/Reports/clients/{clientID}/instances/{instanceID}/documents/{documentID}/send';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdSendPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdSendPost$Response(params: {
    clientID: string;
    instanceID: string;
    documentID: string;
    body?: ReportingSendDocumentArgs;
  }): Observable<ReportingStrictHttpResponse<void>> {
    const rb = new ReportingRequestBuilder(
      this.rootUrl,
      ReportingApiReportsService.ApiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdSendPostPath,
      'post',
    );
    if (params) {
      rb.path('clientID', params.clientID, {});
      rb.path('instanceID', params.instanceID, {});
      rb.path('documentID', params.documentID, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as ReportingStrictHttpResponse<void>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdSendPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdSendPost(params: {
    clientID: string;
    instanceID: string;
    documentID: string;
    body?: ReportingSendDocumentArgs;
  }): Observable<void> {
    return this.apiReportsClientsClientIdInstancesInstanceIdDocumentsDocumentIdSendPost$Response(params).pipe(
      map((r: ReportingStrictHttpResponse<void>) => r.body as void),
    );
  }

  /**
   * Path part for operation apiReportsVersionGet
   */
  static readonly ApiReportsVersionGetPath = '/api/Reports/version';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiReportsVersionGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsVersionGet$Response(params?: {}): Observable<ReportingStrictHttpResponse<void>> {
    const rb = new ReportingRequestBuilder(this.rootUrl, ReportingApiReportsService.ApiReportsVersionGetPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as ReportingStrictHttpResponse<void>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiReportsVersionGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsVersionGet(params?: {}): Observable<void> {
    return this.apiReportsVersionGet$Response(params).pipe(map((r: ReportingStrictHttpResponse<void>) => r.body as void));
  }
}
