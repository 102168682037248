import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

import { WebDynamicFrontPageComponent } from './components/web-dynamic-form-frontPage/web-dynamic-frontPage.component';
import { WebDynamicFormComponent } from './components/web-dynamic-form/web-dynamic-form.component';

export const WEB_DYNAMIC_COMPONENT_EXPORT = [WebDynamicFormComponent, WebDynamicFrontPageComponent];

@NgModule({
  imports: [SharedModule],
  exports: [...WEB_DYNAMIC_COMPONENT_EXPORT],
  declarations: [...WEB_DYNAMIC_COMPONENT_EXPORT],
})
export class WebDynamicModule {}
