/* tslint:disable */
/* eslint-disable */
import { CommonLookupExtrapolate } from './common-lookup-extrapolate';
import { CommonBtApprovalStatus } from './common-bt-approval-status';
import { CommonBtProcessStatus } from './common-bt-process-status';
import { CommonBtVerdeStandardProcess } from './common-bt-verde-standard-process';
export interface CommonPositionSeatRequest {
  bt_approvaldate?: null | string;
  bt_approvalstatus?: CommonBtApprovalStatus;
  bt_department?: CommonLookupExtrapolate;
  bt_division?: CommonLookupExtrapolate;
  bt_effectivedate?: null | string;
  bt_legalentity?: CommonLookupExtrapolate;
  bt_position?: CommonLookupExtrapolate;
  bt_processstatus?: CommonBtProcessStatus;
  bt_seatno?: CommonLookupExtrapolate;
  bt_structurechangerequestno?: null | string;
  bt_verdestandardprocess?: CommonBtVerdeStandardProcess;
  bt_verdestructurechangerequestid?: null | string;
}
