import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageService } from '@verde/core';
import { Observable } from 'rxjs';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(private storageService: StorageService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const httpParams = request.params
      .set('APIURL', this.storageService.sessionGetKey('APIURL') || '')
      .set('subscriptionKey', this.storageService.sessionGetKey('subKey') || '');
    const dupReq = request.clone({
      params: httpParams,
      setHeaders: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
      },
    });
    return next.handle(dupReq);
  }
}
