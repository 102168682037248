<kendo-grid
  #myGrid
  [rowClass]="rowCallback"
  [kendoGridBinding]="UserAccessGridData"
  [resizable]="true"
  [scrollable]="true"
  [groupable]="true"
  [group]="UserAccessGroups"
  (groupChange)="UserAccessGroupChange($event)"
  (dataStateChange)="UserAccessDataStateChange($event)"
  [selectable]="selectableSettings"
  [kendoGridSelectBy]="UserAccessSelectedCallback"
  [selectedKeys]="UserAccessSelection"
  [sortable]="true"
  [sort]="UserAccessSort"
  (sortChange)="UserAccessSortChange($event)"
>
  <!--Columns-->
  <ng-template kendoGridToolbarTemplate style="height: 57px">
    <input disabled placeholder="Search all columns" kendoTextBox style="text-transform: none !important" (input)="onUserAccessFilter($event.target.value)" />
    <kendo-grid-spacer></kendo-grid-spacer>
    <button style="height: 36px; margin-left: auto" kendoGridExcelCommand icon="file-excel">Export to Excel</button>
  </ng-template>
  <kendo-grid-column field="bt_Employee.bt_fullname" title="Full Name" [width]="220"> </kendo-grid-column>
  <kendo-grid-column field="bt_Employee.odataLegalEntity" title="Legal Entity" [width]="220"> </kendo-grid-column>
  <kendo-grid-column field="bt_Employee.odataDepartment" title="Department" [width]="220"> </kendo-grid-column>
  <kendo-grid-column field="bt_Employee.odataDivision" title="Divison" [width]="220"> </kendo-grid-column>
  <kendo-grid-excel fileName="UserAccess.xlsx"></kendo-grid-excel>
</kendo-grid>
