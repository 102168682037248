/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { CommonBaseService } from '../common-base-service';
import { ApiConfiguration } from '../api-configuration';
import { CommonStrictHttpResponse } from '../common-strict-http-response';
import { CommonRequestBuilder } from '../common-request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CommonGetDynamicFormDataCommand } from '../models/common-get-dynamic-form-data-command';
import { CommonGetDynamicFormLookupCommand } from '../models/common-get-dynamic-form-lookup-command';
import { CommonGetPositionSeatRequestQuery } from '../models/common-get-position-seat-request-query';
import { CommonPositionSeatRequest } from '../models/common-position-seat-request';
import { CommonSubmitDynamicFormDataCommand } from '../models/common-submit-dynamic-form-data-command';

@Injectable({
  providedIn: 'root',
})
export class CommonApiWebDynamicService extends CommonBaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation directoriesNamesOptions
   */
  static readonly DirectoriesNamesOptionsPath = '/api/WebDynamic/get/directoriesNamesOptions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `directoriesNamesOptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  directoriesNamesOptions$Response(params?: { tenantId?: string }): Observable<CommonStrictHttpResponse<string>> {
    const rb = new CommonRequestBuilder(this.rootUrl, CommonApiWebDynamicService.DirectoriesNamesOptionsPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as CommonStrictHttpResponse<string>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `directoriesNamesOptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  directoriesNamesOptions(params?: { tenantId?: string }): Observable<string> {
    return this.directoriesNamesOptions$Response(params).pipe(map((r: CommonStrictHttpResponse<string>) => r.body as string));
  }

  /**
   * Path part for operation dynamicFormData
   */
  static readonly DynamicFormDataPath = '/api/WebDynamic/get/dynamicFormData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dynamicFormData()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  dynamicFormData$Response(params?: { tenantId?: string; body?: CommonGetDynamicFormDataCommand }): Observable<CommonStrictHttpResponse<string>> {
    const rb = new CommonRequestBuilder(this.rootUrl, CommonApiWebDynamicService.DynamicFormDataPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as CommonStrictHttpResponse<string>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `dynamicFormData$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  dynamicFormData(params?: { tenantId?: string; body?: CommonGetDynamicFormDataCommand }): Observable<string> {
    return this.dynamicFormData$Response(params).pipe(map((r: CommonStrictHttpResponse<string>) => r.body as string));
  }

  /**
   * Path part for operation dynamicFormLookupData
   */
  static readonly DynamicFormLookupDataPath = '/api/WebDynamic/get/dynamicFormLookupData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dynamicFormLookupData()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  dynamicFormLookupData$Response(params?: { body?: CommonGetDynamicFormLookupCommand }): Observable<
    CommonStrictHttpResponse<
      Array<{
        [key: string]: any;
      }>
    >
  > {
    const rb = new CommonRequestBuilder(this.rootUrl, CommonApiWebDynamicService.DynamicFormLookupDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as CommonStrictHttpResponse<
            Array<{
              [key: string]: any;
            }>
          >;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `dynamicFormLookupData$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  dynamicFormLookupData(params?: { body?: CommonGetDynamicFormLookupCommand }): Observable<
    Array<{
      [key: string]: any;
    }>
  > {
    return this.dynamicFormLookupData$Response(params).pipe(
      map(
        (
          r: CommonStrictHttpResponse<
            Array<{
              [key: string]: any;
            }>
          >,
        ) =>
          r.body as Array<{
            [key: string]: any;
          }>,
      ),
    );
  }

  /**
   * Path part for operation submitDynamicForm
   */
  static readonly SubmitDynamicFormPath = '/api/WebDynamic/get/submitDynamicForm';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `submitDynamicForm()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  submitDynamicForm$Response(params?: { tenantId?: string; body?: CommonSubmitDynamicFormDataCommand }): Observable<CommonStrictHttpResponse<string>> {
    const rb = new CommonRequestBuilder(this.rootUrl, CommonApiWebDynamicService.SubmitDynamicFormPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as CommonStrictHttpResponse<string>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `submitDynamicForm$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  submitDynamicForm(params?: { tenantId?: string; body?: CommonSubmitDynamicFormDataCommand }): Observable<string> {
    return this.submitDynamicForm$Response(params).pipe(map((r: CommonStrictHttpResponse<string>) => r.body as string));
  }

  /**
   * Path part for operation uploadDynamicFormFile
   */
  static readonly UploadDynamicFormFilePath = '/api/WebDynamic/get/uploadDynamicFormFile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadDynamicFormFile()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadDynamicFormFile$Response(params?: {
    tenantId?: string;
    body?: {
      FormRegisterId?: string;
      LegalEntityId?: string;
      EntityName?: string;
      AttributeID?: string;
      AttributeName?: string;
      Files?: Array<Blob>;
      EncryptionTypes?: Array<string>;
      Overwrite?: boolean;
      ImportByEntityId?: string;
      ImportedById?: string;
    };
  }): Observable<CommonStrictHttpResponse<string>> {
    const rb = new CommonRequestBuilder(this.rootUrl, CommonApiWebDynamicService.UploadDynamicFormFilePath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as CommonStrictHttpResponse<string>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadDynamicFormFile$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadDynamicFormFile(params?: {
    tenantId?: string;
    body?: {
      FormRegisterId?: string;
      LegalEntityId?: string;
      EntityName?: string;
      AttributeID?: string;
      AttributeName?: string;
      Files?: Array<Blob>;
      EncryptionTypes?: Array<string>;
      Overwrite?: boolean;
      ImportByEntityId?: string;
      ImportedById?: string;
    };
  }): Observable<string> {
    return this.uploadDynamicFormFile$Response(params).pipe(map((r: CommonStrictHttpResponse<string>) => r.body as string));
  }

  /**
   * Path part for operation getPositionSeatRequests
   */
  static readonly GetPositionSeatRequestsPath = '/api/WebDynamic/Get_Position_Seat_Requests';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPositionSeatRequests()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getPositionSeatRequests$Response(params?: {
    tenantId?: string;
    body?: CommonGetPositionSeatRequestQuery;
  }): Observable<CommonStrictHttpResponse<Array<CommonPositionSeatRequest>>> {
    const rb = new CommonRequestBuilder(this.rootUrl, CommonApiWebDynamicService.GetPositionSeatRequestsPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as CommonStrictHttpResponse<Array<CommonPositionSeatRequest>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPositionSeatRequests$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getPositionSeatRequests(params?: { tenantId?: string; body?: CommonGetPositionSeatRequestQuery }): Observable<Array<CommonPositionSeatRequest>> {
    return this.getPositionSeatRequests$Response(params).pipe(
      map((r: CommonStrictHttpResponse<Array<CommonPositionSeatRequest>>) => r.body as Array<CommonPositionSeatRequest>),
    );
  }
}
