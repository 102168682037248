<ng-container [formGroup]="accountDetails">
  <kendo-formfield>
    <kendo-label [for]="startDate" text="Start Date"></kendo-label>
    <kendo-datepicker #startDate required formControlName="startDate" [format]="'yyyy-MM-dd'"> </kendo-datepicker>
    <kendo-formerror>Start Date is required</kendo-formerror>
  </kendo-formfield>

  <kendo-formfield>
    <kendo-label [for]="firstName" text="First Name"></kendo-label>
    <kendo-textbox formControlName="firstName" #firstName required></kendo-textbox>
    <kendo-formerror>First Name is required</kendo-formerror>
  </kendo-formfield>

  <kendo-formfield>
    <kendo-label [for]="lastName" text="Last Name"> </kendo-label>
    <kendo-textbox formControlName="lastName" #lastName required> </kendo-textbox>
    <kendo-formerror>Last Name is required</kendo-formerror>
  </kendo-formfield>

  <kendo-formfield>
    <kendo-label [for]="middleNames" text="Middle Name(s)"> </kendo-label>
    <kendo-textbox formControlName="middleNames" #middleNames> </kendo-textbox>
  </kendo-formfield>

  <kendo-formfield>
    <kendo-label [for]="preferredName" text="Preferred Name"> </kendo-label>
    <kendo-textbox formControlName="preferredName" #preferredName> </kendo-textbox>
  </kendo-formfield>

  <kendo-formfield *ngIf="config.legalEntityConfig.isEmployeeAutoNumbering == false">
    <kendo-label [for]="empNumber" text="Employee Number"> </kendo-label>
    <kendo-textbox formControlName="empNumber" #empNumber> </kendo-textbox>
  </kendo-formfield>

  <kendo-formfield>
    <kendo-label [for]="employeeTypes" text="Employee Type"></kendo-label>
    <kendo-dropdownlist
      #employeeTypes
      formControlName="employeeTypes"
      [data]="employeeType"
      textField="bthr_employeetype"
      valueField="bthr_employeetypeid"
      id="employeeTypesDropdown"
      name="employeeTypesDropdown"
      required
      [ngModel]
      [disabled]=""
    >
      <ng-template kendoDropDownListNoDataTemplate>
        <p></p>
      </ng-template>
    </kendo-dropdownlist>
    <kendo-formerror>Employee Type is required</kendo-formerror>
  </kendo-formfield>

  <kendo-formfield>
    <kendo-label [for]="probationType" text="Probation"></kendo-label>
    <kendo-dropdownlist
      #probationType
      formControlName="probationType"
      [data]="probation"
      textField="bt_probationname"
      valueField="bt_probationid"
      id="probationTypeDropdown"
      name="probationTypeDropdown"
      required
      [ngModel]
      [disabled]=""
    >
      <ng-template kendoDropDownListNoDataTemplate>
        <p></p>
      </ng-template>
    </kendo-dropdownlist>
    <kendo-formerror>Probation is required</kendo-formerror>
  </kendo-formfield>

  <kendo-formfield>
    <kendo-label [for]="shiftTypes" text="Shift Type"></kendo-label>
    <kendo-dropdownlist
      #shiftTypes
      formControlName="shiftTypes"
      [data]="shiftType"
      textField="bt_shifttype"
      valueField="bt_shifttypeid"
      id="shiftTypesDropdown"
      name="shiftTypesDropdown"
      required
      [ngModel]
      [disabled]=""
    >
      <ng-template kendoDropDownListNoDataTemplate>
        <p></p>
      </ng-template>
    </kendo-dropdownlist>
    <kendo-formerror>Shift Type is required</kendo-formerror>
  </kendo-formfield>
</ng-container>
