<div class="scrollable-content"
     style="height: calc(100% - 80px)">
  <form class="k-form"
        [formGroup]="fg"
        autocomplete="off">
    <kendo-gridlayout gap="12px 31px"
                      [rows]="[]"
                      [cols]="[{ width: '1fr' }, { width: '1fr' }]">
      <kendo-gridlayout-item *ngIf="currentStep === 1"
                             [row]="1"
                             [col]="1"
                             [colSpan]="2">
        <kendo-formfield>
          <kendo-label [for]="graphApiDropdown1"
                       text="Method:"></kendo-label>
          <kendo-dropdownlist [data]="graphApiDropdown1data"
                              textField="graphApiDropdown1Value"
                              valueField="graphApiDropdown1ID"
                              [ngModel]
                              formControlName="graphApiDropdown1"
                              (valueChange)="handleGraphApiDropdown1Filter($event)"
                              #graphApiDropdown1
                              id="graphApiDropdown1">
            <ng-template kendoDropDownListNoDataTemplate>
              <!--<p>{{ graphApiDropdown1Message }}</p>-->
            </ng-template>
          </kendo-dropdownlist>
        </kendo-formfield>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item *ngIf="currentStep === 1"
                             [row]="2"
                             [col]="1"
                             [colSpan]="2">
        <kendo-formfield>
          <kendo-label [for]="exampleGraphApi1"
                       text="Scope"></kendo-label>
          <kendo-textbox formControlName="exampleGraphApi1"
                         #exampleGraphApi1> </kendo-textbox>
        </kendo-formfield>
      </kendo-gridlayout-item>
      <!------------------------>
      <kendo-gridlayout-item *ngIf="currentStep === 1"
                             [row]="3"
                             [col]="1"
                             [colSpan]="2">
        <kendo-formfield>
          <kendo-label [for]="graphUrl"
                       text="URL"></kendo-label>
          <kendo-textbox formControlName="graphUrl"
                         #graphUrl> </kendo-textbox>
        </kendo-formfield>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item *ngIf="currentStep === 1"
                             [row]="4"
                             [col]="1"
                             [colSpan]="2"><kendo-formfield>
          <kendo-label [for]="selectParams"
                       text="Fields to return, comma separated [Optional]"
                       [ngStyle]="{ 'font-size': '6px !important', color: 'gray !important' }"></kendo-label>
          <kendo-textbox formControlName="selectParams"
                         #selectParams> </kendo-textbox>
        </kendo-formfield>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item *ngIf="currentStep === 1"
                             [row]="5"
                             [col]="1"
                             [colSpan]="2">
        <kendo-formfield>
          <kendo-label [for]="filerParams"
                       text="Fields to use to filter, comma separated followed by eq and the value (email eq 'test@test.com') [Optional]"
                       [ngStyle]="{ 'font-size': '6px !important', color: 'gray !important' }"></kendo-label>
          <kendo-textbox formControlName="filerParams"
                         #filerParams> </kendo-textbox>
        </kendo-formfield>
      </kendo-gridlayout-item>
      <!------------------------>
      <kendo-gridlayout-item *ngIf="currentStep === 1"
                             [row]="6"
                             [col]="1"
                             [colSpan]="2">
        <kendo-formfield>
          <kendo-label [for]="exampleGraphApi6"
                       text="Body"></kendo-label>
          <kendo-textarea formControlName="exampleGraphApi6"
                          #exampleGraphApi6> </kendo-textarea>
        </kendo-formfield>
      </kendo-gridlayout-item>
      <!------------------------>
      <kendo-gridlayout-item *ngIf="currentStep === 1"
                             [row]="7"
                             [col]="1"
                             [colSpan]="2">
        <kendo-formfield>
          <kendo-label [for]="graphApiOutput"
                       text="Output"></kendo-label>
          <kendo-textarea [rows]="15"
                          placeholder=""
                          style="width: 100%; display: flex; align-items: flex-start; overflow-y: auto"
                          [readonly]="true"
                          [attr.disabled]="true"
                          formControlName="graphApiOutput"
                          #graphApiOutput>
          </kendo-textarea>
        </kendo-formfield>
      </kendo-gridlayout-item>

      <!------------------------>
      <!--<div class="example">
        <div style="margin-bottom:10px">Person card Hover</div>
        <mgt-person person-query="me" view="twolines" person-card="hover"></mgt-person>
      </div>
      <div class="example">
        <div style="margin-bottom:10px">Person card Click</div>
        <mgt-person person-query="me" view="twolines" person-card="click"></mgt-person>
      </div>-->
      <!------------------------>
    </kendo-gridlayout>
  </form>
</div>

<div style="position: sticky; bottom: 0px">
  <hr style="" />
  <button kendoButton
          themeColor="primary"
          (click)="submit()"
          style="float: right; bottom: 14px; margin-top: 14px; right: 20px"
          *ngIf="currentStep === totalSteps">
    Submit
  </button>
</div>