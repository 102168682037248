/* tslint:disable */
/* eslint-disable */
import { CommonRequestDataCommand } from './common-request-data-command';
export interface CommonSubmitDynamicFormDataCommand {
  entityId?: null | string;
  entityName?: null | string;
  formMode?: null | string;
  formType?: null | string;
  requestData?: CommonRequestDataCommand;
  version?: null | string;
}
