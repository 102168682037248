import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType, FieldTypeConfig, FormlyFieldProps } from '@ngx-formly/core';

export interface KendoFormlyRadioProps extends FormlyFieldProps {}

@Component({
  selector: 'verde-kendo-formly-radio',
  template: `
    <ng-container *ngFor="let option of props.options | formlySelectOptions : field | async; let i = index">
      <input
        type="radio"
        #radioInput
        kendoRadioButton
        [id]="id + '_' + i"
        [name]="field.name || id"
        [value]="option.value"
        [formControl]="option.disabled ? disabledControl : formControl"
        [formlyAttributes]="field"
        [readonly]="props.readonly"
        [required]="props.required"
        [tabIndex]="props.tabindex || 0"
      />
      <label class="k-radio-label" [for]="id + '_' + i">
        {{ option.label }}
      </label>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./radio.type.scss'],
})
export class KendoFormlyRadio extends FieldType<FieldTypeConfig<KendoFormlyRadioProps>> {
  get disabledControl() {
    return new FormControl({ value: this.formControl.value, disabled: true });
  }
}
