export abstract class CommonHelpers {
  /**
   * Converts pixels to centimeters.
   *
   * @param pixels - The number of pixels to convert.
   * @returns The equivalent value in centimeters.
   */
  public static pixelsToCentimeter(pixels: number) {
    var n = 0;
    var cpi = 2.54; // centimeters per inch
    var dpi = 96; // dots per inch
    var ppd = window.devicePixelRatio; // pixels per dot
    return ((pixels * cpi) / (dpi * ppd)).toFixed(n);
  }

  /**
   * Generates a random integer between 1 and the specified maximum integer.
   *
   * @param maxInteger - The maximum integer value.
   * @returns A random integer between 1 and the maximum integer.
   */
  public static getRandomInteger(maxInteger: number) {
    return Math.floor(Math.random() * (maxInteger - 1 + 1)) + 1; // The maximum is inclusive and the minimum is inclusive
  }

  /**
   * Returns the initials of a given full name.
   *
   * @param fullName - The full name to extract initials from.
   * @returns The initials of the full name.
   */
  public static getInitials(fullName: string) {
    var names = fullName.split(' '),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  }
}
