/* tslint:disable */
/* eslint-disable */
export enum UserBtLinkType {
  Internal = 'Internal',
  External = 'External',
  DynamicForm = 'DynamicForm',
  DefaultModelDriven = 'DefaultModelDriven',
  Handler = 'Handler',
  Title = 'Title',
}
