import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { EditorResizableOptions } from '@progress/kendo-angular-editor';
import { UserService } from '@verde/core';
import {
  ModalService,
  ProcessesApiProcessesService,
  ProcessesBthrEmployeeResignationsState,
  ProcessesBthrEmployeeResignationsStatusCode,
  ProcessesEmployeeResignation,
  UserAppConfigDto,
  UserBtResignationNotice,
  VerdeApprovalService,
} from '@verde/shared';
import { saveAs } from 'file-saver';
import { Subject, take, takeUntil } from 'rxjs';
import { TerminationService } from '../../../../../../../../../apps/verde/src/app/features/profile2/services/termination.service';

@Component({
  selector: 'verde-employee-resignation-view',
  templateUrl: './employee-resignation-view.component.html',
  styleUrls: ['./employee-resignation-view.component.scss'],
})
export class EmployeeResignationViewComponent implements OnInit {
  private onDestroy$ = new Subject<boolean>();

  // Config
  config: UserAppConfigDto;

  public registerForm: FormGroup = new FormGroup({
    noticeDate: new FormControl(),
    exitReason: new FormControl(),
    exitDate: new FormControl(),
    comments: new FormControl(),
    fileUpload: new FormControl(),
  });

  editorId: string = 'commentEditor';
  /*terminationReasons = ProcessesBthrTerminationReason;*/
  public resignationTypes: any[] = [];
  uploadedFiles: File[] = [];
  joinDate: Date;
  gridColWidth = '45%';
  editorValue: string;
  todayDate: Date = new Date();
  resignationNotice: string;
  exitDate: Date = new Date();
  noticeDate: Date = new Date();
  confirmMessage: string;
  resignationEdit: any;
  listofFiles: any[];
  statuscode: ProcessesBthrEmployeeResignationsStatusCode;

  constructor(
    private userService: UserService,
    private processesApiProcessesService: ProcessesApiProcessesService,
    private sidebarService: VerdeApprovalService,
    private modalService: ModalService,
    private terminationService: TerminationService,
  ) {}

  ngOnInit(): void {
    this.userService.config$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      this.config = data;
      if (this.config?.legalEntityConfig?.resignationNotice === UserBtResignationNotice.NoticeonScreen) {
        this.resignationNotice = 'NoticeOnScreen';
      } else if (this.config?.legalEntityConfig?.resignationNotice === UserBtResignationNotice.AttachNotice) {
        this.resignationNotice = 'AttachNotice';
      } else if (this.config?.legalEntityConfig?.resignationNotice === UserBtResignationNotice.Both) {
        this.resignationNotice = 'Both';
      }
    });

    this.sidebarService
      .getEmployeeResignationData()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data) => {
        this.resignationEdit = data[0];
      });

    this.exitDate = new Date(this.resignationEdit.bthr_resignationdate);
    this.noticeDate = new Date(this.resignationEdit.bthr_terminationnoticedate);

    this.registerForm = new FormGroup({
      exitDate: new FormControl(this.exitDate),
      noticeDate: new FormControl(this.noticeDate),
      editor: new FormControl(this.resignationEdit.bthr_terminationcomment),
      fileUpload: new FormControl(),
      resignationType: new FormControl(this.resignationEdit.bthr_terminationreason),
    });

    this.listofFiles = [];
    if (this.resignationEdit.annotations.length > 0) {
      this.resignationEdit.annotations.forEach((e) => {
        this.listofFiles.push({
          name: e.filename,
        });
      });
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  public resizable: boolean | EditorResizableOptions = {
    minHeight: 150,
    maxHeight: 450,
  };

  public onChange(event: Event): void {
    const isChecked = (event.target as HTMLInputElement).checked;
    this.resizable = isChecked
      ? {
          minHeight: 150,
          maxHeight: 450,
        }
      : false;
  }

  editorValueChange(event: any) {
    this.editorValue = event;
  }

  onFileSelect(event: any) {
    console.log(event);
    const selectedFiles: FileList = event;
    for (let i = 0; i < selectedFiles.length; i++) {
      this.uploadedFiles.push(selectedFiles[i]);
    }
    console.log('Selected files:', this.uploadedFiles);
  }

  submit() {}

  reversal() {
    this.modalService.open('reversalModal');
    this.confirmMessage = 'Please confirm that you would like to cancel your termination notice';
  }

  confirmReversal() {
    let body: ProcessesEmployeeResignation = {
      bt_statecode: ProcessesBthrEmployeeResignationsState.Inactive,
      bt_employeeresignationsid: this.resignationEdit.bt_employeeresignationsid,
    };
    this.processesApiProcessesService
      .patchEmployeeResignationCancelation({ body: { body: body } })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          console.log(ret);
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.terminationService.notifySidePanelClosed();
          this.sidebarService.setShowSidebar(false);
        },
      );
  }

  //convert to blob
  downloadPdf(base64String: string, fileName: string) {
    const binaryData = atob(base64String);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }

    const blob = new Blob([uint8Array], { type: 'application/pdf' });

    saveAs(blob, fileName);
  }
}
