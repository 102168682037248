/* tslint:disable */
/* eslint-disable */
import { ReportingBtEmployeeStatusEnum } from './reporting-bt-employee-status-enum';
export interface ReportingEmpContactPositionManagerDto {
  bt_employeestatus?: ReportingBtEmployeeStatusEnum;
  bt_genderlookupID?: string;
  bt_genderlookupName?: null | string;
  bt_idorpassportno?: null | string;
  bt_managerfullname?: null | string;
  bt_neworganizationjoindate?: null | string;
  bt_positiondescription?: null | string;
  bt_positionfrompositionseatName?: null | string;
  bt_provincestateID?: string;
  bt_provincestateName?: null | string;
  bt_racelookupID?: string;
  bt_racelookupName?: null | string;
  bt_reportingmanagerID?: string;
  bt_reportingmanagerName?: null | string;
  bt_reportingmanentityID?: string;
  bt_reportingmanentityName?: null | string;
  bt_seattitletext?: null | string;
  bthr_departmentID?: string;
  bthr_departmentName?: null | string;
  bthr_divisionID?: string;
  bthr_divisionName?: null | string;
  bthr_email?: null | string;
  bthr_employeeid?: null | string;
  bthr_employeenumber?: null | string;
  bthr_employeephoto_url?: null | string;
  bthr_firstname?: null | string;
  bthr_lastname?: null | string;
  bthr_legalentityID?: string;
  bthr_legalentityName?: null | string;
  bthr_mobiletelephone?: null | string;
  bthr_preferredname?: null | string;
  bthr_primarylocationID?: string;
  bthr_primarylocationName?: null | string;
}
