<div class="k-loading-panel" *ngIf="loadingPanelVisible">
  <div class="k-loading-panel-mask"></div>
  <div class="k-loading-panel-wrapper">
    <kendo-loader type="infinite-spinner" themeColor="primary" size="large"></kendo-loader>
    <div class="k-loading-panel-text">Loading...</div>
  </div>
</div>

<div class="main">
  <kendo-stepper #stepper [steps]="steps" stepType="full" [(currentStep)]="currentStep" class="stepper-width"> </kendo-stepper>
  <div class="scrollable-content">
    <form class="k-form" [formGroup]="form">
      <verde-onboarding-structure [positionSeat]="positionSeat" *ngIf="currentStep === 0" [structure]="currentGroup"> </verde-onboarding-structure>
      <verde-onboarding-required-info
        [probation]="probation"
        [config]="config"
        [shiftType]="shiftType"
        [employeeType]="employeeType"
        *ngIf="currentStep === 1"
        [accountDetails]="currentGroup"
      >
      </verde-onboarding-required-info>
      <verde-onboarding-nationality
        [validateYesNo]="validateYesNo"
        [testCountry]="testCountry"
        [nationalities]="nationalities"
        [countries]="countries"
        [localResidencyStatus]="localResidencyStatus"
        [citizenStatus]="citizenStatus"
        [positionSeat]="positionSeat"
        *ngIf="currentStep === 2"
        [personalDetails]="currentGroup"
        (customEvent)="handleChildEvent($event)"
      >
      </verde-onboarding-nationality>

      <verde-onboarding-tax-info
        [taxValidation]="taxValidation"
        (validateTaxNumberEvent)="onValidateTaxNumber()"
        *ngIf="currentStep === taxStep"
        [paymentDetails]="paymentDetails"
      >
      </verde-onboarding-tax-info>

      <verde-passport-info *ngIf="currentStep === passportStep" [passportInfo]="currentGroup" [positionSeat]="positionSeat" [personalDetails]="personalDetails">
      </verde-passport-info>
    </form>
  </div>

  <div #appendTo class="fixed-buttons">
    <span class="k-form-separator"></span>
    <div class="k-form-buttons k-buttons-end">
      <span class="page">Step {{ currentStep + 1 }} of {{ totalSteps }}</span>
      <div>
        <button kendoButton themeColor="primary" *ngIf="currentStep !== 0" (click)="prev()">Previous</button>
        <button kendoButton themeColor="primary" (click)="next()" *ngIf="currentStep < count">Next</button>
        <button kendoButton themeColor="primary" (click)="submit()" *ngIf="currentStep === count" [disabled]="!form.valid">Submit</button>
      </div>
    </div>
  </div>
</div>

<verde-modal
  modalTitle="Confirmation"
  id="confirmModal"
  [hasCancelButton]="true"
  submitButton="Continue"
  (actionButton)="confirmOnboarding()"
  modalMaxWidth="400"
>
  <div style="position: relative; height: 60px">
    <p>{{ confirmMessage }}</p>
  </div>
</verde-modal>
