import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class WebDynamicJSService {
  private scripts: { [key: string]: { loaded: boolean; src: string } } = {};

  constructor() {}

  loadScript(name: string, src: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.checkFileExists(src)) {
        // Check if the script is already registered
        if (!this.scripts[name]) {
          this.scripts[name] = { loaded: false, src: src };
        }

        // If script is already loaded, resolve the promise
        if (this.scripts[name].loaded) {
          resolve({ script: name, loaded: true, status: 'Already Loaded' });
        } else {
          // Create a new script element
          let script = document.createElement('script');
          script.type = 'text/javascript';
          script.src = this.scripts[name].src;

          // Set up onload and onerror callbacks
          script.onload = () => {
            this.scripts[name].loaded = true;
            resolve({ script: name, loaded: true, status: 'Loaded' });
          };
          script.onerror = (error: any) => {
            reject({ script: name, loaded: false, status: 'Failed to Load' });
          };

          // Append the script to the head or body
          document.getElementsByTagName('formly-form')[0]?.appendChild(script);
        }
      } else {
        reject({ script: name, loaded: false, status: 'No file found' });
      }
    });
  }

  removeScript(name: string): void {
    //Remove the script so that it can be loaded again
    if (this.scripts[name]) {
      this.scripts = {};

      const script = document.querySelector('script[src="assets/js/dynamic-forms/' + name + '.js"]');
      if (script) {
        script.parentNode?.removeChild(script);
      }
    }
  }

  checkFileExists(src: string): Promise<boolean> {
    return fetch(src, { method: 'HEAD' })
      .then((response) => response.ok)
      .catch(() => false);
  }
}
