<div style="position: relative">
  <ngx-spinner
    [disableAnimation]="disableAnimation"
    name="user_card"
    bdColor="rgb(237, 237, 237)"
    size="default"
    color="#6264a7"
    type="ball-clip-rotate"
    [fullScreen]="false"
  >
  </ngx-spinner>

  <div class="card_main">
    <div style="width: 110px">
      <img *ngIf="displayUser.photo != null" [src]="displayUser.photo" style="border-radius: 25%; z-index: 100; margin: 10px; width: 100px" />
    </div>
    <div style="margin: 10px; width: 100%">
      <div style="text-align: right">
        <img
          *ngIf="legalEntityImageBase64 != null"
          [src]="legalEntityImageBase64"
          style="width: 80px; height: 80px; border-radius: 25%; z-index: 100; margin: 5px 0 5px auto; object-fit: contain !important"
        />
      </div>
      <div>
        <p style="margin: auto 0">{{ displayUser?.fullName }}</p>
        <p class="card_detail">{{ displayUser?.legalEntityName }} - {{ displayUser?.divisionName }} - {{ displayUser?.departmentName }}</p>
        <p class="card_detail">Reports to:</p>
        <p class="card_detail">{{ displayUser.managerEmployeeFullname }}</p>
      </div>
    </div>
  </div>
</div>
