import { Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldProps } from '@ngx-formly/core';
import { IntlService } from '@progress/kendo-angular-intl';

export interface KendoFormlyDateProps extends FormlyFieldProps {
  format?: string;
  label?: string;
  readonly?: boolean;
  required?: boolean;
  tabindex?: number;
}

@Component({
  selector: 'verde-kendo-formly-date',
  template: `
    <label class="k-label ng-star-inserted k-form-field" *ngIf="props.label">{{ props.label }}</label>
    <kendo-datepicker
      [format]="props.format || 'yyyy/MM/dd'"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [readonly]="props.readonly"
      [required]="props.required"
      [tabIndex]="props.tabindex || 0"
    >
    </kendo-datepicker>
  `,
})
export class KendoFormlyDate extends FieldType<FieldTypeConfig<KendoFormlyDateProps>> implements OnInit {
  constructor(private intl: IntlService) {
    super();
  }

  ngOnInit() {
    if (this.formControl) {
      if (this.field.defaultValue === 'today') {
        this.formControl.setValue(new Date());
      } else if (this.field.defaultValue) {
        const customDate = this.intl.formatDate(this.field.defaultValue, 'yyyy/MM/dd');
        if (customDate) {
          this.formControl.setValue(new Date(customDate));
        }
      }
    }
  }
}
