import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { UserService } from '@verde/core';
import {
  CommonApiWebDynamicService,
  CommonPositionSeatRequest,
  DotsMenuItem,
  RefreshService,
  SidePanelWidth,
  UserAppConfigDto,
  UserSecurityFunctionDto,
  WebDynamicFormMode,
  WebDynamicFormType,
  WebDynamicService,
  WebDynamicSidePanelArgs,
  ReportingApiReportsService,
} from '@verde/shared';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'verde-kendo-formly-grid',
  templateUrl: `grid.component.html`,
})
export class KendoFormlyGrid extends FieldType implements OnInit {
  private onDestroy$ = new Subject<boolean>();

  //three dot menu
  dotMenuItems: DotsMenuItem[] = [{}];
  peopleManagementThreeDotMenuFunctions: Array<UserSecurityFunctionDto> = new Array<UserSecurityFunctionDto>();
  config: UserAppConfigDto = {};
  gridData: any[];
  columns: any[];
  selectedrequest: CommonPositionSeatRequest;
  JsonFileName: string = '';
  gridLoading = true;

  constructor(
    private commonApiCommonService: CommonApiWebDynamicService,
    private userService: UserService,
    private webDynamicService: WebDynamicService,
    private cdr: ChangeDetectorRef,
    private refreshService: RefreshService,
    private test: ReportingApiReportsService,
  ) {
    super();

    this.refreshService.formCompleted$.subscribe(() => {
      this.gridLoading = true;
      setTimeout(() => {
        this.refresh();
      }, 500);
    });
  }

  ngOnInit() {
    this.gridData = this.field.formControl.value['gridData'] || [];
    this.columns = this.field.formControl.value['columns'] || [];
    //console.log("AAAAAAA", this.field, this.model)

    this.commonApiCommonService
      .getPositionSeatRequests({ body: { processStatus: '532525252', verdeStandardProcessName: 'Seat' } })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (val) => {
          this.gridData = val;
          this.gridLoading = false;
          this.cdr.detectChanges();
        },
        (error) => {
          console.error(error);
        },
        () => {},
      );
    this.userService.config$.pipe(takeUntil(this.onDestroy$)).subscribe((config) => {
      if (config !== null && config !== undefined && Object.keys(config).length > 0) {
        this.config = config;
        console.log('AAAAAAAA', this.config);
      }
    });
    this.getThreeDotMenuFunctions();

    this.test
      .empContactPositionManager({ body: { test: 'test' } })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (val) => {
          console.log(val);
        },
        (error) => {
          console.error(error);
        },
        () => {},
      );
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  getThreeDotMenuFunctions() {
    //People Management Three Dot Menu
    this.peopleManagementThreeDotMenuFunctions = [];
    const initialFunctions = this.userService.filterUserPermissions('People Management Three Dot Menu', false).sort((a, b) => {
      if (a.menuName && b.menuName) {
        const nameA = a.menuName.toLowerCase();
        const nameB = b.menuName.toLowerCase();
        if (nameA < nameB) {
          //sort string ascending
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      }

      return 0;
    });

    initialFunctions.forEach((t) => {
      if (this.config?.legalEntityConfig[t.configFieldName] || t.configFieldName === 'null') {
        this.peopleManagementThreeDotMenuFunctions.push(t);
      }
    });
  }

  refresh() {
    this.gridLoading = true;
    this.commonApiCommonService
      .getPositionSeatRequests({ body: { processStatus: '532525252', verdeStandardProcessName: 'Seat' } })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (val) => {
          this.gridData = val;
          this.gridLoading = false;
          this.cdr.detectChanges();
        },
        () => {},
        () => {},
      );
    this.userService.config$.pipe(takeUntil(this.onDestroy$)).subscribe((config) => {
      if (config !== null && config !== undefined && Object.keys(config).length > 0) {
        this.config = config;
      }
    });
    this.getThreeDotMenuFunctions();
  }

  menuClicked(e) {
    this.selectedrequest = e;
    this.dotMenuItems = [];
    const tempItems: DotsMenuItem[] = [];
    this.peopleManagementThreeDotMenuFunctions.forEach((t) => {
      if (t.technicalName === 'PEOPLE-GRID-LINE-MENU-EDIT-POSITION-SEATS') {
        this.JsonFileName = t.jsonFileName?.toString();
        tempItems.push({ id: t.technicalName, text: t.menuName });
      }
    });
    this.dotMenuItems = [...tempItems];
  }

  menuItemClicked(e: DotsMenuItem) {
    if (e.id === 'PEOPLE-GRID-LINE-MENU-EDIT-POSITION-SEATS') {
      this.webDynamicService.setDynamicForm({
        entityId: this.selectedrequest.bt_verdestructurechangerequestid,
        formFile: this.JsonFileName,
        formType: WebDynamicFormType.SIDEPANEL,
        formMode: WebDynamicFormMode.UPDATE,
        args: {
          visible: true,
          size: SidePanelWidth.Half,
        } as WebDynamicSidePanelArgs,
      });
    }
  }
}
