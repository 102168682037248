import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PowerBiTokenService } from './power-bi-token.service';
import { VerdePowerBiService } from './verde-power-bi.service';

@NgModule({
  imports: [CommonModule],
  providers: [PowerBiTokenService, VerdePowerBiService],
})
export class VerdePowerBiModule {}
