<form #fleetOdoUpdateForm="ngForm" novalidate autocomplete="off" style="position: relative">
  <ngx-spinner [disableAnimation]="true" name="fleetodoupdate" bdColor="rgb(255, 255, 255)" size="default" color="#6264a7" type="timer" [fullScreen]="false">
    <p style="font-size: 20px; color: black; margin-top: 35px; text-align: center"></p>
  </ngx-spinner>
  <p style="margin-top: 12px">Service</p>
  <div class="modal_grid">
    <div class="form-group">
      <label for="odoServiceStatus">Status:</label>
      <kendo-textbox [readonly]="true" id="odoServiceStatus" name="odoServiceStatus" [ngModel]="selectedFleetItem?.odataServiceStatus"> </kendo-textbox>
    </div>

    <div class="form-group">
      <label for="odoServiceInterval">Interval:</label>
      <kendo-textbox [readonly]="true" id="odoServiceInterval" name="odoServiceInterval" [ngModel]="selectedFleetItem?.bthr_serviceinterval"> </kendo-textbox>
    </div>

    <div class="form-group" *ngIf="selectedFleetItem?.odataServiceStatus != 'New'">
      <label for="lastServiceDate">Last Date:</label>
      <kendo-textbox [readonly]="true" id="lastServiceDate" name="lastServiceDate" [ngModel]="selectedFleetItem?.bthr_lastservicedate | date : 'yyyy/MM/dd'">
      </kendo-textbox>
    </div>

    <div class="form-group" *ngIf="selectedFleetItem?.odataServiceStatus == 'New'">
      <label for="lastServiceDate">Last Date:</label>
      <kendo-datepicker id="lastServiceDate" name="lastServiceDate" #varLastServiceDate="ngModel" required ngModel> </kendo-datepicker>

      <!--Validation-->
      <div class="alert alert-danger" *ngIf="varLastServiceDate.touched && !varLastServiceDate.valid">
        <div *ngIf="varLastServiceDate.errors?.required">Field is required!</div>
      </div>
    </div>

    <div class="form-group">
      <label for="lastServiceOdo">Last ODO:</label>
      <kendo-textbox
        [readonly]="selectedFleetItem?.odataServiceStatus != 'New'"
        id="lastServiceOdo"
        name="lastServiceOdo"
        [ngModel]="selectedFleetItem?.bt_lastserviceodometerreading"
      >
      </kendo-textbox>
    </div>

    <div class="form-group">
      <label for="nextServiceOdo">Next ODO:</label>
      <kendo-textbox [readonly]="true" id="nextServiceOdo" name="nextServiceOdo" [ngModel]="selectedFleetItem?.bt_nextserviceodo"> </kendo-textbox>
    </div>

    <div class="form-group">
      <label for="nextServiceOdo">Next Date:</label>
      <kendo-textbox [readonly]="true" id="nextServiceDate" name="nextServiceDate" [ngModel]="nextServiceDate"> </kendo-textbox>
    </div>
  </div>

  <p style="margin-top: 12px">Last Inspection</p>
  <div class="modal_grid">
    <div class="form-group">
      <label for="lastInspectionDate">Date:</label>
      <kendo-textbox
        [readonly]="true"
        id="lastInspectionDate"
        name="lastInspectionDate"
        [ngModel]="selectedFleetItem?.bthr_lastinspectiondate | date : 'yyyy/MM/dd'"
      >
      </kendo-textbox>
    </div>

    <div class="form-group">
      <label for="lastInspectionOdo">ODO Reading:</label>
      <kendo-textbox [readonly]="true" id="lastInspectionOdo" name="lastInspectionOdo" [ngModel]="selectedFleetItem?.bthr_odometerreading"> </kendo-textbox>
    </div>
  </div>

  <p style="margin-top: 12px">Latest Readings</p>
  <div class="modal_grid">
    <div class="form-group">
      <label for="todaysDate">Today:</label>
      <kendo-textbox [readonly]="true" id="todaysDate" name="todaysDate" [ngModel]="todaysDate"> </kendo-textbox>
    </div>

    <div class="form-group">
      <label for="latestInspectionOdo">ODO Reading:</label>
      <kendo-numerictextbox
        [min]="selectedFleetItem?.bthr_odometerreading"
        [max]="1000000"
        #varLatestInspectionOdo="ngModel"
        id="latestInspectionOdo"
        name="latestInspectionOdo"
        required
        [(ngModel)]="latestOdoReading"
      >
      </kendo-numerictextbox>

      <!-- Validation -->
      <div class="alert alert-danger" *ngIf="varLatestInspectionOdo.touched && !varLatestInspectionOdo.valid">
        <div *ngIf="varLatestInspectionOdo.errors?.required">Field is required!</div>
      </div>
    </div>
  </div>

  <p *ngIf="latestOdoReading > selectedFleetItem?.bt_nextserviceodo && selectedFleetItem?.odataServiceStatus != 'New'" style="color: red">
    Please note: this vehicle is overdue for service.
  </p>
  <button
    style="position: absolute; bottom: 20px; right: 20px"
    kendoButton
    themeColor="primary"
    (click)="showCheckModal = true"
    [disabled]="fleetOdoUpdateForm.invalid"
  >
    Submit
  </button>
</form>

<!-- #region Confirmation Modal -->
<kendo-dialog *ngIf="showCheckModal" title="Confirmation" verdeResponsiveDialog>
  <div style="height: 60px">
    <p>Are you sure you want to submit this update?</p>
  </div>
  <kendo-dialog-actions>
    <button kendoButton themeColor="primary" (click)="showCheckModal = false">Cancel</button>
    <button kendoButton themeColor="primary" style="margin-left: auto" (click)="postEntityTransfer(fleetOdoUpdateForm)">Confirm</button>
  </kendo-dialog-actions>
</kendo-dialog>
<kendo-dialog *ngIf="showConfirmationModal" title="Confirmation" verdeResponsiveDialog>
  <div style="height: 60px">
    <p>The odometer update was successful.</p>
  </div>
  <kendo-dialog-actions>
    <button kendoButton themeColor="primary" style="margin-left: auto" (click)="closeConfirmationModal()">Continue</button>
  </kendo-dialog-actions>
</kendo-dialog>
<!-- #endregion Confirmation Modal -->
