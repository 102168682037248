/* tslint:disable */
/* eslint-disable */
export enum UserBtModule {
  People = 'People',
  TimeOff = 'TimeOff',
  Fleet = 'Fleet',
  Gifts = 'Gifts',
  Equipment = 'Equipment',
  Structure = 'Structure',
  Learn = 'Learn',
  Documents = 'Documents',
  Engage = 'Engage',
  Task = 'Task',
  Requests = 'Requests',
  Bookings = 'Bookings',
  TimeTracking = 'TimeTracking',
  Policy = 'Policy',
  PowerBi = 'PowerBI',
  Templates = 'Templates',
  Travel = 'Travel',
  ExpenseClaims = 'ExpenseClaims',
  Workspaces = 'Workspaces',
  PeopleManagement = 'PeopleManagement',
  Admin = 'Admin',
}
