import { Component, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { VerdeApprovalService } from '../../../services/verde-approval.service';

@Component({
  selector: 'verde-benchmark-edit',
  templateUrl: './benchmark-edit.component.html',
  styleUrls: ['./benchmark-edit.component.scss'],
})
export class BenchmarkEditComponent implements OnInit {
  private onDestroy$ = new Subject<boolean>();
  selectedBenchmark: any;
  constructor(private sidebarService: VerdeApprovalService) {}

  ngOnInit(): void {
    this.sidebarService
      .getSelectedBenchmark()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((benchmark: any) => {
        this.selectedBenchmark = benchmark;
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  editBenchmark() {}
}
