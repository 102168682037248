<section class="main-review-component">
  <div class="review-window">
    <div class="review-header">
      <span>{{ currentQuestionDisplay + 1 }} / {{ totalQuestions }}</span>
      <div class="progress-bar-outline">
        <div class="progress-bar-inner" [style.width]="progressBarStatus"></div>
      </div>
    </div>

    <div class="questions-container">
      <ng-container *ngFor="let review of reviewQuestions; let i = index">
        <div class="question" *ngIf="i === currentQuestion && !transition" [@enter] [@leave]>
          <div>
            <div class="sub-headings">
              <div class="group" style="margin-top: 20px">
                <span>{{ review.group?.odata }}</span>
              </div>
              <!-- <div *ngIf="review.bt_displaysubgroup" class="sub-group"> -->
              <div class="sub-group" style="margin-top: 20px">
                <span>{{ review.subgroup?.odata }}</span>
              </div>
            </div>
            <!--<mat-divider></mat-divider>-->
            <div class="question-header">
              <h3>Question {{ currentQuestion + 1 }}</h3>
              <h4>{{ review.measure?.odata }}</h4>
            </div>
          </div>
          <div class="question-content">
            <!-- Rating Question -->
            <verde-enhanced-rating-option
              *ngIf="review.bt_responsetype === 'rating'"
              [setReviewOption]="review"
              (answerSelected)="setQuestionAnswer(i, $event)"
            >
            </verde-enhanced-rating-option>

            <!-- Response Question -->
            <verde-enhanced-response-option *ngIf="review.bt_responsetype === 'free text'" [setReviewOption]="review"> </verde-enhanced-response-option>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="button-section">
      <button kendoButton themeColor="primary" [disabled]="!(currentQuestionDisplay > 0)" (click)="previousQuestion()">Previous</button>

      <button kendoButton themeColor="primary" *ngIf="currentQuestionDisplay < totalQuestions - 1" [disabled]="questionNotAnswered" (click)="nextQuestion()">
        Next
      </button>

      <button
        kendoButton
        themeColor="primary"
        class="review-submit"
        *ngIf="!(currentQuestionDisplay < totalQuestions - 1)"
        [disabled]="questionNotAnswered"
        (click)="submitConfirmation()"
      >
        Submit
      </button>
    </div>
  </div>
</section>

<verde-modal id="submitReview" [modalMinWidth]="600" [modalTitle]="'Submit review?'" [submitButton]="'Submit'" [zIndex]="3000" (actionButton)="submit()">
  <h5>Are you sure you want to submit review?</h5>
  <p>You can still go back and edit questions but once you submit your review you cannot change your answers.</p>
</verde-modal>
