import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldProps } from '@ngx-formly/core';

export interface KendoFormlyInputProps extends FormlyFieldProps {}

@Component({
  selector: 'verde-kendo-formly-input',
  template: `
    <kendo-label *ngIf="props.description">{{ props.description }}</kendo-label>
    <input
      [id]="field.key"
      *ngIf="props.type !== 'number'; else numberTmp"
      kendoTextBox
      [type]="props.type || 'text'"
      [formlyAttributes]="field"
      [formControl]="formControl"
      [readonly]="props.readonly"
      [required]="props.required"
      [tabIndex]="props.tabindex || 0"
      [placeholder]="props.placeholder"
    />
    <ng-template #numberTmp>
      <kendo-numerictextbox
        [id]="field.key"
        type="number"
        [min]="0"
        [max]="100"
        [formlyAttributes]="field"
        [formControl]="formControl"
        [readonly]="props.readonly"
        [required]="props.required"
        [tabIndex]="props.tabindex || 0"
        [placeholder]="props.placeholder"
      >
      </kendo-numerictextbox>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KendoFormlyInput extends FieldType<FieldTypeConfig<KendoFormlyInputProps>> {
  get type() {
    return this.props.type || 'text';
  }
}
