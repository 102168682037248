/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WebDynamicApiConfiguration, WebDynamicApiConfigurationParams } from './web-dynamic-api-configuration';

import { WebDynamicApiVersionService } from './services/web-dynamic-api-version.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [WebDynamicApiVersionService, WebDynamicApiConfiguration],
})
export class WebDynamicApiModule {
  static forRoot(params: WebDynamicApiConfigurationParams): ModuleWithProviders<WebDynamicApiModule> {
    return {
      ngModule: WebDynamicApiModule,
      providers: [
        {
          provide: WebDynamicApiConfiguration,
          useValue: params,
        },
      ],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: WebDynamicApiModule, @Optional() http: HttpClient) {
    if (parentModule) {
      throw new Error('WebDynamicApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' + 'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
