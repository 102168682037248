<div style="padding: 20px">
  <div>
    <label>Legal Entity:</label>
    <br />
    <kendo-textbox [readonly]="true" [value]="selectedBenchmark?.bt_LegalEntity.bthr_legalentityname"></kendo-textbox>
    <br />
    <br />
    <label>Group:</label>
    <br />
    <kendo-textbox [readonly]="true" [value]="selectedBenchmark?.bt_Group.bt_name"></kendo-textbox>
    <br />
    <br />
    <label>Benchmark:</label>
    <br />
    <kendo-textbox [readonly]="true" [value]="selectedBenchmark?.bt_reviewbenchmark"></kendo-textbox>
    <br />
    <br />
    <label>Min Value:</label>
    <br />
    <kendo-textbox [readonly]="true" [value]="selectedBenchmark?.bt_minvalue"></kendo-textbox>
    <br />
    <br />
    <label>Max Value:</label>
    <br />
    <kendo-textbox [readonly]="true" [value]="selectedBenchmark?.bt_maxvalue"></kendo-textbox>
    <br />
    <br />
    <label>Description:</label>
    <br />
    <kendo-textbox [readonly]="true" [value]="selectedBenchmark?.bt_description"></kendo-textbox>
  </div>
</div>
