import { Injectable } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { PerformanceReviewService } from '../../../../../../../apps/verde/src/app/features/performance-reviews/services/performance-review.service';
import { PeerReviewFeedback, UserPeerReviewProcess } from '@verde/shared';

@Injectable({
  providedIn: 'root',
})
export class CapturePeerReviewServiceService {
  private onDestroy$: Subject<any> = new Subject();

  activeReviewProcess: UserPeerReviewProcess = {};
  activeQuestionSet: PeerReviewFeedback[] = [];

  setActiveReview(value: UserPeerReviewProcess) {
    this.activeReviewProcess = value;
  }

  setActiveQuestionSet(value: PeerReviewFeedback[]) {
    this.activeQuestionSet = value;
  }

  constructor(private prService: PerformanceReviewService) {}

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  get currentUnansweredQuestion(): number {
    let unansweredQuestion: number = 0;
    for (const q of this.activeReviewProcess.questions) {
      if (!q.bt_feedbackcompleted) {
        break;
      }
      unansweredQuestion++;
    }
    return unansweredQuestion < this.activeReviewProcess.questions.length ? unansweredQuestion : this.activeReviewProcess.questions.length - 1;
  }

  captureReview(finalSubmit: boolean = true): void {
    if (finalSubmit) {
    }
    for (const question of this.activeQuestionSet) {
      if (question.bt_responsetype === 692360000) {
        // rating
        question.bt_feedbackcompleted = question.bt_rating ? true : false;
      }
      if (question.bt_responsetype === 692360001) {
        // response
        question.bt_feedbackcompleted = question.bt_response ? true : false;
      }
    }

    this.activeReviewProcess.peerReviewSummaries.bt_peerreviewstatus = this.setStatus();

    this.prService
      .updatePeerReview(this.activeReviewProcess)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        if (finalSubmit) {
          /*this.getAllSummaries();*/
        }
      });
  }

  setStatus(): number {
    const allStatusses = this.activeQuestionSet.map((s) => s.bt_feedbackcompleted);
    let hasTrue: boolean;
    let hasFalse: boolean;

    for (const status of allStatusses) {
      if (status) {
        hasTrue = true;
      }
      if (!status) {
        hasFalse = true;
      }
    }

    return hasTrue && !hasFalse ? 692360002 : hasTrue && hasFalse ? 692360001 : 692360000;
  }

  setQuestions(questions: PeerReviewFeedback[]): void {
    this.activeReviewProcess.questions = questions;
  }
}
