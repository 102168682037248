import { WebDynamicFormType } from '../enums/web-dynamic-form-type.enum';
import { WebDynamicFormMode } from '../enums/web-dynamic-form-mode.enum';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { FormGroup } from '@angular/forms';

export interface WebDynamicSidePanelArgs {
  visible: boolean;
  size: number;
}

export interface WebDynamicGridArgs {
  //
}

export interface WebDynamicModel {
  entityId: string;
  formFile?: string;
  form?: FormGroup;
  model?: any;
  additional?: any;
  options?: FormlyFormOptions;
  schema?: any;
  fields?: FormlyFieldConfig[];
  formType: WebDynamicFormType;
  formMode?: WebDynamicFormMode;
  args?: any[] | WebDynamicSidePanelArgs | WebDynamicGridArgs;
}
