import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldProps } from '@ngx-formly/core';

export interface KendoFormlyTabProps extends FormlyFieldProps {}

@Component({
  selector: 'verde-kendo-formly-tab',
  template: `
    <kendo-tabstrip>
      <ng-container *ngFor="let tab of field.fieldGroup">
        <kendo-tabstrip-tab [title]="tab.title">
          <formly-form [form]="form" [id]="field.key" [model]="model" [fields]="tab.sections"></formly-form>
        </kendo-tabstrip-tab>
      </ng-container>
    </kendo-tabstrip>
  `,
})
export class KendoFormlyTab extends FieldType<FieldTypeConfig<KendoFormlyTabProps>> {}
