<ng-container [formGroup]="personalDetails">
  <kendo-formfield>
    <kendo-label [for]="countryResidence" text="Country Residence"></kendo-label>
    <kendo-dropdownlist
      #countryResidence
      formControlName="countryResidence"
      [data]="countriesData"
      textField="bthr_country"
      valueField="bthr_countryid"
      id="countryResidenceDropdown"
      name="countryResidenceDropdown"
      (valueChange)="countryChange($event)"
      required
      [ngModel]
      [disabled]=""
      [filterable]="true"
      (filterChange)="handleCountryFilter($event)"
    >
      <ng-template kendoDropDownListNoDataTemplate>
        <p></p>
      </ng-template>
    </kendo-dropdownlist>
    <kendo-formerror>Country Residence is required</kendo-formerror>
  </kendo-formfield>

  <kendo-formfield>
    <kendo-label [for]="localResidency" text="Local Residency Status"></kendo-label>
    <kendo-dropdownlist
      #localResidency
      formControlName="localResidency"
      [data]="localResidencyStatus"
      textField="bt_localresidencystatus"
      valueField="bt_localresidencystatusid"
      id="localResidencyStatusDropdown"
      name="localResidencyStatusDropdown"
      (valueChange)="residencyChange($event)"
      required
      [ngModel]
      [disabled]="loadingLocalResidencyStatus"
    >
      <ng-template kendoDropDownListNoDataTemplate>
        <p></p>
      </ng-template>
    </kendo-dropdownlist>
    <kendo-formerror>Local Residency Status is required</kendo-formerror>
  </kendo-formfield>

  <kendo-formfield>
    <kendo-label [for]="primaryNationality" text="Primary Nationality"></kendo-label>
    <kendo-dropdownlist
      #primaryNationality
      formControlName="primaryNationality"
      [data]="nationalities"
      textField="bthr_nationality"
      valueField="bthr_nationalityid"
      id="primaryNationalityDropdown"
      name="primaryNationalityDropdown"
      (valueChange)="nationalityChange($event)"
      required
      [ngModel]
      [disabled]=""
    >
      <ng-template kendoDropDownListNoDataTemplate>
        <p></p>
      </ng-template>
    </kendo-dropdownlist>
    <kendo-formerror>Primary Nationality is required</kendo-formerror>
  </kendo-formfield>

  <kendo-formfield>
    <kendo-label [for]="citizenshipStatus" text="Citizenship Status"></kendo-label>
    <kendo-dropdownlist
      #citizenshipStatus
      formControlName="citizenshipStatus"
      [data]="citizenStatus"
      textField="bt_statusname"
      valueField="bt_citizenshipstatusid"
      id="citizenshipStatusStatusDropdown"
      name="citizenshipStatusStatusDropdown"
      required
      [ngModel]
      [disabled]="loadingCitizenshipStatus"
    >
      <ng-template kendoDropDownListNoDataTemplate>
        <p></p>
      </ng-template>
    </kendo-dropdownlist>
    <kendo-formerror>Citizenship Status Status is required</kendo-formerror>
  </kendo-formfield>

  <kendo-formfield orientation="vertical">
    <kendo-label text="Foreign National"> </kendo-label>
    <ul class="k-radio-list k-list-horizontal">
      <li class="k-radio-item">
        <input type="radio" #yes value="yes" kendoRadioButton disabled formControlName="foreignNational" />
        <kendo-label class="k-radio-label" [for]="yes" text="Yes"></kendo-label>
      </li>

      <li class="k-radio-item">
        <input type="radio" #no value="no" kendoRadioButton disabled formControlName="foreignNational" />
        <kendo-label class="k-radio-label" [for]="no" text="No"></kendo-label>
      </li>
    </ul>
  </kendo-formfield>

  <kendo-formfield>
    <kendo-label [for]="idNumber" text="ID Number"></kendo-label>
    <div class="input-container">
      <kendo-textbox formControlName="idNumber" #idNumber (valueChange)="onIDChange($event)"></kendo-textbox>
      <kendo-buttongroup>
        <button kendoButton themeColor="primary" (click)="validateIdNumber()" [disabled]="validateEnabled">Validate</button>
      </kendo-buttongroup>
    </div>
    <kendo-formhint>{{ validationMessage }}</kendo-formhint>
  </kendo-formfield>

  <kendo-formfield>
    <kendo-label [for]="idUpload" [optional]="true" text="Re-upload ID Doc"></kendo-label>
    <kendo-fileselect #idUpload formControlName="idUpload" [restrictions]="restrictions" (select)="onFileSelect($event)"> </kendo-fileselect>

    <kendo-formhint>Allowed extensions are jpg, jpeg, png or pdf</kendo-formhint>
    <kendo-formhint>
      <a style="color: #0366d6" href="#" (click)="donwloadDoc(); (false)">
        {{ onboardingEmployee?.bthr_nationalidpassport_Name }}
      </a>
    </kendo-formhint>
  </kendo-formfield>
</ng-container>
