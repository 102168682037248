<button style="display: none" (click)="loadPDF()">test</button>

<div style="position: relative">
  <div class="example-config">
    <div style="margin-top: 2%">
      <button class="saveaspdf-btn" kendoButton (click)="SaveThePdf(pdf)">Save As PDF...</button>
    </div>
  </div>
  <div style="height: 80vh; overflow-y: auto; background-color: white">
    <kendo-pdf-export #pdf paperSize="A4" margin="0.5cm" [scale]="0.75">
      <div id="HtmlTemplateContent" style="padding: 20px"></div>
    </kendo-pdf-export>
  </div>
  <div style="margin-top: 2%; position: sticky; bottom: 0px; background-color: white; padding-bottom: 10px; padding-bottom: 2px">
    <button id="confirm-button" style="float: right" kendoButton themeColor="primary" (click)="sign()">Sign</button>
    <button id="cancel-button" style="" kendoButton themeColor="primary" (click)="cancel()">Cancel</button>
  </div>
</div>

<verde-modal
  modalTitle="Confirmation"
  id="confirmFundOptionChange"
  [hasCancelButton]="true"
  submitButton="Continue"
  (actionButton)="confirm(pdf)"
  modalMaxWidth="400"
>
  <div style="position: relative; height: 60px">
    <p>Please confirm that you would like to change fund option to: {{ myPdfInputs.bt_newOption }}</p>
  </div>
</verde-modal>
