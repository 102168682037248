import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpCancelService } from '@verde/core';
import { Subject, takeUntil } from 'rxjs';
import { StructureService } from 'apps/verde/src/app/features/structure-management/services/structure.service';
import { StructureFullExtended } from 'apps/verde/src/app/features/structure-management/store/models/structure-full-extended.model';
import { VerdeApprovalService } from '../../../services/verde-approval.service';

@Component({
  selector: 'verde-structure-dispose-request',
  templateUrl: './structure-dispose-request.component.html',
  styleUrls: ['./structure-dispose-request.component.scss'],
})
export class StructureDisposeRequestComponent implements OnDestroy {
  @Input() parentItem: StructureFullExtended;
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter();

  private onDestroy$ = new Subject<boolean>();
  fb: FormBuilder;
  fg: FormGroup;

  get isSubmitActive(): boolean {
    return !(this.fg ? this.fg.invalid : true);
  }

  constructor(private structureService: StructureService, private httpCancelService: HttpCancelService, private sidebarService: VerdeApprovalService) {
    this.sidebarService
      .getParentItem()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((item: any) => {
        this.parentItem = item;
      });

    this.fb = new FormBuilder();
    this.resetForm();
  }

  resetForm() {
    this.fg = this.fb.group({
      motivation: [],
      effectiveDate: [new Date()],
    });
  }

  async dispose() {
    const structure: StructureFullExtended = {
      ...this.parentItem,
      $motivation: this.fg.value.motivation,
      $effectiveDate: this.fg.value.effectiveDate,
    };
    this.structureService.disposeStructures([structure]);

    this.close();
  }

  close() {
    this.resetForm();
    this.structureService.deselectParentStructureId();
    /*this.closeModal.emit();*/
    this.sidebarService.setShowSidebar(false);
  }

  ngOnDestroy(): void {
    this.httpCancelService.cancelPendingRequests();

    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
