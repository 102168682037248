<!-- Rendom Button -->
<button id="showClassificationModal" hidden (click)="getFleetItem(fleetClassificationUpdateForm)"></button>
<!-- #region Assign Fleet Modal -->
<verde-modal
  id="fleetClassificationUpdateModal"
  modalTitle="Update Classification"
  modalMinWidth="750"
  submitButton="Submit"
  (actionButton)="postFleetClassificationUpdate(fleetClassificationUpdateForm)"
  (modalCancelled)="hideModal()"
  [submitActive]="fleetClassificationUpdateForm.valid && (agreedYes || agreedNo)"
  submitConfirmationMessage="Please confirm that you would like to update this fleet items classification"
  cancelConfirmationMessage="Please confirm that you would like to cancel"
  [hasSubmitConfirmation]="true"
  [hasCancelConfirmation]="fleetClassificationUpdateForm.touched && !fleetClassificationUpdateForm.pristine"
>
  <form #fleetClassificationUpdateForm="ngForm" novalidate autocomplete="off" style="position: relative">
    <ngx-spinner
      [disableAnimation]="disableAnimation"
      name="fleetclassificationupdate"
      bdColor="rgb(237, 237, 237)"
      size="default"
      color="#6264a7"
      type="ball-clip-rotate"
      [fullScreen]="false"
    >
    </ngx-spinner>

    <div class="modal_grid">
      <div class="form-group">
        <label for="updateFleetNo">Fleet No:</label>
        <kendo-textbox [readonly]="true" id="updateFleetNo" name="updateFleetNo" [ngModel]="selectedFleetItem?.bt_fleetno"> </kendo-textbox>
      </div>

      <div class="form-group">
        <label for="updateLicenseNo">License No:</label>
        <kendo-textbox [readonly]="true" id="updateLicenseNo" name="updateLicenseNo" [ngModel]="selectedFleetItem?.bthr_licensenumber"> </kendo-textbox>
      </div>

      <div class="form-group">
        <label for="updateFleetItem">Fleet Item:</label>
        <kendo-textbox [readonly]="true" id="updateFleetItem" name="updateFleetItem" [ngModel]="selectedFleetItem?.odataMake"> </kendo-textbox>
      </div>

      <div class="form-group">
        <label for="updateFleetStatus">Status:</label>
        <kendo-textbox [readonly]="true" id="updateFleetStatus" name="updateFleetStatus" [ngModel]="selectedFleetItem?.odataFleetMovementStatus">
        </kendo-textbox>
      </div>

      <div class="form-group">
        <label for="updateFromClassification">From Classification:</label>
        <kendo-textbox [readonly]="true" id="updateFromClassification" name="updateFromClassification" [ngModel]="selectedFleetItem?.odataClassification">
        </kendo-textbox>
      </div>

      <div class="form-group">
        <label for="updateToClassification">To Classification:</label>
        <kendo-dropdownlist
          [data]="allClassifications"
          textField="text"
          valueField="value"
          id="updateToClassification"
          name="updateToClassification"
          #varUpdateToClassification="ngModel"
          required
          [ngModel]
        >
        </kendo-dropdownlist>

        <!-- Validation -->
        <div class="alert alert-danger" *ngIf="varUpdateToClassification.touched && !varUpdateToClassification.valid">
          <div *ngIf="varUpdateToClassification.errors?.pattern">Field is required!</div>
          <div *ngIf="varUpdateToClassification.errors?.required">Field is required!</div>
        </div>
      </div>

      <div class="form-group">
        <label for="updateEffectiveDate">Effective Date:</label>
        <kendo-datepicker
          id="updateEffectiveDate"
          name="updateEffectiveDate"
          #varUpdateEffectiveDate="ngModel"
          required
          (valueChange)="updateEffectiveDateValue($event)"
          [min]="todaysDateNew"
          [ngModel]="todaysDateNew"
        >
        </kendo-datepicker>

        <!-- Validation -->
        <div class="alert alert-danger" *ngIf="varUpdateEffectiveDate.touched && !varUpdateEffectiveDate.valid">
          <div *ngIf="varUpdateEffectiveDate.errors?.required">Field is required!</div>
        </div>
      </div>

      <div class="form-group">
        <div style="float: right; display: inline-flex; margin-bottom: unset">
          <label style="margin-bottom: unset">Auto Reverse:</label>

          <div style="display: inline-flex; margin-bottom: unset; margin-left: 5px; float: right">
            <label style="margin-bottom: unset" for="agreedYes">Yes:</label>
            <input
              style="width: 25px; height: 20px; margin: 3px 0 0 5px; vertical-align: middle"
              (change)="agreedYesChange($event.target.checked, varAgreedNo)"
              type="checkbox"
              kendoCheckBox
              class="form-control"
              id="agreedYes"
              name="agreedYes"
              #varAgreedYes="ngModel"
              [ngModel]="agreedYes"
            />

            <label for="agreedNo" style="margin-left: 5px; margin-bottom: unset">No:</label>
            <input
              style="width: 25px; height: 20px; margin: 3px 0 0 5px; vertical-align: middle"
              (change)="agreedNoChange($event.target.checked, varAgreedYes)"
              type="checkbox"
              kendoCheckBox
              class="form-control"
              id="agreedNo"
              name="agreedNo"
              #varAgreedNo="ngModel"
              [ngModel]="agreedNo"
            />
          </div>
        </div>
      </div>

      <div *ngIf="agreedYes" class="form-group">
        <label for="updateReversalDate">Reversal Date:</label>
        <kendo-datepicker
          id="updateReversalDate"
          name="updateReversalDate"
          #varUpdateReversalDate="ngModel"
          required
          [min]="minReversalDateNew"
          [ngModel]="minReversalDateNew"
        >
        </kendo-datepicker>

        <!-- Validation -->
        <div class="alert alert-danger" *ngIf="varUpdateReversalDate.touched && !varUpdateReversalDate.valid">
          <div *ngIf="varUpdateReversalDate.errors?.required">Field is required!</div>
        </div>
      </div>
    </div>
  </form>
</verde-modal>
<!-- #endregion Assign Fleet Modal -->
<!-- #region Confirmation Modal -->
<verde-modal
  modalTitle="Confirmation"
  id="confirmFleetClassificationUpdateModal"
  modalMaxWidth="400"
  [hasCancelButton]="false"
  submitButton="Continue"
  (actionButton)="hideModal()"
>
  <div style="position: relative; height: 60px">
    <ngx-spinner
      [disableAnimation]="disableAnimation"
      name="confirmfleetclassificationupdate"
      bdColor="rgb(237, 237, 237)"
      size="default"
      color="#6264a7"
      type="ball-clip-rotate"
      [fullScreen]="false"
    >
    </ngx-spinner>

    <p>{{ confirmationMessage }}</p>
  </div>
</verde-modal>
<!-- #endregion Confirmation Modal -->
