<ng-container [formGroup]="passportInfo">
  <kendo-formfield orientation="horizontal">
    <kendo-label text="Has Passort"> </kendo-label>
    <ul class="k-radio-list k-list-horizontal">
      <li class="k-radio-item">
        <input type="radio" #yes value="yes" kendoRadioButton formControlName="hasPassport" (change)="onRadioChange('yes')" />
        <kendo-label class="k-radio-label" [for]="yes" text="Yes"></kendo-label>
      </li>

      <li class="k-radio-item">
        <input type="radio" #no value="no" kendoRadioButton formControlName="hasPassport" (change)="onRadioChange('no')" />
        <kendo-label class="k-radio-label" [for]="no" text="No"></kendo-label>
      </li>
    </ul>
  </kendo-formfield>

  <kendo-formfield *ngIf="passportInfo.controls.hasPassport.value === 'yes'">
    <kendo-label [for]="passportCountry" text="Passport Country"> </kendo-label>
    <kendo-textbox formControlName="passportCountry" #passportCountry readonly="true"> </kendo-textbox>
  </kendo-formfield>

  <kendo-formfield *ngIf="passportInfo.controls.hasPassport.value === 'yes'">
    <kendo-label [for]="passportNumber" text="Passport Number"> </kendo-label>
    <kendo-textbox formControlName="passportNumber" #passportNumber> </kendo-textbox>
  </kendo-formfield>

  <kendo-formfield *ngIf="passportInfo.controls.hasPassport.value === 'yes'">
    <kendo-label [for]="passportExpDate" text="Passport Expiry Date"></kendo-label>
    <kendo-datepicker #passportExpDate required formControlName="passportExpDate" [format]="'yyyy-MM-dd'"> </kendo-datepicker>
  </kendo-formfield>

  <kendo-formfield *ngIf="passportInfo.controls.hasPassport.value === 'yes'">
    <kendo-label [for]="passportUpload" text="Re-upload Passport  - Required"></kendo-label>
    <kendo-fileselect #passportUpload formControlName="passportUpload" [restrictions]="restrictions" (select)="onFileSelect($event)"> </kendo-fileselect>

    <kendo-formhint>Allowed extensions are jpg, jpeg, png or pdf</kendo-formhint>
    <kendo-formhint>
      <a style="color: #0366d6" href="#" (click)="donwloadDoc(); (false)">
        {{ onboardingEmployee?.bthr_passport_Name }}
      </a>
    </kendo-formhint>
  </kendo-formfield>
</ng-container>
