import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'verde-kendo-formly-file',
  template: `
    <kendo-fileselect
      [accept]="this.optionsVarAccept"
      [multiple]="this.optionsVarMultiple"
      [ngModel]="this.key"
      (valueChange)="fileChanged($event)"
      [id]="this.key"
    ></kendo-fileselect>
  `,
})
export class KendoFormlyFile extends FieldType<FieldTypeConfig> {
  optionsVarAccept: any = [];
  optionsVarMultiple: any = [];

  ngOnInit() {
    //console.log("QWE2", "field", this.field, "defaultOptions", this.defaultOptions, "model", this.model, this.form, "options", this.options, "key", this.key, "formControl", this.formControl, "props", this.props, "to", this.to, "showError", this.showError, "id", this.id, "formState", this.formState);

    //debugger
    this.optionsVarAccept =
      (this.formState ?? '') == '' ? [] : this.formState[this.field?.parent?.key?.toString()][this.key?.toString()]?.additionalProperties?.fileType ?? [];
    this.optionsVarMultiple =
      (this.formState ?? '') == '' ? [] : this.formState[this.field?.parent?.key?.toString()][this.key?.toString()]?.additionalProperties?.multiple ?? false;

    //console.log("AAAAAAA", this.field, this.model)

    //console.log("TESTING3", this.formState,  this.optionsVarAccept, this.optionsVarMultiple)
  }

  fileChanged(data) {
    //console.log("QWE23", "field", this.field, "defaultOptions", this.defaultOptions, "model", this.model, this.form, "options", this.options, "key", this.key, "formControl", this.formControl, "props", this.props, "to", this.to, "showError", this.showError, "id", this.id, "formState", this.formState);

    if ((this.optionsVarAccept[0] ?? '') == '') {
      //debugger
      this.optionsVarAccept =
        (this.formState ?? '') == '' ? [] : this.formState[this.field?.parent?.key?.toString()][this.key?.toString()]?.additionalProperties?.fileType ?? [];
      this.optionsVarMultiple =
        (this.formState ?? '') == '' ? [] : this.formState[this.field?.parent?.key?.toString()][this.key?.toString()]?.additionalProperties?.multiple ?? false;
    }

    //console.log("TESTING", this.optionsVarAccept, this.optionsVarMultiple)

    // Convert File to Blob
    const blob = (data ?? '') == '' ? null : new Blob([data[0]], { type: data[0].type });

    //convert blob to bas64
    //var reader = new FileReader();
    //reader.readAsDataURL(blob);
    // reader.onloadend = () => this.setFile(reader.result.toString())

    // You can now use the Blob object
    //console.log("BLOBDATA", blob, data[0], data[0].type);

    this.setFile(blob);

    // this.model =
    //   if ((this.registerForm?.value?.eQfile ?? null) != null || (this.registerForm?.value?.renewalFile ?? null) != null) {
    //  this.getBase64(this.registerForm?.value?.eQfile[0]! ?? this.registerForm?.value?.renewalFile[0]! ?? '').then((data) =>
    //    this.fileToBase64String(data.toString()),
    //  );
  }

  setFile(input) {
    this.formControl.setValue(input);
  }

  getBase64(file) {
    //return new Promise((resolve, reject) => {
    //  const reader = new FileReader();
    //  reader.readAsDataURL(file);
    //  reader.onload = () => resolve(reader.result);
    //  reader.onerror = (error) => reject('');
    //});
  }

  public fileToBase64String(testa: string) {
    //if (this.deviceTypeService.isMobile() != true) {
    //  testa = testa.replace('data:', '').replace(/^.+,/, '').replace('on/pdf;base64,', '');
    //  setTimeout(() => {
    //    this.viewSDKClient.ready().then(() => {
    //      this.viewSDKClient.previewFile(
    //        'pdf-div',
    //        {},
    //        testa.toString() ?? '',
    //        this.registerForm?.value?.eQfile[0]?.name?.toString() ?? this.registerForm?.value?.renewalFile[0]?.name?.toString() ?? '',
    //      );
    //    });
    //    this.runOnUpdate();
    //  }, 1000);
    //  this.runOnUpdate();
    //}
  }
}
/*<input style="margin-bottom: 5%" type="file" [formControl]="formControl" [formlyAttributes]="field" /> */

//"properties": {
//             "btvsp_file_bthr_cvdocument": {
//               "type": "input",
//               "readOnly": false,
//               "title": "CV",
//               "templateOptions": {
//                 "type": "file",
//                 "placeholder": "Upload your CV",
//                 "additionalProperties": {
//                   "fileType": [ "pdf" ],
//                   "maxFileSize": 10485760,
//                   "multiple": false,
//                   "encryptionTypes": [ "Standard" ],
//                   "overwrite": false
//                 }
//               }
//             }

//"additionalProperties": {
//                   "fileType": [
//                     ".jpg",
//                     ".jpeg",
//                     ".png"
//                   ],
//                   "maxFileSize": 10485760,
//                   "maxImageSize": "144x144",
//                   "multiple": false,
//                   "encryptionTypes": [
//                     "Standard"
//                   ],
//                   "overwrite": true
