import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { fadeInDownOnEnterAnimation, fadeOutUpOnLeaveAnimation } from 'angular-animations';
import {
  ModalService,
  PerformanceReviewBtDetailType,
  PerformanceReviewBtPeerReviewStatus,
  PerformanceReviewBtResponseType,
  PerformanceReviewEnhancedReviewDetail,
  UserReviewProcess,
  VerdeApprovalService,
} from '@verde/shared';
/*import { PerformanceReviewService } from '../../services/performance-review.service';*/
import { PerformanceReviewService } from '../../../../../../../../../apps/verde/src/app/features/performance-reviews/services/performance-review.service';
import { Subject, takeUntil } from 'rxjs';
import { StorePerformanceReviewService } from '../../../../../../../../../apps/verde/src/app/features/performance-reviews/services/store-performance-review';
import { StoreEnhancedReviewDetailService } from '../../../../../../../../../apps/verde/src/app/features/performance-reviews/services/enhanced-review-detail';

@Component({
  selector: 'verde-enhanced-review',
  templateUrl: './enhanced-review.component.html',
  styleUrls: ['./enhanced-review.component.scss'],
  animations: [fadeInDownOnEnterAnimation({ anchor: 'enter', duration: 300, delay: 100 }), fadeOutUpOnLeaveAnimation({ anchor: 'leave', duration: 300 })],
})
export class EnhancedReviewComponent implements OnInit {
  private onDestroy$ = new Subject<boolean>();

  currentQuestion: number = 0;

  //@Input()
  //set setActiveReviewProcess(val: UserReviewProcess) {
  //  if (val && val.questions) {
  //    this.reviewProcess = val;
  //    this.reviewQuestions = val.questions;
  //    this.totalQuestions = this.reviewQuestions?.length;
  //    this.prService.currentPerformanceReviewQuestions = [...this.reviewQuestions];
  //    this.currentQuestion = this.getCurrentQuestion();
  //    this.currentQuestionDisplay = this.currentQuestion;
  //  }
  //}

  //@Input()
  //set setActiveReviewProcess(val: UserReviewProcess) {
  //  if (val && val.questions) {
  //    this.reviewProcess = val;
  //    this.reviewQuestions = val.questions;
  //    this.totalQuestions = this.reviewQuestions?.length;
  //    this.prService.currentPerformanceReviewQuestions = [...this.reviewQuestions];
  //    this.currentQuestion = this.getCurrentQuestion();
  //    this.currentQuestionDisplay = this.currentQuestion;
  //  }
  //}
  /*setActiveReviewProcess : any*/

  @Output() nextButtonClick: EventEmitter<boolean> = new EventEmitter();
  @Output() reviewSubmit: EventEmitter<boolean> = new EventEmitter();
  @Output() optionSelected: EventEmitter<PerformanceReviewEnhancedReviewDetail[]> = new EventEmitter();

  reviewQuestions: PerformanceReviewEnhancedReviewDetail[] = [];
  reviewProcess: UserReviewProcess = {};

  totalQuestions: number;
  currentQuestionDisplay: number = 0;
  transition: boolean = false;

  currentQuestionPatch: any;

  constructor(
    private prService: PerformanceReviewService,
    private modalService: ModalService,
    private sidebarService: VerdeApprovalService,
    private storeEnhancedReviewDetailService: StoreEnhancedReviewDetailService,
    private storePerformanceReviewService: StorePerformanceReviewService,
  ) {
    this.totalQuestions = this.reviewQuestions.length;
  }

  ngOnInit(): void {
    this.sidebarService
      .getSelectedActiveReview()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((review: any) => {
        if (review && review.questions) {
          this.reviewProcess = review;
          this.reviewQuestions = JSON.parse(JSON.stringify(review.questions));
          this.totalQuestions = this.reviewQuestions?.length;
          this.prService.currentPerformanceReviewQuestions = [...this.reviewQuestions];
          this.currentQuestion = this.getCurrentQuestion();
          this.currentQuestionDisplay = this.currentQuestion;
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  get progressBarStatus(): string {
    return `${(this.currentQuestion / (this.totalQuestions - 1)) * 100}%`;
  }

  get questionNotAnswered(): boolean {
    const tempQ: any = this.reviewQuestions[this.currentQuestion];
    let questionAnswered: boolean;

    if (!tempQ) {
      return true;
    } else if (tempQ.bt_responsetype === 'rating') {
      // rating
      questionAnswered = tempQ.bt_ratingfloat ? true : false;
    } else if (tempQ.bt_responsetype === 'freeText') {
      // response
      questionAnswered = tempQ.bt_response ? true : false;
    }

    return !questionAnswered;
  }

  getCurrentQuestion(): number {
    let unansweredQuestion: number = 0;
    for (const q of this.reviewProcess?.questions) {
      if (!q.bt_completed) {
        break;
      }
      unansweredQuestion++;
    }
    return unansweredQuestion < this.reviewProcess.questions.length ? unansweredQuestion : this.reviewProcess.questions.length - 1;
  }

  previousQuestion(): void {
    const temp = this.currentQuestion <= 0 ? 1 : this.currentQuestion;
    this.transition = true;
    setTimeout(() => {
      this.currentQuestion = temp - 1;
      this.currentQuestionDisplay = this.currentQuestion;
      this.transition = false;
    }, 300);
    this.currentQuestion--;
  }

  resetData(): void {
    this.reviewQuestions = [];
    this.reviewProcess = {};

    this.totalQuestions = 0;
    this.currentQuestionDisplay = 0;
    this.transition = false;
  }

  nextQuestion(): void {
    /*this.nextButtonClick.emit(true);*/

    const temp = this.currentQuestion >= this.totalQuestions ? this.totalQuestions : this.currentQuestion;

    this.transition = true;
    /*this.prService.currentPerformanceReviewQuestions = [...this.reviewQuestions];*/

    let questionPatchBody: any[] = [];
    if (this.currentQuestionPatch) {
      questionPatchBody.push({
        bt_PerformanceReviewDetailId: this.currentQuestionPatch.bt_PerformanceReviewDetailId,
        bt_ratingfloat: this.currentQuestionPatch.bt_ratingfloat,
        bt_completed: true,
      });

      this.storeEnhancedReviewDetailService.updateEnhancedReviewDetail({ patchEnhancedReviewDetailCommands: questionPatchBody[0] });
    }

    let reviewPatchBody: any[] = [];
    if (
      this.reviewProcess.questions[0].bt_detailtype === PerformanceReviewBtDetailType.Employee &&
      this.reviewProcess.performanceReviewSummaries.bt_employeereviewstatus !== PerformanceReviewBtPeerReviewStatus.InProgress
    ) {
      reviewPatchBody.push({
        bthr_employeeperformancereviewid: this.reviewProcess.performanceReviewSummaries.bthr_employeeperformancereviewid,
        bt_employeereviewstatus: PerformanceReviewBtPeerReviewStatus.InProgress,
      });
      this.storePerformanceReviewService.updatePerformanceReview({ patchPerformanceReviewsCommands: reviewPatchBody[0] });
    }
    if (
      this.reviewProcess.questions[0].bt_detailtype === PerformanceReviewBtDetailType.Manager &&
      this.reviewProcess.performanceReviewSummaries?.bt_managerreviewstatus !== PerformanceReviewBtPeerReviewStatus.InProgress
    ) {
      reviewPatchBody.push({
        bthr_employeeperformancereviewid: this.reviewProcess.performanceReviewSummaries.bthr_employeeperformancereviewid,
        bt_managerreviewstatus: PerformanceReviewBtPeerReviewStatus.InProgress,
      });
      this.storePerformanceReviewService.updatePerformanceReview({ patchPerformanceReviewsCommands: reviewPatchBody[0] });
    }

    setTimeout(() => {
      this.currentQuestion = temp + 1;
      this.currentQuestionDisplay = this.currentQuestion;
      this.transition = false;
    }, 300);
  }

  submitConfirmation(): void {
    this.modalService.open('submitReview');
    this.sidebarService.setShowSidebar(false);
  }

  submit(): void {
    this.reviewSubmit.emit(true);
    this.sidebarService.setShowSidebar(false);
  }

  setQuestionAnswer(index: number, newAnswer: any): void {
    if (this.reviewQuestions[index].bt_responsetype === PerformanceReviewBtResponseType.Rating) {
      this.transition = true;
      let obj = JSON.parse(JSON.stringify(this.reviewQuestions[index]));
      obj = { ...obj, bt_ratingfloat: newAnswer.bt_ratingfloat };
      /*this.reviewQuestions.splice(index, 1, obj)*/
      this.reviewQuestions[index] = obj;

      this.currentQuestionPatch = this.reviewQuestions[index];

      /*this.reviewQuestions[index].bt_ratingfloat = newAnswer.bt_ratingfloat;*/
    } else if (this.reviewQuestions[index].bt_responsetype === PerformanceReviewBtResponseType.FreeText) {
      this.reviewQuestions[index].bt_response = newAnswer.bt_response;
    }
    setTimeout(() => {
      this.transition = false;
    }, 300);
    this.optionSelected.emit(this.reviewQuestions);
  }
}
