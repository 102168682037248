import { Component, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { VerdeApprovalService } from '../../../services/verde-approval.service';

@Component({
  selector: 'verde-benchmark-view',
  templateUrl: './benchmark-view.component.html',
  styleUrls: ['./benchmark-view.component.scss'],
})
export class BenchmarkViewComponent implements OnInit {
  private onDestroy$ = new Subject<boolean>();
  selectedBenchmark: any;

  constructor(private sidebarService: VerdeApprovalService) {}

  ngOnInit(): void {
    this.sidebarService
      .getSelectedBenchmark()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((benchmark: any) => {
        this.selectedBenchmark = benchmark;
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
