import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PeerReviewFeedback, UserPeerReviewProcess, VerdeApprovalService } from '@verde/shared';

import { fadeInDownOnEnterAnimation, fadeOutUpOnLeaveAnimation } from 'angular-animations';
import { Subject, takeUntil } from 'rxjs';
import { PerformanceReviewService } from '../../../../../../../../../apps/verde/src/app/features/performance-reviews/services/performance-review.service';

@Component({
  selector: 'verde-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
  animations: [fadeInDownOnEnterAnimation({ anchor: 'enter', duration: 300, delay: 100 }), fadeOutUpOnLeaveAnimation({ anchor: 'leave', duration: 300 })],
})
export class ReviewComponent implements OnInit {
  private onDestroy$ = new Subject<boolean>();

  //@Input()
  //set setActiveReviewProcess(val: UserPeerReviewProcess) {
  //  this.reviewProcess = val;
  //  this.reviewQuestions = val.questions;
  //  this.totalQuestions = this.reviewQuestions.length;
  //  this.prService.currentPeerReviewQuestions = [...this.reviewQuestions];
  //}

  @Input()
  set setActiveReviewProcess(val: any) {
    if (val && val.questions) {
      this.reviewProcess = val;
      this.reviewQuestions = val.questions;
      this.totalQuestions = this.reviewQuestions?.length;
      this.prService.currentPerformanceReviewQuestions = [...this.reviewQuestions];
    }
  }

  @Input() currentQuestion: number = 0;

  @Output() nextButtonClick: EventEmitter<boolean> = new EventEmitter();
  @Output() reviewSubmit: EventEmitter<boolean> = new EventEmitter();
  @Output() optionSelected: EventEmitter<any[]> = new EventEmitter();

  reviewQuestions: any[] = [];
  reviewProcess: any = {};

  totalQuestions: number;
  currentQuestionDisplay: number = 0;
  transition: boolean = false;

  constructor(private prService: PerformanceReviewService, private sidebarService: VerdeApprovalService) {
    this.totalQuestions = this.reviewQuestions.length;
  }

  ngOnInit(): void {
    this.currentQuestionDisplay = this.currentQuestion;
    this.sidebarService
      .getSelectedActiveReview()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((review: any) => {
        if (review && review.questions) {
          this.reviewProcess = review;
          this.reviewQuestions = review.questions;
          this.totalQuestions = this.reviewQuestions?.length;
          this.prService.currentPerformanceReviewQuestions = [...this.reviewQuestions];
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  get progressBarStatus(): string {
    return `${(this.currentQuestion / (this.totalQuestions - 1)) * 100}%`;
  }

  get questionNotAnswered(): boolean {
    const tempQ = this.reviewQuestions[this.currentQuestion];
    let questionAnswered: boolean;

    if (!tempQ) {
      return true;
    } else if (tempQ.bt_responsetype === 'rating') {
      // rating
      questionAnswered = tempQ.bt_rating ? true : false;
    } else if (tempQ.bt_responsetype === 'freeText') {
      // response
      questionAnswered = tempQ.bt_response ? true : false;
    }

    return !questionAnswered;
    /*return !true;*/
  }

  previousQuestion(): void {
    const temp = this.currentQuestion <= 0 ? 1 : this.currentQuestion;
    this.transition = true;
    setTimeout(() => {
      this.currentQuestion = temp - 1;
      this.currentQuestionDisplay = this.currentQuestion;
      this.transition = false;
    }, 300);
    this.currentQuestion--;
  }

  nextQuestion(): void {
    this.nextButtonClick.emit(true);
    const temp = this.currentQuestion >= this.totalQuestions ? this.totalQuestions : this.currentQuestion;
    this.transition = true;
    this.prService.currentPeerReviewQuestions = [...this.reviewQuestions];
    setTimeout(() => {
      this.currentQuestion = temp + 1;
      this.currentQuestionDisplay = this.currentQuestion;
      this.transition = false;
    }, 300);
  }

  submit(): void {
    this.reviewSubmit.emit(true);
  }

  setQuestionAnswer(index: number, newAnswer: PeerReviewFeedback): void {
    if (this.reviewQuestions[index].odataResponseType === 'Rating') {
      this.reviewQuestions[index].bt_rating = newAnswer.bt_rating;
    } else if (this.reviewQuestions[index].odataResponseType === 'Free Text') {
      this.reviewQuestions[index].bt_response = newAnswer.bt_response;
    }

    this.optionSelected.emit(this.reviewQuestions);
  }
}
