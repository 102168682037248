<div
  class="main"
  *ngIf="
    (this.selectedNavigationOption == 'viewUpdate' || this.selectedNavigationOption == 'viewAdd' || this.selectedNavigationOption == 'viewRenew') &&
    this.createItemSidepanel == ''
  "
>
  <div class="scrollable-content">
    <form class="k-form sidepanel-form" [formGroup]="registerForm" style="height: -webkit-fill-available" (onChange)="onFormUpdate()" autocomplete="off">
      <kendo-gridlayout gap="2% 5%" [rows]="[]" style="height: -webkit-fill-available" [cols]="[]">
        <!--EDIT EMPLOYEE QUALIFICATION-->
        <!--<form [formGroup]="editEQForm" (ngSubmit)="editEmployeeEducationalQualificationForum()">-->

        <kendo-gridlayout-item
          *ngIf="currentRowCount != 0 && !(deviceTypeService.isMobile$ | async) && (viewRenewalFile == true || viewEQfile == true)"
          [col]="1"
          [colSpan]="4"
          [rowSpan]="14"
        >
          <div id="pdf-div"></div>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item [col]="currentRowCount + 1" [colSpan]="2">
          <kendo-gridlayout gap="2% 5%" [rows]="[]" style="height: -webkit-fill-available" [cols]="[]">
            <kendo-gridlayout-item [col]="1" [colSpan]="2">
              <kendo-formfield>
                <kendo-label [for]="eQQualificationType" text="Qualification Type:"></kendo-label>

                <kendo-dropdownlist
                  [ngModel]
                  formControlName="eQQualificationType"
                  #eQQualificationType
                  id="eQQualificationType"
                  [valuePrimitive]="true"
                  (valueChange)="eQQualificationTypeChanged()"
                  [data]="qualificationsBthrQualificationType"
                ></kendo-dropdownlist>
              </kendo-formfield>
            </kendo-gridlayout-item>

            <kendo-gridlayout-item [col]="1" [colSpan]="2">
              <kendo-formfield>
                <kendo-label [for]="eQQualification" text="Qualification:"></kendo-label>
                <kendo-dropdownlist
                  [filterable]="true"
                  (filterChange)="handleEQQualificationFilter($event)"
                  [data]="qualificationDisplay"
                  textField="bthr_name"
                  valueField="id"
                  (valueChange)="qualificationChanged()"
                  [ngModel]
                  formControlName="eQQualification"
                  #eQQualification
                  id="eQQualification"
                >
                  <ng-template kendoDropDownListNoDataTemplate>
                    <p>{{ eQQualificationDropdownMessage }}</p>
                  </ng-template>
                </kendo-dropdownlist>
              </kendo-formfield>
            </kendo-gridlayout-item>

            <kendo-gridlayout-item *ngIf="vieweQNewQualificationNgIf" [col]="1" [colSpan]="2">
              <kendo-formfield>
                <kendo-label [for]="eQNewQualificationName" text="New Qualification Name:"> </kendo-label>

                <kendo-textbox
                  [ngModel]
                  formControlName="eQNewQualificationName"
                  (valueChange)="runOnUpdate()"
                  #eQNewQualificationName
                  id="eQNewQualificationName"
                ></kendo-textbox>
              </kendo-formfield>
            </kendo-gridlayout-item>

            <kendo-gridlayout-item [col]="1" [colSpan]="2">
              <kendo-formfield>
                <kendo-label [for]="eQInstution" text="Institution:"></kendo-label>
                <kendo-dropdownlist
                  [filterable]="true"
                  (filterChange)="handleEQInstutionFilter($event)"
                  [data]="institutionDisplay"
                  textField="bthr_name"
                  valueField="bthr_id"
                  [ngModel]
                  formControlName="eQInstution"
                  (valueChange)="instutionChanged($event)"
                  #eQInstution
                  id="eQInstution"
                >
                  <ng-template kendoDropDownListNoDataTemplate>
                    <p>{{ eQInstutionDropdownMessage }}</p>
                  </ng-template>
                </kendo-dropdownlist>
              </kendo-formfield>
            </kendo-gridlayout-item>

            <kendo-gridlayout-item *ngIf="vieweQInstutionNgIf" [col]="1" [colSpan]="2">
              <kendo-formfield>
                <kendo-label [for]="eQNewInstitutionName" text="New Institution Name:"> </kendo-label>

                <kendo-textbox
                  [ngModel]
                  formControlName="eQNewInstitutionName"
                  (valueChange)="runOnUpdate()"
                  #eQNewInstitutionName
                  id="eQNewInstitutionName"
                ></kendo-textbox>
              </kendo-formfield>
            </kendo-gridlayout-item>

            <kendo-gridlayout-item *ngIf="vieweQInstutionNgIf" [col]="1" [colSpan]="2">
              <kendo-formfield>
                <kendo-label [for]="eQNewInstitutionURL" text="New Institution URL:"> </kendo-label>

                <kendo-textbox
                  [ngModel]
                  formControlName="eQNewInstitutionURL"
                  (valueChange)="runOnUpdate()"
                  #eQNewInstitutionURL
                  id="eQNewInstitutionURL"
                ></kendo-textbox>
              </kendo-formfield>
            </kendo-gridlayout-item>

            <kendo-gridlayout-item [col]="1" [colSpan]="1">
              <kendo-formfield>
                <kendo-label [for]="eQDateAttained" text="Date Attained:"></kendo-label>
                <kendo-datepicker
                  format="yyyy/MM/dd"
                  [disabledDates]="disabledDates"
                  (valueChange)="dateAttainedChanged()"
                  [ngModel]
                  formControlName="eQDateAttained"
                  #eQDateAttained
                  id="eQDateAttained"
                >
                </kendo-datepicker>
              </kendo-formfield>
            </kendo-gridlayout-item>

            <kendo-gridlayout-item *ngIf="eQExpiryDateNgIf" [col]="2" [colSpan]="1">
              <kendo-formfield>
                <kendo-label [for]="eQExpiryDate" text="Expiry Date:"></kendo-label>
                <kendo-datepicker format="yyyy/MM/dd" [readonly]="true" [ngModel] formControlName="eQExpiryDate" #eQExpiryDate id="eQExpiryDate">
                </kendo-datepicker>
              </kendo-formfield>
            </kendo-gridlayout-item>

            <kendo-gridlayout-item *ngIf="eQLevelNgIf" [col]="eQLevelSide" [colSpan]="1">
              <kendo-formfield>
                <kendo-label [for]="eQLevel" text="Level:"></kendo-label>
                <kendo-textbox [ngModel] formControlName="eQLevel" #eQLevelid id="eQLevel" (onChange)="eQLevelQPointsSide()" [readonly]="true"></kendo-textbox>
              </kendo-formfield>
            </kendo-gridlayout-item>

            <kendo-gridlayout-item *ngIf="eQPointsNgIf" [col]="eQPointsSide" [colSpan]="1">
              <kendo-formfield>
                <kendo-label text="Points:"></kendo-label>

                <kendo-textbox [ngModel] formControlName="eQPoints" #eQPoints id="eQPoints" (onChange)="eQLevelQPointsSide()" [readonly]="true"></kendo-textbox>
              </kendo-formfield>
            </kendo-gridlayout-item>

            <kendo-gridlayout-item *ngIf="!viewRenewNgIf" [col]="1" [colSpan]="2">
              <kendo-formfield>
                <kendo-label [for]="eQfile" text="Proof of qualification:">
                  <a *ngIf="viewEQfile" href="javascript:void(0);" (click)="downloadPdf()" style="color: blue"> &nbsp;&nbsp; Download<!--{{pdfName}}--> </a>
                </kendo-label>

                <kendo-fileselect
                  [restrictions]="myRestrictions"
                  accept=".pdf"
                  [ngModel]
                  (valueChange)="eQfileChanged()"
                  formControlName="eQfile"
                  #eQfile
                  id="eQfile"
                  [multiple]="false"
                ></kendo-fileselect>
              </kendo-formfield>
            </kendo-gridlayout-item>

            <!--<kendo-gridlayout-item *ngIf="!viewRenewNgIf" [row]="8"  [col]="1" [colSpan]="2">
  <kendo-formfield>
    <kendo-label [for]="eQfile" text="File Upload"></kendo-label>
    <kendo-fileselect eQfile
                      formControlName="eQfile"
                      [multiple]="false"
                      [restrictions]="restrictions">
    </kendo-fileselect>
    <kendo-formhint>Allowed extension is pdf</kendo-formhint>
  </kendo-formfield>
  </kendo-gridlayout-item>-->

            <kendo-gridlayout-item *ngIf="!viewRenewNgIf" [col]="1" [colSpan]="2">
              <kendo-formfield>
                <kendo-label text="Validation URL:"> </kendo-label>

                <kendo-textbox [ngModel] formControlName="eQValidationURL" #eQValidationURL (valueChange)="runOnUpdate()" id="eQValidationURL"></kendo-textbox>
              </kendo-formfield>
            </kendo-gridlayout-item>

            <kendo-gridlayout-item *ngIf="viewRenewNgIf" [col]="1" [colSpan]="1">
              <kendo-formfield>
                <kendo-label [for]="renewalDate" text="Renewal Date:"></kendo-label>
                <kendo-datepicker format="yyyy/MM/dd" [ngModel] formControlName="renewalDate" #renewalDate (valueChange)="runOnUpdate()" id="renewalDate">
                </kendo-datepicker>
              </kendo-formfield>
            </kendo-gridlayout-item>

            <kendo-gridlayout-item *ngIf="viewRenewNgIf" [col]="1" [colSpan]="2">
              <kendo-formfield>
                <kendo-label [for]="renewalFile" text="Renewal File:">
                  <a *ngIf="viewRenewalFile" href="javascript:void(0);" (click)="downloadRenewalPdf()" style="color: blue">
                    &nbsp;&nbsp; Download<!--{{pdfName}}-->
                  </a>
                </kendo-label>

                <kendo-fileselect
                  [restrictions]="myRestrictions"
                  accept=".pdf"
                  [ngModel]
                  (valueChange)="renewalFileChanged($event)"
                  formControlName="renewalFile"
                  #renewalFile
                  id="renewalFile"
                  [multiple]="false"
                ></kendo-fileselect>
              </kendo-formfield>
            </kendo-gridlayout-item>

            <kendo-gridlayout-item *ngIf="viewRenewNgIf" [col]="1" [colSpan]="2">
              <kendo-formfield>
                <kendo-label text="Renewal URL:"></kendo-label>

                <kendo-textbox
                  [ngModel]
                  formControlName="renewalValidationURL"
                  #renewalValidationURL
                  (valueChange)="runOnUpdate()"
                  id="renewalValidationURL"
                ></kendo-textbox>
              </kendo-formfield>
            </kendo-gridlayout-item>

            <kendo-gridlayout-item *ngIf="viewRenewNgIf" [col]="1" [colSpan]="2" style="height: -webkit-fill-available"> </kendo-gridlayout-item>
          </kendo-gridlayout>
        </kendo-gridlayout-item>

        <!------------------------>
      </kendo-gridlayout>
    </form>
  </div>

  <div #appendTo class="fixed-buttons">
    <span class="k-form-separator"></span>
    <br />
    <div class="k-form-buttons k-buttons-end">
      <div>
        <!--[disabled]="!validateForum()"-->
        <button kendoButton [disabled]="submitButon" themeColor="primary" (click)="submitConfirmed()">
          {{ qualificationButtonText }}
        </button>
      </div>
    </div>
  </div>
</div>

<!--</form>-->
<!--ADD QUALIFICATION-->
<!--<div *ngIf="this.createItemSidepanel == 'qualification'">
  <br />
  <label>Add Qualification:</label>
  <br />
  <label>Qualification Name:</label>
  <br />
  <kendo-textbox [(ngModel)]="addQQualificationName"></kendo-textbox>
  <label>Type:</label>
  <br />
  <kendo-dropdownlist [(ngModel)]="addQType" [data]="this.qualificationsBthrQualificationType"></kendo-dropdownlist>
  <label>Does It Expire:</label>
  <br />
  &nbsp; <input [(ngModel)]="addQDoesItExpire" class="form-check-input"
                kendoCheckBox
                type="checkbox"
                style="height: 15px; width: 15px" />
  <br />

  <div *ngIf="addQDoesItExpire == true">
    <label>Expire After (Days):</label>
    <br />
    <kendo-textbox [(ngModel)]="addQWhen"></kendo-textbox>
  </div>

  <label>NQF Level (if applicable):</label>
  <br />
  <kendo-textbox [(ngModel)]="addQLevel"></kendo-textbox>
  <label>Points  (if applicable):</label>
  <br />
  <kendo-textbox [(ngModel)]="addQPoints"></kendo-textbox>
  <br />
  <br />
  <button #add-button" style="position: absolute; bottom: 20px; right: 110px; z-index: 1; " kendoButton themeColor="primary" (click)="closeAdd()">Close</button>
  <button #add-button" style="position: absolute; bottom: 20px; right: 20px; z-index: 1; " kendoButton themeColor="primary" (click)="createQualification()" [disabled]="(this.addQQualificationName != null && this.addQQualificationName != '' && this.addQType != null) == false">Save</button>

</div>-->
<!--ADD INSTITUTION-->
<!--<div *ngIf="this.createItemSidepanel == 'institution'">
  <br />
  <label>Add Institution:</label>
  <br />
  <label>Name:</label>
  <br />
  <kendo-textbox [(ngModel)]="addIName"></kendo-textbox>
  <label>Type:</label>
  <br />
  <kendo-dropdownlist [(ngModel)]="addIType"
                      [data]="institutiontype"></kendo-dropdownlist>
  <label>Country:</label>
  <br />
  <kendo-dropdownlist [filterable]="true"
                      (filterChange)="handleCountryFilter($event)"
                      [data]="country"
                      textField="bthr_country"
                      valueField="bthr_Countryid"
                      (valueChange)="enableAddICountry()"
                      [(ngModel)]="addICountry"></kendo-dropdownlist>
  <label>Accreditation Authority:</label>
  <br />
  <kendo-dropdownlist [(ngModel)]="addIAccreditationAuthority"
                      [data]="accreditationAuthority"
                      textField="bthr_accreditationauthority"
                      valueField="bthr_AccreditationAuthorityid"></kendo-dropdownlist>


  <button #add-button" style="position: absolute; bottom: 20px; right: 110px; z-index: 1; " kendoButton themeColor="primary" (click)="closeAdd()">Close</button>
  <button #add-button" style="position: absolute; bottom: 20px; right: 20px; z-index: 1; " kendoButton themeColor="primary" (click)="createInstitution()" [disabled]="(this.addIName != null && this.addIType != null && this.addICountry != null && this.addIAccreditationAuthority != null) == false">Save</button>
  <br />
</div>-->
<!--ADD RENEWAL-->
<!--<div *ngIf="this.selectedNavigationOption == 'viewRenew' && this.createItemSidepanel == ''">
  RENEWALS IS IN PROGRESS
</div>-->
<!--NOTIFICATION-->
<!--<div *ngIf="notificationActive == true" style="position: absolute; bottom: 20px; right: 50%">
  {{ notificationMessage }}
</div>-->
<!-- #region Confirmation Modal -->
<!--<kendo-dialog *ngIf="notificationActive == true"
              title="{{ notificationTitle }}"
              verdeResponsiveDialog>
  <div style="height: 60px">
    <p>
      {{ notificationMessage }}
    </p>
  </div>
  <kendo-dialog-actions>
    <button kendoButton
            themeColor="primary"
            style="margin-left: auto"
            (click)="closeNotification()">
      {{ notificationButton }}
    </button>
  </kendo-dialog-actions>
</kendo-dialog>-->
<!-- #endregion Confirmation Modal -->
<!--ADD QUALIFICATION-->
<!--<div *ngIf="this.createItemSidepanel == 'qualification'">
  <br />
  <label>Add Qualification:</label>
  <br />
  <label>Qualification Name:</label>
  <br />
  <kendo-textbox [(ngModel)]="addQQualificationName"></kendo-textbox>
  <label>Type:</label>
  <br />
  <kendo-dropdownlist [(ngModel)]="addQType" [data]="this.qualificationsBthrQualificationType"></kendo-dropdownlist>
  <label>Does It Expire:</label>
  <br />
  &nbsp; <input [(ngModel)]="addQDoesItExpire" class="form-check-input"
                kendoCheckBox
                type="checkbox"
                style="height: 15px; width: 15px" />
  <br />

  <div *ngIf="addQDoesItExpire == true">
    <label>Expire After (Days):</label>
    <br />
    <kendo-textbox [(ngModel)]="addQWhen"></kendo-textbox>
  </div>

  <label>NQF Level (if applicable):</label>
  <br />
  <kendo-textbox [(ngModel)]="addQLevel"></kendo-textbox>
  <label>Points  (if applicable):</label>
  <br />
  <kendo-textbox [(ngModel)]="addQPoints"></kendo-textbox>
  <br />
  <br />
  <button #add-button" style="position: absolute; bottom: 20px; right: 110px; z-index: 1; " kendoButton themeColor="primary" (click)="closeAdd()">Close</button>
  <button #add-button" style="position: absolute; bottom: 20px; right: 20px; z-index: 1; " kendoButton themeColor="primary" (click)="createQualification()" [disabled]="(this.addQQualificationName != null && this.addQQualificationName != '' && this.addQType != null) == false">Save</button>

</div>-->
<!--ADD INSTITUTION-->
<!--<div *ngIf="this.createItemSidepanel == 'institution'">
  <br />
  <label>Add Institution:</label>
  <br />
  <label>Name:</label>
  <br />
  <kendo-textbox [(ngModel)]="addIName"></kendo-textbox>
  <label>Type:</label>
  <br />
  <kendo-dropdownlist [(ngModel)]="addIType"
                      [data]="institutiontype"></kendo-dropdownlist>
  <label>Country:</label>
  <br />
  <kendo-dropdownlist [filterable]="true"
                      (filterChange)="handleCountryFilter($event)"
                      [data]="country"
                      textField="bthr_country"
                      valueField="bthr_Countryid"
                      (valueChange)="enableAddICountry()"
                      [(ngModel)]="addICountry"></kendo-dropdownlist>
  <label>Accreditation Authority:</label>
  <br />
  <kendo-dropdownlist [(ngModel)]="addIAccreditationAuthority"
                      [data]="accreditationAuthority"
                      textField="bthr_accreditationauthority"
                      valueField="bthr_AccreditationAuthorityid"></kendo-dropdownlist>


  <button #add-button" style="position: absolute; bottom: 20px; right: 110px; z-index: 1; " kendoButton themeColor="primary" (click)="closeAdd()">Close</button>
  <button #add-button" style="position: absolute; bottom: 20px; right: 20px; z-index: 1; " kendoButton themeColor="primary" (click)="createInstitution()" [disabled]="(this.addIName != null && this.addIType != null && this.addICountry != null && this.addIAccreditationAuthority != null) == false">Save</button>
  <br />
</div>-->
<!--ADD RENEWAL-->
<!--<div *ngIf="this.selectedNavigationOption == 'viewRenew' && this.createItemSidepanel == ''">
  RENEWALS IS IN PROGRESS
</div>-->
<!--NOTIFICATION-->
<!--<div *ngIf="notificationActive == true" style="position: absolute; bottom: 20px; right: 50%">
  {{ notificationMessage }}
</div>-->
<!-- #region Confirmation Modal -->
<!--<kendo-dialog *ngIf="notificationActive == true"
              title="{{ notificationTitle }}"
              verdeResponsiveDialog>
  <div style="height: 60px">
    <p>
      {{ notificationMessage }}
    </p>
  </div>
  <kendo-dialog-actions>
    <button kendoButton
            themeColor="primary"
            style="margin-left: auto"
            (click)="closeNotification()">
      {{ notificationButton }}
    </button>
  </kendo-dialog-actions>
</kendo-dialog>-->
<!-- #endregion Confirmation Modal -->
<!-- #endregion Confirmation Modal -->
<!--<table>
  <tr>
    <th style="width: 190% ">
      <kendo-dropdownlist [filterable]="true"
                          (filterChange)="handleEditEQQualificationFilter($event)"
                          [data]="link"
                          textField="qualification.odata"
                          valueField="qualification.id"
                          (valueChange)="enableEditAccreditationAuthority()"
                          [(ngModel)]="editEQQualification"></kendo-dropdownlist>
    </th>
    <th style="width: 10%; padding-left: 12px">
      <verde-dots-menu-button (menuClicked)="enableEditQualificationPanel()"></verde-dots-menu-button>
    </th>
  </tr>
</table>-->
<!--<kendo-dropdownlist [filterable]="true"
                    (filterChange)="handleEditEQQualificationFilter($event)"
                    [data]="link"
                    textField="qualification.odata"
                    valueField="qualification.id"
                    (valueChange)="enableEditAccreditationAuthority()"
                    [(ngModel)]="editEQQualification">
  <ng-template kendoDropDownListNoDataTemplate>
    <p>{{ editEQQualificationDropdownMessage }}</p>
  </ng-template>
</kendo-dropdownlist>
<br />-->
<!--<table>
  <tr>
    <th *ngIf="editEQQualificationPermissions" style="width: 190% ">
      <kendo-dropdownlist [filterable]="true"
                          (filterChange)="handleEditEQQualificationFilter($event)"
                          [data]="qualification"
                          textField="bthr_name"
                          valueField="id"
                          (valueChange)="enableEditAccreditationAuthority()"
                          [(ngModel)]="editEQQualification"
                          [disabled]="editEQQualificationType == null">
        <ng-template kendoDropDownListNoDataTemplate>
          <p>{{ editEQQualificationDropdownMessage }}</p>
        </ng-template>
      </kendo-dropdownlist>
    </th>

    <th *ngIf="!editEQQualificationPermissions" style="width: 200% ">
      <kendo-dropdownlist [filterable]="true"
                          (filterChange)="handleEditEQQualificationFilter($event)"
                          [data]="qualification"
                          textField="bthr_name"
                          valueField="id"
                          (valueChange)="enableEditAccreditationAuthority()"
                          [(ngModel)]="editEQQualification"
                          [disabled]="editEQQualificationType == null">
        <ng-template kendoDropDownListNoDataTemplate>
          <p>{{ editEQQualificationDropdownMessage }}</p>
        </ng-template>
      </kendo-dropdownlist>
    </th>
    <th *ngIf="editEQQualificationPermissions" style="width: 10%; padding-left: 12px">
      <verde-dots-menu-button (menuClicked)="enableEditQualificationPanel()"></verde-dots-menu-button>
    </th>
  </tr>
</table>-->
<!--<table style="width:inherit">
  <tr>
    <th style="width: 190% ">
      <kendo-dropdownlist [filterable]="true"
                          (filterChange)="handleEditeQInstutionFilter($event)"
                          [data]="institution"
                          textField="bthr_name"
                          valueField="bthr_id"
                          (valueChange)="dropdownUpdateEDIT()"
                          [(ngModel)]="editEQInstution"></kendo-dropdownlist>
    </th>
    <th style="width: 10%; padding-left: 12px">
      <verde-dots-menu-button (menuClicked)="enableEditInstitutionPanel()"></verde-dots-menu-button>
    </th>
  </tr>
</table>-->
<!--<kendo-dropdownlist [filterable]="true"
                    (filterChange)="handleEditEQInstutionFilter($event)"
                    [data]="institution"
                    textField="bthr_name"
                    valueField="bthr_id"
                    (valueChange)="dropdownUpdateEDIT()"
                    [(ngModel)]="editEQInstution">
  <ng-template kendoDropDownListNoDataTemplate>
    <p>{{ eQInstutionDropdownMessage }}</p>
  </ng-template>
</kendo-dropdownlist>-->
<!--<table style="width:inherit">
<tr>
  <th style="width: 190% ">
    <kendo-dropdownlist [filterable]="true"
                        (filterChange)="handleEditEQInstutionFilter($event)"
                        [data]="institution"
                        textField="bthr_name"
                        valueField="bthr_id"
                        [(ngModel)]="editEQInstution">
      <ng-template kendoDropDownListNoDataTemplate>
        <p>{{ eQInstutionDropdownMessage }}</p>
      </ng-template>
    </kendo-dropdownlist>-->
<!--(valueChange)="dropdownUpdateEDIT()"-->
<!--</th>
    <th style="width: 10%; padding-left: 12px">
      <verde-dots-menu-button (menuClicked)="enableEditInstitutionPanel()"></verde-dots-menu-button>
    </th>
  </tr>
</table>-->
