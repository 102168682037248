<kendo-tabstrip #financialDocumentTabstrip (tabSelect)="onTabSelect($event)" class="Financialdocument_tabstrip">
  <kendo-tabstrip-tab title="Personal Tax" [selected]="true">
    <ng-template kendoTabContent>
      <verde-personal-tax-documents></verde-personal-tax-documents>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab title="Other">
    <ng-template kendoTabContent>
      <verde-other-documents></verde-other-documents>
    </ng-template>
  </kendo-tabstrip-tab>
</kendo-tabstrip>
