import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyJsonschema } from '@ngx-formly/core/json-schema';
import { VerdeApprovalService } from '@verde/shared';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'verde-personal-details',
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.scss'],
})
export class PersonalDetailsComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<boolean>();
  columnOneForm: FormGroup;
  columnOneFormfields: FormlyFieldConfig[];
  columnOneModel: any;

  constructor(private sidebarService: VerdeApprovalService, private formlyJsonschema: FormlyJsonschema) {}

  ngOnInit(): void {
    this.sidebarService
      .getSelectedDynamicFormJson()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((json: string) => {
        console.log(json);
        this.loadJson(json);
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  loadJson(myJson: string) {
    const schemaObject = JSON.parse(myJson);

    this.columnOneForm = new FormGroup({});
    this.columnOneFormfields = [this.formlyJsonschema.toFieldConfig(schemaObject.form)];
    this.columnOneModel = schemaObject.columnOneModel;
  }

  submit() {
    console.log('submit');
  }
}
