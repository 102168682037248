<br />
<form #fleetMovementCancellationForm="ngForm" novalidate autocomplete="off" style="position: relative">
  <div class="modal_grid">
    <div class="form-group">
      <label for="confirmModel">Make - Model:</label>
      <kendo-textbox [readonly]="true" id="confirmModel" name="confirmModel" [ngModel]="selectedFleetItem?.odataModel"> </kendo-textbox>
    </div>

    <div class="form-group">
      <label for="confirmLicenseNo">License No:</label>
      <kendo-textbox [readonly]="true" id="confirmLicenseNo" name="confirmLicenseNo" [ngModel]="selectedFleetItem?.bthr_licensenumber"> </kendo-textbox>
    </div>

    <div class="form-group">
      <label for="confirmReturnLocation">Return To:</label>
      <kendo-textbox [readonly]="true" id="confirmReturnLocation" name="confirmReturnLocation" [ngModel]="selectedFleetItem?.odataNewLocation"> </kendo-textbox>
    </div>

    <div class="form-group">
      <label for="cancellationtype">Cancellation Type:</label>
      <kendo-dropdownlist
        [data]="allCancellationReasons"
        textField="fleetMovementCancellationTypeName"
        valueField="fleetMovementCancellationTypeID"
        id="cancellationtype"
        name="cancellationtype"
        required
        [ngModel]
        #varCancellationReason="ngModel"
      >
      </kendo-dropdownlist>

      <div class="alert alert-danger" *ngIf="varCancellationReason.touched && !varCancellationReason.valid">
        <div *ngIf="varCancellationReason.errors?.required">Field is required!</div>
      </div>
    </div>

    <div class="form-group wide">
      <label for="cancellationReason">Reason for Cancellation:</label>
      <kendo-textarea
        maxlength="300"
        id="cancellationReason"
        name="cancellationReason"
        required
        #varCancellationReason="ngModel"
        resizable="vertical"
        [ngModel]
      >
      </kendo-textarea>

      <!--Validation-->
      <div class="alert alert-danger" *ngIf="varCancellationReason.touched && !varCancellationReason.valid">
        <div *ngIf="varCancellationReason.errors?.required">Field is required!</div>
        <div *ngIf="varCancellationReason.errors?.minlength">Enter a detailed reason.</div>
      </div>
    </div>
  </div>
</form>
<button
  style="position: absolute; bottom: 20px; right: 20px"
  kendoButton
  themeColor="primary"
  (click)="postThisThing(fleetMovementCancellationForm)"
  [disabled]="fleetMovementCancellationForm.invalid"
>
  Submit
</button>

<!-- #region Confirmation Modal -->
<kendo-dialog *ngIf="showConfirmationModal" title="Confirmation" verdeResponsiveDialog>
  <div style="height: 60px">
    <p>The return to location request has been submitted.</p>
  </div>
  <kendo-dialog-actions>
    <button kendoButton themeColor="primary" style="margin-left: auto" (click)="closeConfirmationModal()">Continue</button>
  </kendo-dialog-actions>
</kendo-dialog>
<!-- #endregion Confirmation Modal -->
