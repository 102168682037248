import { Injectable } from '@angular/core';
import {
  DocumentApiDocumentService,
  DocumentGetDocSalaryAdviceDetailQuery,
  DocumentGetDocSalaryAdviceHeaderQuery,
  DocumentGetDocTypeFinancialQuery,
  DocumentGetTaxYearAndPeriodQuery,
  DocumentPostBtPayrollmportDetailCommand,
  SharedApiFileService,
  SharedApiVerdeDocumentsService,
  UserUserDto,
  VerdeManualDocuments,
} from '@verde/api';
import { UserService } from '@verde/core';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ManualDocumentsDataService {
  onDestroy$ = new Subject<boolean>();

  allManualDocuments: Subject<VerdeManualDocuments[]> = new Subject<VerdeManualDocuments[]>();
  manualDocumentFile: Subject<string> = new Subject<string>();
  manualDocumentFileUserUpload: Subject<string> = new Subject<string>();

  private userUpdated = new Subject<UserUserDto>();

  constructor(
    private userService: UserService,
    private sharedApiFileService: SharedApiFileService,
    private documentApiDocumentService: DocumentApiDocumentService,
    private sharedapiverdedocumentsservice: SharedApiVerdeDocumentsService,
  ) {}


  applyNewProxyUser(user: UserUserDto) {
    this.userUpdated.next(user);
  }

  getUserUpdates() {
    return this.userUpdated.asObservable();
  }

  getVerdeManualDocuments(
    userID: string = this.userService.user?.employeeId ?? '',
    legalEntityID: string = this.userService.user?.legalEntityId ?? '',
  ): Observable<VerdeManualDocuments[]> {
    if (userID != '' && legalEntityID !== '') {
      return this.sharedapiverdedocumentsservice.getVerdeManualDocuments({ userID, legalEntityID }).pipe(
        takeUntil(this.onDestroy$),
        tap((docs) => {
          if (docs.length > 0) {
            let newDocs: VerdeManualDocuments[] = [];

            docs.forEach((t) => {
              if (t?.bt_VerdeManualDocumentsControl?.bt_createtask) {
                newDocs.push(t);
              }
            });

            if (newDocs.length > 0) {
              let singleDoc: Array<VerdeManualDocuments> = new Array<VerdeManualDocuments>();

              singleDoc.push(newDocs[0]);

              this.allManualDocuments.next(singleDoc);

              if (singleDoc[0]?._bt_verdemanualdocumentscontrol_value) {
                this.getVerdeManualDocumentFile(singleDoc[0]._bt_verdemanualdocumentscontrol_value).subscribe();
              }
            }
          }
        }),
      );
    } else {
      throw new Error('getVerdeManualDocuments: userID or legalEntityID not defined');
    }
  }

  getVerdeManualDocumentFile(recordid: string): Observable<string> {
    this.sharedApiFileService
      .getVerdeManualDocument({ recordid })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          this.manualDocumentFile.next(ret);
        },
        (error) => {
          console.error(error);
        },
        () => {},
      );

    return this.manualDocumentFile;
  }

  getVerdeManualDocumentFileUserUpload(recordid: string): Observable<string> {
    this.sharedApiFileService
      .getVerdeManualDocumentUserUpload({ recordid })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          this.manualDocumentFileUserUpload.next(ret);
        },
        (error) => {
          console.error(error);
        },
        () => {},
      );

    return this.manualDocumentFileUserUpload;
  }

  // Dewans APIs
  getDocSalaryAdviceHeader(body: DocumentGetDocSalaryAdviceHeaderQuery): void {
    this.documentApiDocumentService
      .getDocSalaryAdviceHeader({ body })
      .pipe(take(1))
      .subscribe(
        () => {
          //
        },
        (error) => {
          console.error(error);
        },
        () => {},
      );
  }

  getDocSalaryAdviceDetail(body: DocumentGetDocSalaryAdviceDetailQuery): void {
    this.documentApiDocumentService
      .getDocSalaryAdviceDetail({ body })
      .pipe(take(1))
      .subscribe(
        () => {
          //
        },
        (error) => {
          console.error(error);
        },
        () => {},
      );
  }

  getDocTypeFinancial(body: DocumentGetDocTypeFinancialQuery): void {
    this.documentApiDocumentService
      .getDocTypeFinancial({ body })
      .pipe(take(1))
      .subscribe(
        () => {
          //
        },
        (error) => {
          console.error(error);
        },
        () => {},
      );
  }

  getTaxYearAndPeriod(body: DocumentGetTaxYearAndPeriodQuery): void {
    this.documentApiDocumentService
      .getTaxYearAndPeriod({ body })
      .pipe(take(1))
      .subscribe(
        () => {
          //
        },
        (error) => {
          console.error(error);
        },
        () => {},
      );
  }

  postBtPayrollmportDetail(body: DocumentPostBtPayrollmportDetailCommand): void {
    this.documentApiDocumentService
      .postBtPayrollmportDetail({ body })
      .pipe(take(1))
      .subscribe(
        () => {
          //
        },
        (error) => {
          console.error(error);
        },
        () => {},
      );
  }
}
