import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { FormlyJsonschema } from '@ngx-formly/core/json-schema';
import {
  CommonApiWebDynamicService,
  ModalService,
  WebDynamicJSService,
  VerdeApprovalService,
  WebDynamicService,
  WebDynamicFormType,
  WebDynamicFormMode,
  WebDynamicModel,
} from '@verde/shared';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'verde-web-dynamic-frontPage',
  templateUrl: './web-dynamic-frontPage.component.html',
  styleUrls: ['./web-dynamic-frontPage.component.scss'],
})
export class WebDynamicFrontPageComponent implements OnInit, OnDestroy {
  private onDestroyFrontPage$ = new Subject<boolean>();
  // formModel: WebDynamicModel;
  WebDynamicFormType: typeof WebDynamicFormType = WebDynamicFormType;
  WebDynamicFormMode: typeof WebDynamicFormMode = WebDynamicFormMode;
  // dynamicFormGroupFrontPage: FormGroup;
  // dynamicModelFrontPage: JSON;
  // additionalFrontPage: any;
  // optionsFrontPage: FormlyFormOptions;
  // entityIdFrontPage: string;
  // formNameFrontPage: string;
  // dynamicFormfieldsFrontPage: FormlyFieldConfig[];
  // //Test
  // formFieldsFrontPage: FormlyFieldConfig[] = []; // Define a variable to hold Formly field configurations
  // entityNameFrontPage: string;
  // versionFrontPage: string;
  // formTypeFrontPage: string;
  // dataFrontPage: [] = [];
  // jsonDataFrontPage: JSON;
  // getEntityIdValueFrontPage: string = '';
  // getFormNameValueFrontPage: string = '';
  getFormTypeValueFrontPage: WebDynamicFormType;
  // //Spinner
  // disableAnimationFrontPage: boolean;
  // //JSON variables
  // schemaJSONFrontPage: any;
  // schemaGlobalFrontPage: any; //  schemaGlobal: any[];//--------------------------------------------------------------------------------------------
  // modelJSONFrontPage: any;
  // jsonDynamicAdditionalDataFrontPage: any;
  // globalDataTypeListFrontPage: { [key: string]: { [key: string]: string } } = {};
  // //Notification
  // notificationMessageFrontPage: string = '';
  // notificationActiveFrontPage: boolean = false;
  // notificationTitleFrontPage: string = '';
  // notificationButtonFrontPage: string = '';
  // //Negate Request Data Asynch
  // modelDataRequestCompletedFrontPage: Boolean = false;
  // lookupDataRequestCompletedFrontPage: Boolean = false;
  // notification(title: string, message: string, button?: string) {
  //   this.notificationMessageFrontPage = message;
  //   this.notificationTitleFrontPage = title;
  //   this.notificationButtonFrontPage = button == null ? 'Continue' : button;
  //   this.notificationActiveFrontPage = true;
  // }
  // closeNotification() {
  //   this.notificationActiveFrontPage = false;
  // }
  // constructor(
  //   private formlyJsonschema: FormlyJsonschema,
  //   private http: HttpClient,
  //   private commonApiCommonService: CommonApiWebDynamicService,
  //   private route: ActivatedRoute,
  //   private webDynamicService: WebDynamicService,
  //   private scriptLoaderService: WebDynamicJSService,
  //   private spinner: NgxSpinnerService,
  //   private sidebarService: VerdeApprovalService,
  //   private modalService: ModalService,
  // ) {
  //   // Gets the Entity Id Value from where this page is called
  //   this.webDynamicService.webDynamicModel$.pipe(takeUntil(this.onDestroyFrontPage$)).subscribe((formModel) => {
  //     if (formModel) {
  //       const hasEntityId = (formModel.entityId ?? '') !== '';
  //       const hasFormFile = (formModel.formFile ?? '') !== '';
  //       if (hasEntityId && hasFormFile) {
  //         this.formModel = formModel;
  //         this.getEntityIdValueFrontPage = this.formModel.entityId;
  //         this.getFormNameValueFrontPage = this.formModel.formFile;
  //         this.getFormTypeValueFrontPage = this.formModel.formType;
  //         //this.loadDynamicFormScript();
  //         this.loadPage();
  //       } else {
  //         this.notificationMessageFrontPage = 'Unable to load the dynamic page: EntityId or FormName is not provided';
  //         this.modalService.open('errorModal');
  //       }
  //     }
  //   });
  //   // Gets the form names value from where this page is called
  //   //if ((this.getEntityIdValue ?? "") != "" || (this.formName ?? "") != "") {
  //   //  //this.loadDynamicFormScript();
  //   //  this.loadPage();
  //   //} else {
  //   //  this.notification("Error", "Unable to load the dynamic page: EntityId or FormName is not provided", "Continue");
  //   //}
  // }
  ngOnInit(): void {}
  // loadDynamicFormScript() {
  //   ////This loads the JS file
  //   //this.scriptLoaderService
  //   //  .loadScript('btvsp_PositionSeatRules', 'assets/js/dynamic-forms/btvsp_PositionSeatRules.js')
  //   //  .then((data) => {
  //   //    this.loadPage();
  //   //    console.log('JavascriptLoaded', data);
  //   //  })
  //   //  .catch((error) => console.error('Script loading error:', error));
  // }
  ngOnDestroy(): void {
    this.onDestroyFrontPage$.next(true);
    this.onDestroyFrontPage$.complete();
  }
  // closeVerdeDynamicModal() {
  //   if (this.getFormTypeValueFrontPage === WebDynamicFormType.SIDEPANEL) {
  //     this.sidebarService.setShowSidebar(false);
  //   }
  // }
  // loadPage() {
  //   var jsonDynamicModelData: JSON;
  //   var filteredSchema;
  //   //this.spinner.show('dynamicFormSpinner');
  //   if (
  //     (this.getFormNameValueFrontPage ?? '') != '' &&
  //     this.getFormNameValueFrontPage != '' &&
  //     this.getFormNameValueFrontPage != null &&
  //     this.getFormNameValueFrontPage != undefined
  //   ) {
  //     //Loads JSON data for the form layout and gets any other requred data from it
  //     this.http
  //       .get<any>('/assets/json/dynamic-forms/' + this.getFormNameValueFrontPage + '.json')
  //       .pipe(
  //         tap(({ tabs }) => {
  //           if ((tabs ?? '') != '') {
  //             var tabsVar = { ...tabs } as Object;
  //             // This code underneath loads the first tab from the JSON and is used for testing (i.e. if data isn't available from the database)
  //             //this.renderForm(tabsVar["tab_1"]?.schema, tabsVar["tab_1"]?.model, tabsVar["tab_1"]?.additional);
  //             // loops through tabs
  //             for (let tabLoop in { ...tabsVar }) {
  //               var jsonDynamicModelData: JSON;
  //               var filteredSchema;
  //               if (this.getFormTypeValueFrontPage === WebDynamicFormType.SIDEPANEL) {
  //                 this.sidebarService.setTitleTag({ ...tabsVar }[tabLoop.toString()]['schema']['title']?.toString());
  //               }
  //               tabsVar[tabLoop.toString()].schema.title = null;
  //               this.renderForm(tabsVar[tabLoop.toString()].schema, tabsVar[tabLoop.toString()].model, tabsVar[tabLoop.toString()].additional);
  //               //this.renderForm(schema, {}, additional) //--------------------------------------------
  //               this.globalDataTypeListFrontPage[tabLoop.toString()] = null;
  //               var dataTypeList: { [key: string]: string } = {};
  //               //var expressionsList: { [key: string]: { [key: string]: string } } = { };
  //               //0 = form Name, 1 = description, 2 = title, 3 =  type, 4 = data array
  //               //schema section in JSON file
  //               filteredSchema = { ...tabsVar[tabLoop.toString()].schema };
  //               //filteredSchema.properties = {};
  //               this.renderForm(tabsVar[tabLoop.toString()].schema, tabsVar[tabLoop.toString()].model, tabsVar[tabLoop.toString()].additional);
  //               if (this.getFormTypeValueFrontPage === WebDynamicFormType.SIDEPANEL) {
  //                 this.sidebarService.setSidebarSize(Number(tabsVar[tabLoop.toString()].additional.formWidth ?? '50'));
  //               }
  //               var counterSectionsLoop = 0;
  //               if (this.getFormTypeValueFrontPage !== WebDynamicFormType.GRID) {
  //                 for (let sectionsLoop in { ...tabsVar[tabLoop.toString()].schema.properties }) {
  //                   var counterFieldsLoop = 0;
  //                   counterSectionsLoop = counterSectionsLoop + 1;
  //                   jsonDynamicModelData = { ...jsonDynamicModelData, [sectionsLoop.toString()]: {} };
  //                   //loops through fields
  //                   for (let k in { ...filteredSchema.properties }[sectionsLoop].properties) {
  //                     counterFieldsLoop = counterFieldsLoop + 1;
  //                     dataTypeList = this.globalDataTypeListFrontPage[tabLoop.toString() ?? ''] = {
  //                       ...dataTypeList,
  //                       [k.toString()]: { ...tabsVar[tabLoop.toString()].schema?.properties }[sectionsLoop]?.properties[k]?.templateOptions?.type?.toString(),
  //                     };
  //                     jsonDynamicModelData[sectionsLoop][k] = '';
  //                     //expressionsList = { ...expressionsList, [k.toString()]: { ...tabsVar[tabLoop.toString()].schema?.properties }[sectionsLoop]?.properties[k].expressions };
  //                     if (
  //                       ({ ...tabsVar[tabLoop.toString()].schema?.properties }[sectionsLoop]?.properties[k]?.type?.toString() == 'select' ||
  //                         { ...tabsVar[tabLoop.toString()].schema?.properties }[sectionsLoop]?.properties[k]?.templateOptions?.type?.toString() == 'lookup') &&
  //                       ({ ...tabsVar[tabLoop.toString()].schema?.properties }[sectionsLoop]?.properties[
  //                         k
  //                       ]?.templateOptions?.additionalProperties?.defaultViewId?.toString() ?? '') != ''
  //                     ) {
  //                       this.commonApiCommonService
  //                         .dynamicFormLookupData({
  //                           body: {
  //                             defaultViewId: { ...tabsVar[tabLoop.toString()].schema?.properties }[sectionsLoop]?.properties[
  //                               k
  //                             ]?.templateOptions?.additionalProperties?.defaultViewId?.toString(),
  //                           },
  //                         })
  //                         .pipe(take(1))
  //                         .subscribe((dynamicFormLookupResponse) => {
  //                           if ((dynamicFormLookupResponse ?? '') != '' && dynamicFormLookupResponse != undefined) {
  //                             let parsedJsonLookupResponse = JSON.parse(dynamicFormLookupResponse);
  //                             if (!tabsVar[tabLoop.toString()].schema.properties[sectionsLoop].properties[k].hasOwnProperty('oneOf')) {
  //                               tabsVar[tabLoop.toString()].schema.properties[sectionsLoop].properties[k].oneOf = [];
  //                               tabsVar[tabLoop.toString()].schema.properties[sectionsLoop].properties[k].oneOf.push(...parsedJsonLookupResponse.oneOf);
  //                             } else {
  //                               tabsVar[tabLoop.toString()].schema.properties[sectionsLoop].properties[k].oneOf.push(...parsedJsonLookupResponse.oneOf);
  //                             }
  //                           }
  //                           //Only runs on on the last cycle of for schema.properties loop
  //                           this.renderForm(filteredSchema, jsonDynamicModelData, tabsVar[tabLoop.toString()].additional);
  //                           //Gets the fields default data to be loaded as default on items in front end
  //                           if (
  //                             Object.keys(tabsVar[tabLoop.toString()].schema?.properties[sectionsLoop].properties).length <= counterFieldsLoop &&
  //                             Object.keys(tabsVar[tabLoop.toString()].schema.properties).length <= counterSectionsLoop
  //                           ) {
  //                             //debugger
  //                             console.log('DataSent', {
  //                               entityName: Object.assign({ ...tabsVar[tabLoop.toString()].additional })?.entityName,
  //                               entityId: this.getEntityIdValueFrontPage?.toString(),
  //                               requestData: {
  //                                 dataTypes: dataTypeList,
  //                               },
  //                             });
  //                             this.commonApiCommonService
  //                               .dynamicFormData({
  //                                 body: {
  //                                   entityName: Object.assign({ ...tabsVar[tabLoop.toString()].additional })?.entityName,
  //                                   entityId: this.getEntityIdValueFrontPage?.toString(),
  //                                   requestData: {
  //                                     dataTypes: dataTypeList,
  //                                   },
  //                                 },
  //                               })
  //                               .pipe(take(1))
  //                               .subscribe((modelResponse) => {
  //                                 console.log('modelResponse a1', modelResponse);
  //                                 if ((modelResponse ?? '') != '') {
  //                                   console.log('modelResponse 1', modelResponse);
  //                                   JSON.parse(modelResponse).ResponseModel.forEach((item) => {
  //                                     for (let modelResponseSectionsLoop in { ...tabsVar[tabLoop.toString()].schema.properties }) {
  //                                       for (let modelResponseLoop in item) {
  //                                         console.log('modelResponseSectionsLoop modelResponseLoop', modelResponseSectionsLoop, modelResponseLoop);
  //                                         //Grabs relevant information from lookup array for front end data population
  //                                         if (
  //                                           Object.keys({ ...jsonDynamicModelData[modelResponseSectionsLoop] }).indexOf(modelResponseLoop) > -1 &&
  //                                           (dataTypeList[modelResponseLoop.toString()].toString() == 'lookup' ||
  //                                             dataTypeList[modelResponseLoop.toString()].toString() == 'picklist') &&
  //                                           ({ ...tabsVar[tabLoop.toString()].schema?.properties }[modelResponseSectionsLoop]?.properties[
  //                                             modelResponseLoop.toString()
  //                                           ]?.templateOptions?.additionalProperties?.defaultViewId?.toString() ?? '') != ''
  //                                         ) {
  //                                           jsonDynamicModelData[modelResponseSectionsLoop][modelResponseLoop] = item[modelResponseLoop]['Id'];
  //                                         } else if (
  //                                           Object.keys({ ...jsonDynamicModelData[modelResponseSectionsLoop] }).indexOf(modelResponseLoop) > -1 &&
  //                                           (dataTypeList[modelResponseLoop.toString()].toString() == 'lookup' ||
  //                                             dataTypeList[modelResponseLoop.toString()].toString() == 'picklist') &&
  //                                           ({ ...tabsVar[tabLoop.toString()].schema?.properties }[modelResponseSectionsLoop]?.properties[
  //                                             modelResponseLoop.toString()
  //                                           ]?.templateOptions?.additionalProperties?.defaultViewId?.toString() ?? '') == ''
  //                                         ) {
  //                                           jsonDynamicModelData[modelResponseSectionsLoop][modelResponseLoop] = item[modelResponseLoop];
  //                                         } else if (
  //                                           Object.keys({ ...jsonDynamicModelData[modelResponseSectionsLoop] }).indexOf(modelResponseLoop) > -1 &&
  //                                           tabsVar[tabLoop.toString()].schema?.properties[modelResponseSectionsLoop]?.properties[
  //                                             modelResponseLoop.toString()
  //                                           ]?.type?.toString() == 'input' &&
  //                                           dataTypeList[modelResponseLoop.toString()].toString() == 'date'
  //                                         ) {
  //                                           //If a date is placed in a text field instead of a datepicker it converts it into the correct format
  //                                           console.log(
  //                                             'modelResponseSectionsLoop modelResponseLoop',
  //                                             item[modelResponseLoop],
  //                                             'ASD',
  //                                             new Date(item[modelResponseLoop]),
  //                                             'ZXC',
  //                                             moment(item[modelResponseLoop]).format('yyyy/MM/DD').toString(),
  //                                           );
  //                                           jsonDynamicModelData[modelResponseSectionsLoop][modelResponseLoop] = moment(item[modelResponseLoop])
  //                                             .format('yyyy/MM/DD')
  //                                             .toString();
  //                                         } else if (
  //                                           Object.keys({ ...jsonDynamicModelData[modelResponseSectionsLoop] }).indexOf(modelResponseLoop) > -1 &&
  //                                           tabsVar[tabLoop.toString()].schema?.properties[modelResponseSectionsLoop]?.properties[
  //                                             modelResponseLoop.toString()
  //                                           ]?.type?.toString() == 'date' &&
  //                                           dataTypeList[modelResponseLoop.toString()].toString() == 'date'
  //                                         ) {
  //                                           //Formats for datepicker, adds date type object instead of string
  //                                           jsonDynamicModelData[modelResponseSectionsLoop][modelResponseLoop] = new Date(item[modelResponseLoop]) ?? null;
  //                                         } else if (Object.keys({ ...jsonDynamicModelData[modelResponseSectionsLoop] }).indexOf(modelResponseLoop) > -1) {
  //                                           jsonDynamicModelData[modelResponseSectionsLoop][modelResponseLoop] = item[modelResponseLoop];
  //                                         }
  //                                         //for (let i in { ...tabsVar[tabLoop.toString()].schema.properties }) {
  //                                         //  console.log(i)
  //                                         //  jsonDynamicModelData[i] = { ...jsonDynamicModelData[sectionsLoop] }
  //                                         //}
  //                                         // Insures that only items in database gets loaded
  //                                         //filteredSchema.properties[j.toString()] = schema?.properties[j.toString()]
  //                                       }
  //                                     }
  //                                   });
  //                                 } else {
  //                                 }
  //                                 filteredSchema.properties[sectionsLoop].properties[k] = {
  //                                   ...filteredSchema?.properties[sectionsLoop].properties[k],
  //                                   ...tabsVar[tabLoop.toString()].schema?.properties[sectionsLoop].properties[k],
  //                                 };
  //                                 this.modelDataRequestCompletedFrontPage = true;
  //                                 this.renderForm(filteredSchema, jsonDynamicModelData, tabsVar[tabLoop.toString()].additional);
  //                               });
  //                           }
  //                         });
  //                     } else {
  //                       if (
  //                         Object.keys(tabsVar[tabLoop.toString()].schema?.properties[sectionsLoop].properties).length <= counterFieldsLoop &&
  //                         Object.keys(tabsVar[tabLoop.toString()].schema.properties).length <= counterSectionsLoop
  //                       ) {
  //                         //debugger
  //                         console.log('DataSent', {
  //                           entityName: Object.assign({ ...tabsVar[tabLoop.toString()].additional })?.entityName,
  //                           entityId: this.getEntityIdValueFrontPage?.toString(),
  //                           requestData: {
  //                             dataTypes: dataTypeList,
  //                           },
  //                         });
  //                         this.commonApiCommonService
  //                           .dynamicFormData({
  //                             body: {
  //                               entityName: Object.assign({ ...tabsVar[tabLoop.toString()].additional })?.entityName,
  //                               entityId: this.getEntityIdValueFrontPage?.toString(),
  //                               requestData: {
  //                                 dataTypes: dataTypeList,
  //                               },
  //                             },
  //                           })
  //                           .pipe(take(1))
  //                           .subscribe((modelResponse) => {
  //                             console.log('modelResponse a2', modelResponse);
  //                             if ((modelResponse ?? '') != '') {
  //                               console.log('modelResponse 2', modelResponse);
  //                               JSON.parse(modelResponse).ResponseModel.forEach((item) => {
  //                                 for (let modelResponseSectionsLoop in { ...tabsVar[tabLoop.toString()].schema.properties }) {
  //                                   for (let modelResponseLoop in item) {
  //                                     //Grabs relevant information from lookup array for front end data population
  //                                     if (
  //                                       Object.keys({ ...jsonDynamicModelData[modelResponseSectionsLoop] }).indexOf(modelResponseLoop) > -1 &&
  //                                       (dataTypeList[modelResponseLoop.toString()].toString() == 'lookup' ||
  //                                         dataTypeList[modelResponseLoop.toString()].toString() == 'picklist') &&
  //                                       ({ ...tabsVar[tabLoop.toString()].schema?.properties }[modelResponseSectionsLoop]?.properties[
  //                                         modelResponseLoop.toString()
  //                                       ]?.templateOptions?.additionalProperties?.defaultViewId?.toString() ?? '') != ''
  //                                     ) {
  //                                       jsonDynamicModelData[modelResponseSectionsLoop][modelResponseLoop] = item[modelResponseLoop]['Id'];
  //                                     } else if (
  //                                       Object.keys({ ...jsonDynamicModelData[modelResponseSectionsLoop] }).indexOf(modelResponseLoop) > -1 &&
  //                                       (dataTypeList[modelResponseLoop.toString()].toString() == 'lookup' ||
  //                                         dataTypeList[modelResponseLoop.toString()].toString() == 'picklist') &&
  //                                       ({ ...tabsVar[tabLoop.toString()].schema?.properties }[modelResponseSectionsLoop]?.properties[
  //                                         modelResponseLoop.toString()
  //                                       ]?.templateOptions?.additionalProperties?.defaultViewId?.toString() ?? '') == ''
  //                                     ) {
  //                                       jsonDynamicModelData[modelResponseSectionsLoop][modelResponseLoop] = item[modelResponseLoop];
  //                                     } else if (
  //                                       Object.keys({ ...jsonDynamicModelData[modelResponseSectionsLoop] }).indexOf(modelResponseLoop) > -1 &&
  //                                       tabsVar[tabLoop.toString()].schema?.properties[modelResponseSectionsLoop]?.properties[
  //                                         modelResponseLoop.toString()
  //                                       ]?.type?.toString() == 'input' &&
  //                                       dataTypeList[modelResponseLoop.toString()].toString() == 'date'
  //                                     ) {
  //                                       //If a date is placed in a text field instead of a datepicker it converts it into the correct format
  //                                       console.log(
  //                                         'modelResponseSectionsLoop modelResponseLoop',
  //                                         item[modelResponseLoop],
  //                                         'ASD',
  //                                         new Date(item[modelResponseLoop]),
  //                                         'ZXC',
  //                                         moment(item[modelResponseLoop]).format('yyyy/MM/DD').toString(),
  //                                       );
  //                                       jsonDynamicModelData[modelResponseSectionsLoop][modelResponseLoop] = moment(item[modelResponseLoop])
  //                                         .format('yyyy/MM/DD')
  //                                         .toString();
  //                                     } else if (
  //                                       Object.keys({ ...jsonDynamicModelData[modelResponseSectionsLoop] }).indexOf(modelResponseLoop) > -1 &&
  //                                       tabsVar[tabLoop.toString()].schema?.properties[modelResponseSectionsLoop]?.properties[
  //                                         modelResponseLoop.toString()
  //                                       ]?.type?.toString() == 'date' &&
  //                                       dataTypeList[modelResponseLoop.toString()].toString() == 'date'
  //                                     ) {
  //                                       //Formats for datepicker, adds date type object instead of string
  //                                       jsonDynamicModelData[modelResponseSectionsLoop][modelResponseLoop] = new Date(item[modelResponseLoop]) ?? null;
  //                                     } else if (Object.keys({ ...jsonDynamicModelData[modelResponseSectionsLoop] }).indexOf(modelResponseLoop) > -1) {
  //                                       jsonDynamicModelData[modelResponseSectionsLoop][modelResponseLoop] = item[modelResponseLoop];
  //                                     }
  //                                     //for (let i in { ...tabsVar[tabLoop.toString()].schema.properties }) {
  //                                     //  console.log(i)
  //                                     //  jsonDynamicModelData[i] = { ...jsonDynamicModelData[sectionsLoop] }
  //                                     //}
  //                                     // Insures that only items in database gets loaded
  //                                     //filteredSchema.properties[j.toString()] = schema?.properties[j.toString()]
  //                                   }
  //                                 }
  //                               });
  //                             } else {
  //                             }
  //                             filteredSchema.properties[sectionsLoop].properties[k] = {
  //                               ...filteredSchema?.properties[sectionsLoop].properties[k],
  //                               ...tabsVar[tabLoop.toString()].schema?.properties[sectionsLoop].properties[k],
  //                             };
  //                             this.modelDataRequestCompletedFrontPage = true;
  //                             this.renderForm(filteredSchema, jsonDynamicModelData, tabsVar[tabLoop.toString()].additional);
  //                           });
  //                       }
  //                     }
  //                   }
  //                 }
  //               }
  //               //loops through sections
  //               // this.loadDynamicFormScript();
  //             }
  //           }
  //         }),
  //         takeUntil(this.onDestroyFrontPage$),
  //       )
  //       .subscribe();
  //   }
  // }
  // //Test method
  // private transformJsonToFormlyFields(jsonData: any): FormlyFieldConfig[] {
  //   const formlyFields: FormlyFieldConfig[] = [];
  //   // Implement transformation logic based on your JSON structure
  //   // Example transformation logic for Kendo UI components
  //   for (const tab of jsonData.schema.tabs) {
  //     for (const section of tab.sections) {
  //       for (const field of section.fields) {
  //         let formlyField: FormlyFieldConfig = {
  //           key: field.key,
  //           type: 'input', // Default type, adjust based on field.type or other conditions
  //           templateOptions: {
  //             label: field.title,
  //             placeholder: field.templateOptions.placeholder,
  //             appearance: 'outline', // Example: Use Kendo UI appearance
  //           },
  //         };
  //         // Adjust formlyField based on field.type and other properties
  //         // Example: Use Kendo UI components for specific field types
  //         if (field.type === 'select') {
  //           formlyField.type = 'kendo-dropdown';
  //           formlyField.templateOptions.options = field.templateOptions.additionalProperties.entityLocalName;
  //         }
  //         formlyFields.push(formlyField);
  //       }
  //     }
  //   }
  //   return formlyFields;
  // }
  // renderForm(schema?, model?, additional?) {
  //   if ((this.modelDataRequestCompletedFrontPage == true && this.lookupDataRequestCompletedFrontPage) || 1 == 1) {
  //     //Populates dynamic form html
  //     this.dynamicFormGroupFrontPage = new FormGroup({});
  //     this.optionsFrontPage = {};
  //     (schema ?? '') == ''
  //       ? (this.dynamicFormfieldsFrontPage = this.dynamicFormfieldsFrontPage)
  //       : (this.dynamicFormfieldsFrontPage = [this.formlyJsonschema.toFieldConfig(schema)]);
  //     // Changes data where specific datatype is needed:
  //     for (let i in this.globalDataTypeListFrontPage['tab_1']) {
  //       //dynamicModel
  //       for (let j in model) {
  //         if (this.globalDataTypeListFrontPage['tab_1'][i] == 'date') {
  //           //(model[j][i] ?? '') == '' ? (model[j][i] = null) : (model[j][i] = model[j][i]); //269
  //         }
  //       }
  //     }
  //     (model ?? '') == '' ? (this.dynamicModelFrontPage = this.dynamicModelFrontPage) : (this.dynamicModelFrontPage = model);
  //     (additional ?? '') == '' ? (this.dataFrontPage = this.dataFrontPage) : (this.dataFrontPage = Object.assign([], additional));
  //     this.schemaGlobalFrontPage = schema;
  //     console.log('schema', this.formlyJsonschema.toFieldConfig(schema));
  //     //this.spinner.hide('dynamicFormSpinner');
  //     //this.loadDynamicFormScript();
  //   }
  // }
  // //openSubmitModal() {
  // //  //debugger
  // //  this.notificationMessage = 'Please confirm that you would like to submit';
  // //  this.modalService.open('confirmModal');
  // //}
  // //submit() {
  // //  let listTypes: { [key: string]: string } = {};
  // //  let listData: { [key: string]: string } = {};
  // //  let listEntityLocalNames: { [key: string]: string } = {};
  // //  var tempDynamicModel: { [key: string]: string } = {};
  // //  for (let j in this.dynamicModel) {
  // //    for (let i in this.dynamicModel[j]) {
  // //      if (({ ...{ ...this.schemaGlobal.properties }[j]?.properties }[i]?.readOnly ?? true) == false) {
  // //        console.log("this.dynamicModel[j][i]", this.dynamicModel[j][i])
  // //        tempDynamicModel[i.toString()] = this.dynamicModel[j][i]?.toString();
  // //        if (
  // //          { ...this.schemaGlobal.properties[j.toString()] }.properties[i.toString().toString()]?.templateOptions?.type?.toString() == 'lookup' ||
  // //          { ...this.schemaGlobal.properties[j.toString()] }.properties[i.toString().toString()]?.templateOptions?.type?.toString() == 'picklist'
  // //        ) {
  // //          listEntityLocalNames = {
  // //            ...listEntityLocalNames,
  // //            [i.toString()]: { ...this.schemaGlobal.properties[j.toString()] }.properties[i.toString()]?.templateOptions?.additionalProperties
  // //              ?.entityLocalName,
  // //          };
  // //        }
  // //        listTypes = {
  // //          ...listTypes,
  // //          [i.toString()]: { ...this.schemaGlobal.properties[j] }.properties[i.toString()]?.templateOptions?.type?.toString(),
  // //        };
  // //      }
  // //    }
  // //  }
  // //        console.log("this.dynamicModel", this.dynamicModel)
  // //  ////this.dynamicModel.forEach(field => { console.log(`Key:${field.key}, Value:${this.model[field.key]}`); }); --x--x--x
  // //  //tempDynamicModel.forEach((element) => {
  // //  //  //schemaGlobal
  // //  //  console.log('tempArrayDynamicModel element', element);
  // //  //  this.dynamicFormfields[0]?.fieldGroup?.forEach((element2) => {
  // //  //    console.log('tempArrayDynamicModel element2', element2);
  // //  //    if ({ ...this.schemaGlobal.properties }[element[0]?.toString()]?.templateOptions?.type?.toString() == 'lookup' ||
  // //  //      { ...this.schemaGlobal.properties }[element[0]?.toString()]?.templateOptions?.type?.toString() == 'picklist') {
  // //  //      listEntityLocalNames = {
  // //  //        ...listEntityLocalNames,
  // //  //        [element[0]?.toString()]: { ...this.schemaGlobal.properties }[element[0]?.toString()]?.templateOptions?.additionalProperties?.entityLocalName,
  // //  //      };
  // //  //      console.log('dropdown');
  // //  //    }
  // //  //    if (element2?.key == element[0] && (element[0]?.toString ?? '') != '' && (element2?.type?.toString() ?? '') != '') {
  // //  //      listTypes = {
  // //  //        ...listTypes, [element[0]?.toString()]: { ...this.schemaGlobal.properties }[element[0]?.toString()]?.templateOptions?.type?.toString(),
  // //  //      };
  // //  //      console.log('listData element[0]', element[0]);
  // //  //    }
  // //  //  });
  // //  //});
  // //  console.log("tempDynamicModel", tempDynamicModel)
  // //  if (this.dynamicFormGroup.valid) {
  // //    // Check FormGroup validity
  // //    this.commonApiCommonService
  // //      .submitDynamicForm({
  // //        body: {
  // //          entityId: this.getEntityIdValue?.toString(),
  // //          entityName: this.data['entityName'],
  // //          version: this.data['version'],
  // //          formType: this.data['formType'],
  // //          requestData: {
  // //            data: tempDynamicModel,
  // //            dataTypes: listTypes,
  // //            entityLocalNames: listEntityLocalNames,
  // //          },
  // //        },
  // //      })
  // //      .pipe()
  // //      .subscribe(
  // //        (value) => {
  // //          if (value.toLowerCase() == 'true') {
  // //            this.closeVerdeDynamicModal();
  // //          } else {
  // //            this.notificationMessage = 'Please try again, and if the issue persists, please log a support request';
  // //            this.modalService.open('errorModal');
  // //          }
  // //        },
  // //        (error) => {
  // //          this.notificationMessage = 'Please try again, and if the issue persists, please log a support request';
  // //          this.modalService.open('errorModal');
  // //        },
  // //      );
  // //  }
  // //}
}
