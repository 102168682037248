import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { take, takeUntil } from 'rxjs/operators';
import {
  IssueModalComponent,
  UserAppConfigDto,
  DotsMenuItem,
  ManualDocumentsDataService,
  DocumentBtDocumentGrouping,
  UserUserDto,
  UserApiUserService,
  UserSecurityFunctionDto,
} from '@verde/shared';
import { UserSecurityLegalEntity, UserService } from '@verde/core';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { SelectEvent, TabStripComponent } from '@progress/kendo-angular-layout';
import { NgxPermissionsObject } from 'ngx-permissions';
import { FormControlDirective } from '@angular/forms';

@Component({
  selector: 'verde-employee-documents',
  templateUrl: './employee-documents.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./employee-documents.component.scss'],
})
export class EmployeeDocumentsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('issueModal') issueModal: IssueModalComponent | undefined;
  @ViewChild('documentTabstrip') public documentTabstrip: TabStripComponent;
  private onDestroy$ = new Subject<boolean>();
  // Config
  config: UserAppConfigDto = {};

  // Issue log
  issueLocation: string = '';
  showIssueModal: boolean = false;
  onSalaryAdvice: boolean = true;

  disableAnimation: boolean = false;

  allAvailableTeamMembers: UserUserDto[] = [];

  allPermissions: NgxPermissionsObject = {};

  menuOptions: any[] = Object.values(DocumentBtDocumentGrouping);

  allowedLegalEntities: UserSecurityLegalEntity[];
  allEmployeesFilteredData: UserUserDto[] = [];
  entityDropdownValue: UserSecurityFunctionDto;
  employeeDropdownMessage = 'Type at least 3 characters';
  selectedCalendarEmployee: UserUserDto;

  constructor(
    public userService: UserService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private manualDocumentsDataService: ManualDocumentsDataService,
    private userApiUserService: UserApiUserService,
  ) {}

  ngOnInit(): void {
    this.allowedLegalEntities = this.userService.getLegalEntitiesWithPermission('PEOPLE-PEOPLE-MANAGEMENT-PAYSLIPS');

    this.userService.team.forEach((t) => {
      if (t.employeeId === this.userService.user.employeeId) {
        this.allAvailableTeamMembers.push(t);
      } else if (t.overrideManagerPayslipAccess) {
        this.allAvailableTeamMembers.push(t);
      }
    });

    this.userService.config$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      this.config = data;
    });

    this.userService.disableAnimation$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      this.disableAnimation = data;
    });
  }

  ngAfterViewInit(): void {
    this.retrieveParams();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  handleEmployeeFilter(search: string) {
    if (search.length >= 3) {
      this.userApiUserService
        .getUsersByLegalEntity({ body: { search, legalEntityId: this.entityDropdownValue.legalEntityId } })
        .pipe(take(1))
        .subscribe(
          (ret) => {
            this.allEmployeesFilteredData = ret;
          },
          (error) => {
            console.error(error);
          },
          () => {
            if (this.allEmployeesFilteredData.length === 0) {
              this.employeeDropdownMessage = 'No Data Found for ' + search;
            }
          },
        );
    } else if (search.length === 0) {
      this.allEmployeesFilteredData = [];
      this.employeeDropdownMessage = 'Type at least 3 characters';
    }
  }

  setEntityDropdownValue(e: UserSecurityFunctionDto, el: FormControlDirective) {
    this.entityDropdownValue = e;
    el.reset();

    //this.pivotGridData = [];
    //this.selectedCalendarEmployee = null;
    //this.selectedYearObject = {};
  }

  setCalendarEmployeeDropdownValue(e: UserUserDto) {
    this.selectedCalendarEmployee = e;

    this.entityDropdownValue = {
      legalEntityId: this.selectedCalendarEmployee.legalEntityId,
      legalEntityName: this.selectedCalendarEmployee.legalEntityName,
    };

    /*this.getCalendarYears();*/
  }

  applyNewUser(e: UserUserDto) {
    this.userService.applyNewProxyUser(e);
  }

  onTabSelect(e: SelectEvent) {
    if (e.index === 0) {
      this.onSalaryAdvice = true;
    } else {
      this.onSalaryAdvice = false;
    }
  }

  private retrieveParams(): void {
    this.route.params.pipe(take(1)).subscribe(
      (params) => {
        if (params['index'] === 'financial') {
          this.documentTabstrip.selectTab(0);
        }

        this.cdr.detectChanges();
      },
      (error) => {
        console.error(error);
      },
    );
  }

  // #region Help, Policy and Issue Methods
  // Open Help or Policy
  openHelpOrPolicy(url?: string | null | undefined) {
    if (url) {
      window.open(url, '_blank');
    }
  }

  // Report Issue
  reportIssue(loc: string) {
    this.issueLocation = loc;

    this.showIssueModal = true;
    setTimeout(() => {
      if (this.issueModal) {
        this.issueModal.openReportIssueModal();
      }
    }, 50);
  }

  hideIssueModal(e: boolean) {
    this.showIssueModal = e;
  }
  // #endregion Help, Policy and Issue Methods

  refresh() {
    //this.getFooterData();
    //this.getHeaderData();
    //this.getAllTemplates();
  }
}
