import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { Subject, takeUntil } from 'rxjs';
import {
  ProcessesProcessNationality,
  ProcessesProcessCountry,
  ProcessesCitizenshipStatus,
  ProcessesLocalResidencyStatus,
  ProcessesApiProcessesService,
  ProcessesValidateIdNumberQuery,
  ProcessesGetLocalResidencyStatusQuery,
  RefreshService,
  ProcessesPositionSeats,
  VerdeApprovalService,
} from '@verde/shared';

@Component({
  selector: 'verde-onboarding-nationality',
  templateUrl: './onboarding-nationality.component.html',
  styleUrls: ['./onboarding-nationality.component.scss'],
})
export class OnboardingNationalityComponent implements OnInit {
  private onDestroy$ = new Subject<boolean>();

  @Input() public personalDetails: FormGroup | null = null;
  @Input() nationalities: ProcessesProcessNationality[] | null = null;
  @Input() countries: ProcessesProcessCountry[] | null = null;
  @Input() citizenStatus: ProcessesCitizenshipStatus[] | null = null;
  @Input() testCountry: any[] | null = null;
  @Input() localResidencyStatus: ProcessesLocalResidencyStatus[] | null = null;
  @Input() validateYesNo: any | null = null;
  @Output() selectedCountryEvent = new EventEmitter<string>();
  @Input() positionSeat: ProcessesPositionSeats | null = null;
  @Output() customEvent = new EventEmitter<boolean>();

  public isReadOnly: boolean = true;

  public restrictions: FileRestrictions = {
    allowedExtensions: ['jpg', 'jpeg', 'png', 'pdf'],
  };

  validationMessage: string = '';
  defaultCountryID: string;
  CountryObj: ProcessesProcessCountry;
  localResidencyStatusObj: ProcessesLocalResidencyStatus;
  nationalitiesObj: ProcessesProcessNationality;
  citizenStatusObj: ProcessesCitizenshipStatus;
  loadingLocalResidencyStatus: boolean = false;
  loadingCitizenshipStatus: boolean = false;
  selectedCountry: ProcessesProcessCountry;
  selectedNationality: ProcessesProcessNationality;
  countriesData: ProcessesProcessCountry[] | null = null;

  constructor(
    private sidebarService: VerdeApprovalService,
    private processesApiProcessesService: ProcessesApiProcessesService,
    private refreshService: RefreshService,
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.countriesData = this.countries.slice();
    if (!this.personalDetails) {
      console.error('Personal details FormGroup is null.');
    } else {
      this.personalDetails
        .get('primaryNationality')
        ?.valueChanges.pipe(takeUntil(this.onDestroy$))
        .subscribe(() => {
          this.updateForeignNationalRadio();
        });

      this.personalDetails
        .get('citizenshipStatus')
        ?.valueChanges.pipe(takeUntil(this.onDestroy$))
        .subscribe(() => {
          this.updateForeignNationalRadio();
        });

      this.personalDetails
        .get('countryResidence')
        ?.valueChanges.pipe(takeUntil(this.onDestroy$))
        .subscribe(() => {
          this.updateForeignNationalRadio();
        });

      this.updateForeignNationalRadio();
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  updateForeignNationalRadio() {
    const nationalityControl = this.personalDetails?.get('primaryNationality');
    const foreignNationalControl = this.personalDetails?.get('foreignNational');

    if (foreignNationalControl && nationalityControl) {
      const nationality = this.nationalities.find((nationality) => nationality.bthr_nationalityid === nationalityControl.value?.bthr_nationalityid);
      const foreignNationalValue = this.calculateForeignNationalValue(nationality);

      foreignNationalControl?.setValue(foreignNationalValue);
    }
  }

  calculateForeignNationalValue(nationality) {
    if (nationality.bt_country.primaryName === this.positionSeat.bt_legalentityCountryText) {
      this.customEvent.emit(false);
      return 'no';
    } else {
      this.customEvent.emit(true);
      return 'yes';
    }
  }

  residencyChange(residencyChange) {
    this.validateYesNo = residencyChange.bt_validateid;
  }

  validateIdNumber() {
    const idNumber = this.personalDetails?.value.idNumber;
    if (idNumber) {
      const validation: ProcessesValidateIdNumberQuery = {
        countryName: this.personalDetails?.value.primaryNationality.bthr_nationality,
        idNumber: idNumber,
        validateId: this.validateYesNo,
      };

      this.processesApiProcessesService
        .validateIdNumber({ body: validation })
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (val) => {
            this.validationMessage = val;
          },
          () => {},
        );
    } else {
      console.error('ID Number is null or undefined.');
    }
  }

  nationalityChange(nationality) {
    this.selectedNationality = nationality;
    this.refreshService.updateSharedValue(nationality.bthr_nationality);
    this.personalDetails?.get('citizenshipStatus')?.setValue(null);
    this.loadingCitizenshipStatus = true;
    this.getCitizenShipStatusUpdate();
  }

  countryChange(country) {
    this.selectedCountry = country;
    this.loadingLocalResidencyStatus = true;

    if (country?.bthr_countryid) {
      const body: ProcessesGetLocalResidencyStatusQuery = {
        countryID: country.bthr_countryid,
      };
      this.personalDetails?.get('localResidency')?.setValue(null);
      this.processesApiProcessesService
        .getLocalResidencyStatus({ body: body })
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (val) => {
            this.localResidencyStatus = val;
          },
          (error) => {
            console.error(error);
          },
          () => {
            this.loadingLocalResidencyStatus = false;
          },
        );
    } else {
      console.error('Country ID is null or undefined.');
    }
  }

  setDefaultCitizenStatus() {
    if (this.nationalitiesObj?.bt_defaultcitizenstatus?.primaryName) {
      this.citizenStatusObj = this.citizenStatus?.find((c) => c.bt_statusname === String(this.nationalitiesObj.bt_defaultcitizenstatus.primaryName));
      if (this.citizenStatusObj) {
        this.personalDetails?.get('citizenshipStatus')?.setValue(this.citizenStatusObj);
      }
    } else {
      console.error('Default citizen status primary name is null or undefined.');
    }
  }

  onFileSelect(event: any): void {
    const file = event?.files?.[0];
    if (file) {
      this.personalDetails?.get('personalDetails.idUpload')?.setValue(file);
    } else {
      console.error('File is null or undefined.');
    }
  }

  getCitizenShipStatusUpdate() {
    this.processesApiProcessesService
      .getCitizenshipStatus({ body: { nationalityID: this.selectedNationality.bthr_nationalityid } })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (val) => {
          this.citizenStatus = val;
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.loadingCitizenshipStatus = false;
        },
      );
  }

  handleCountryFilter(value) {
    this.countriesData = this.countries.filter((s) => s.bthr_country.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  onRadioChange(value) {}
}
