import { Injectable } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { PerformanceReviewService } from '../../../../../../../apps/verde/src/app/features/performance-reviews/services/performance-review.service';
import { PerformanceReviewBtPeerReviewStatus, UserReviewProcess } from '@verde/shared';

@Injectable({
  providedIn: 'root',
})
export class CaptureReviewService {
  private onDestroy$: Subject<any> = new Subject();

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
  activeReviewProcess: UserReviewProcess = {};
  activeQuestionSet: any[] = [];

  constructor(private prService: PerformanceReviewService) {}

  setActiveReview(value: any) {
    this.activeReviewProcess = value;
  }

  setActiveQuestionSet(value: any[]) {
    this.activeQuestionSet = value;
  }

  get currentUnansweredQuestion(): number {
    let unansweredQuestion: number = 0;
    for (const q of this.activeReviewProcess.questions) {
      if (!q.bt_completed) {
        break;
      }
      unansweredQuestion++;
    }
    return unansweredQuestion < this.activeReviewProcess.questions.length ? unansweredQuestion : this.activeReviewProcess.questions.length - 1;
  }

  public captureReview(finalSubmit: boolean = true): void {
    if (finalSubmit) {
      this.activeReviewProcess.performanceReviewSummaries.bt_employeecompleted = true;
      this.activeReviewProcess.performanceReviewSummaries.bt_employeereviewstatus = PerformanceReviewBtPeerReviewStatus.Completed;
    }
    for (let question of this.activeQuestionSet) {
      if (question.bt_responsetype === 'rating') {
        // rating
        let object = JSON.parse(JSON.stringify(question));
        object = { ...object, bt_completed: question.bt_ratingfloat ? true : false };
        question = object;
        /*question.bt_completed = question.bt_ratingfloat ? true : false;*/
        if (!finalSubmit) {
          let obj = JSON.parse(JSON.stringify(this.activeReviewProcess.performanceReviewSummaries));
          obj = { ...obj, bt_managerreviewstatus: PerformanceReviewBtPeerReviewStatus.InProgress };
          obj = { ...obj, bt_employeereviewstatus: PerformanceReviewBtPeerReviewStatus.InProgress };
          this.activeReviewProcess.performanceReviewSummaries = obj;
          /*this.activeReviewProcess.performanceReviewSummaries.bt_managerreviewstatus = PerformanceReviewBtPeerReviewStatus.InProgress;*/
        }
      }
      if (question.bt_responsetype === 'freeText') {
        // response
        question.bt_completed = question.bt_response ? true : false;
        if (!finalSubmit) {
          this.activeReviewProcess.performanceReviewSummaries.bt_managerreviewstatus = PerformanceReviewBtPeerReviewStatus.InProgress;
        }
      }
    }
    this.prService
      .updatePerformanceReview(this.activeReviewProcess)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => () => () => {
        if (finalSubmit) {
          this.activeReviewProcess.performanceReviewSummaries.bt_employeecompleted = true;
          /*this.getAllSummaries();*/
        }
      });
  }

  setQuestions(questions: any[]): void {
    this.activeReviewProcess.questions = questions;
  }
}
