import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { UserService } from '@verde/core';
import { ModalService, WebDynamicFormMode, WebDynamicService } from '@verde/shared';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'verde-web-dynamic-form',
  templateUrl: './web-dynamic-form.component.html',
  styleUrls: ['./web-dynamic-form.component.scss'],
})
export class WebDynamicFormComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<boolean>();
  form: FormGroup;
  model: any;
  additional: any;
  options: FormlyFormOptions;
  fields: FormlyFieldConfig[];
  disableAnimation: boolean;
  notificationMessage: string = '';

  constructor(
    public userService: UserService,
    private webDynamicService: WebDynamicService,
    private spinner: NgxSpinnerService,
    private modalService: ModalService,
  ) {
    this.webDynamicService.webDynamicModel$.pipe(takeUntil(this.onDestroy$)).subscribe((webDynamicModel) => {
      if (webDynamicModel) {
        const hasEntityId = (webDynamicModel.entityId ?? '') !== '';
        const hasFormFile = (webDynamicModel.formFile ?? '') !== '';
        const isCreateForm = webDynamicModel.formMode === WebDynamicFormMode.CREATE;

        if ((hasEntityId || isCreateForm) && hasFormFile) {
          this.form = webDynamicModel.form;
          this.model = webDynamicModel.model;
          this.additional = webDynamicModel.additional;
          this.options = webDynamicModel.options;
          this.fields = webDynamicModel.fields;
        } else {
          this.throwError('Unable to load the dynamic page: EntityId or FormName is not provided');
        }
      } else {
        this.throwError();
      }
    });
  }

  ngOnInit(): void {
    this.userService.disableAnimation$.pipe(takeUntil(this.onDestroy$)).subscribe((disableAnimation) => {
      this.disableAnimation = disableAnimation;
    });
  }

  openSubmitModal() {
    //
  }

  submit() {
    //
  }

  throwError(errorMessage?: string) {
    this.spinner?.hide('dynamicFormSpinner');
    this.notificationMessage = errorMessage ?? 'Please try again, and if the issue persists, please log a support request';
    this.modalService?.open('errorModal');
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
