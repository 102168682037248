<kendo-grid
  #myGrid
  [rowClass]="rowCallback"
  [kendoGridBinding]="DocumentGridData"
  [resizable]="true"
  [kendoGridSelectBy]="skeletonSelectedCallback"
  [selectedKeys]="skeletonSelection"
  [sortable]="true"
  [groupable]="true"
  [group]="financialDocumentsGroups"
  (groupChange)="financialDocumentsGroupChange($event)"
  [loading]="bulkDocsLoading"
>
  <!--Columns-->
  <ng-template kendoGridToolbarTemplate style="height: 57px">
    <kendo-grid-spacer></kendo-grid-spacer>
    <button *ngIf="!(deviceTypeService.isMobile$ | async)" style="height: 36px; margin-left: auto" kendoGridExcelCommand>Export to Excel</button>
    <img title="Refresh" class="refresh_icon" src="https://engageimages.z1.web.core.windows.net/Images/Verde/Other/reload.svg" alt="" (click)="refresh()" />
  </ng-template>

  <kendo-grid-column field="bt_documenttype.bt_doctypefinancial" title="Document Type" [width]="100"> </kendo-grid-column>
  <kendo-grid-column field="bt_filename" title="File Name" [width]="100"> </kendo-grid-column>

  <kendo-grid-command-column title="" [width]="40" [sticky]="true" [minResizableWidth]="40" [maxResizableWidth]="40">
    <ng-template kendoGridCellTemplate let-dataItem>
      <verde-dots-menu-button [items]="dotMenuItems" (menuClicked)="menuClicked(dataItem)" (menuItemClicked)="menuItemClicked($event)">
      </verde-dots-menu-button>
    </ng-template>
  </kendo-grid-command-column>

  <kendo-grid-excel fileName="Verde Bulk Control Table.xlsx"></kendo-grid-excel>
</kendo-grid>
